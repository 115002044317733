import { BookmarkIcon as HollowBookmarkIcon } from "@/components/icons/hollow";
import { BookmarkIcon } from "@/components/icons";
import ArticleIcon from "@/components/icons/hollow/article";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useQuestionBankLibrary from "@/hooks/library/use-questionbank-library";
import { cn, range } from "@/lib/utils";
import { buttonVariants } from "@/ui/button";
import { useParams } from "@tanstack/react-router";
import useBookmarkArticle from "@/hooks/library/use-bookmark-article";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faFolder } from "@fortawesome/free-solid-svg-icons";
import { PropsWithClassName } from "@coursology/types";
import { ComponentProps, PropsWithChildren } from "react";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { GetQuestionBankLibraryOutput } from "@/api/src/server/routers/library";
import {
  useMedicalLibraryOpenCategories,
  useMedicalLibraryPage,
  useMedicalLibraryRouter,
  useMedicalLibrarySelectedHeader,
  useMedicalLibraryVariant,
} from "../store";
import ScrollArea from "@/components/ui/scroll-area";
import useArticleContent from "@/hooks/article/use-article-content";

const Body = () => {
  const { qbankEndpoint } = useParams({ strict: false });
  const { data, isLoading } = useQuestionBankLibrary(
    {
      questionBankEndpoint: qbankEndpoint ?? "",
    },
    { enabled: !!qbankEndpoint },
  );
  return (
    <ScrollArea.Root className="w-full h-full">
      <ScrollArea.Viewport className="w-full h-full">
        <div className="flex flex-col justify-start items-start gap-y-3 p-2">
          {isLoading && <ArticleListSkeleton />}
          {data && !isLoading && (
            <>
              {/*@ts-expect-error date types collapse to string for some reason*/}
              <ArticleList articles={data.articles} />
              {/*@ts-expect-error date types collapse to string for some reason*/}
              <ArticleCategoryList categories={data.categories} />
            </>
          )}
        </div>
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar orientation="vertical">
        <ScrollArea.Thumb />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner />
    </ScrollArea.Root>
  );
};

export default Body;

const ArticleListSkeleton = () => {
  return (
    <div className="w-full h-full flex flex-col justify-start items-start gap-y-3">
      {range(10).map((n) => (
        <Skeleton className="w-full h-8 rounded-primary" key={n} />
      ))}
    </div>
  );
};

const ArticleList = ({
  articles,
}: {
  articles: GetQuestionBankLibraryOutput["articles"];
}) => {
  return (
    <div className="w-full flex flex-col justify-start items-start gap-y-3">
      {articles.map((a) => (
        <ArticleListItem
          articleName={a.name}
          articleId={a.id}
          key={a.id}
          isBookmarked={!!a.usage.bookmarkedAt}
        />
      ))}
    </div>
  );
};

const ArticleCategoryList = ({
  categories,
}: {
  categories: GetQuestionBankLibraryOutput["categories"];
}) => {
  return (
    <div className="w-full flex flex-col justify-start items-start gap-y-3">
      {categories.map((category) => (
        <ArticleCategoryListItem
          key={category.id}
          categoryName={category.name}
          articles={category.articles}
          categoryId={category.id}
        />
      ))}
    </div>
  );
};

const ItemButton = ({
  icon,
  text,
  onClick,
}: {
  icon: React.ReactNode;
  text: React.ReactNode;
  onClick: () => void;
}) => {
  const [variant] = useMedicalLibraryVariant();
  const isOnMobile = variant === "mobile";
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <button
            className="w-full rounded-primary flex flex-row justify-start items-center gap-x-2 min-w-0 outline-none"
            onClick={onClick}
          >
            {icon}
            <span className="capitalize overflow-hidden whitespace-nowrap text-ellipsis">
              {text}
            </span>
          </button>
        </TooltipTrigger>
        <TooltipContent
          className="pointer-events-none"
          side={isOnMobile ? "top" : "right"}
        >
          <span className="capitalize">{text}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const ListItem = ({
  className,
  icon,
  text,
  children,
  secondaryButton,
  onClick,
}: PropsWithClassName &
  ComponentProps<typeof ItemButton> & {
    secondaryButton?: React.ReactNode;
  } & PropsWithChildren & {
    onClick: ComponentProps<"button">["onClick"];
  }) => {
  return (
    <div className="w-full flex flex-col justify-start items-start gap-y-1">
      <div
        className={cn(
          buttonVariants({
            variant: "ghost",
          }),
          "w-full rounded-primary flex flex-row justify-start items-center gap-x-2 outline-none",
          className,
        )}
      >
        <ItemButton icon={icon} text={text} onClick={onClick} />
        {secondaryButton}
      </div>
      {children}
    </div>
  );
};

const ArticleCategoryListItem = ({
  categoryName,
  categoryId,
  articles,
}: {
  categoryName: string;
  categoryId: string;
  articles: ComponentProps<typeof ArticleList>["articles"];
}) => {
  const [openCategories, toggleCategory] = useMedicalLibraryOpenCategories();
  const isOpened = openCategories.includes(categoryId);
  return (
    <ListItem
      icon={
        <FontAwesomeIcon
          icon={isOpened ? faFolderOpen : faFolder}
          className={cn(
            isOpened && "text-primary-500 dark:text-white warm:text-brown-700",
          )}
        />
      }
      text={categoryName}
      onClick={() => toggleCategory(categoryId)}
    >
      {isOpened && (
        <div className="w-[calc(100%-2rem)] ms-6">
          <ArticleList articles={articles} />
        </div>
      )}
    </ListItem>
  );
};

const ArticleListItem = ({
  articleName,
  articleId,
  isBookmarked,
}: {
  articleName: string;
  articleId: string;
  isBookmarked: boolean;
}) => {
  const [page] = useMedicalLibraryPage();
  const router = useMedicalLibraryRouter();
  const isSelected = page === articleId;
  const {
    data: articleContent,
    isLoading,
    isPlaceholderData,
  } = useArticleContent({ articleId }, { enabled: isSelected });
  return (
    <ListItem
      className={cn(
        isSelected &&
          "text-primary-500 dark:text-neutral-900 bg-gray-100 hover:bg-gray-100 hover:text-primary-500 dark:bg-white warm:text-brown-800 dark:hover:bg-white dark:hover:text-neutral-900 warm:hover:bg-brown-100 warm:bg-brown-100",
      )}
      icon={<ArticleIcon />}
      text={articleName}
      secondaryButton={
        <ArticleBookmarkButton
          articleId={articleId}
          isBookmarked={isBookmarked}
          isSelected={isSelected}
        />
      }
      onClick={() => {
        router.push(articleId);
      }}
    >
      {isSelected && (isLoading || isPlaceholderData) && (
        <ArticleHeaderListSkeleton />
      )}
      {isSelected && articleContent && (
        <ArticleHeaderList headers={articleContent.headers} />
      )}
    </ListItem>
  );
};

const ArticleHeaderListSkeleton = () => {
  return (
    <div className="flex flex-col justify-start items-start ms-10 w-full gap-y-2">
      {range(10).map((n) => (
        <Skeleton
          key={n}
          className={cn(
            "dark:text-neutral-200 warm:text-brown-600 h-8 border-s border-s-gray-300 dark:border-s-neutral-700 warm:border-s-brown-200 w-[calc(100%-1.5rem)] flex flex-row justify-start items-center gap-x-2 transition-colors cursor-pointer text-sm",
          )}
        />
      ))}
    </div>
  );
};

const ArticleHeaderList = ({
  headers,
}: {
  headers: { name: string; level: number; id: string }[];
}) => {
  return (
    <div className="flex flex-col justify-start items-start ms-10 w-full">
      {headers.map((h) => (
        <ArticleHeader key={h.id} {...h} />
      ))}
    </div>
  );
};

const ArticleHeader = ({
  name,
  level,
  id,
}: {
  name: string;
  level: number;
  id: string;
}) => {
  const [variant] = useMedicalLibraryVariant();
  const [selectedHeader] = useMedicalLibrarySelectedHeader();
  const isSelected = selectedHeader === id;
  const isOnMobile = variant === "mobile";

  return (
    <div
      className={cn(
        "dark:text-neutral-200 warm:text-brown-600 p-2 border-s border-s-gray-300 dark:border-s-neutral-700 warm:border-s-brown-200 hover:border-s-primary-500 dark:hover:border-s-neutral-300 warm:hover:border-s-brown-700 hover:text-primary-500 dark:hover:text-neutral-200 warm:hover:text-brown-700 hover:bg-gray-100 dark:hover:bg-neutral-800 warm:hover:bg-brown-100 w-[calc(100%-1.5rem)] flex flex-row justify-start items-center gap-x-2 transition-colors cursor-pointer text-sm",
        level === 2 && "ps-6",
        isSelected &&
          "dark:text-neutral-100 warm:text-brown-700 border-s-primary-500 dark:border-s-neutral-300 warm:border-s-brown-700 text-primary-500 bg-gray-100 dark:bg-neutral-800 warm:bg-brown-100",
      )}
      onClick={() => {
        document
          .getElementById(id)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
      }}
    >
      {level === 2 && <FontAwesomeIcon icon={faCircle} className="w-2 h-2" />}
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <p className="capitalize truncate">{name}</p>
          </TooltipTrigger>
          <TooltipContent
            side={isOnMobile ? "top" : "right"}
            className="pointer-events-none"
          >
            <span className="capitalize">{name}</span>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

const ArticleBookmarkButton = ({
  articleId,
  isBookmarked,
  isSelected,
}: {
  articleId: string;
  isBookmarked: boolean;
  isSelected: boolean;
}) => {
  const { mutate } = useBookmarkArticle();
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <button
            className="flex flex-row justify-center items-center transition-transform hover:scale-125 active:scale-100 outline-none"
            onClick={() => {
              mutate({ articleId });
            }}
          >
            {isBookmarked ? (
              <BookmarkIcon
                className={cn(
                  "w-4 h-4 text-primary-500 dark:text-white warm:text-brown-700",
                  isSelected && "dark:text-black",
                )}
              />
            ) : (
              <HollowBookmarkIcon className="w-4 h-4" />
            )}
          </button>
        </TooltipTrigger>
        <TooltipContent className="pointer-events-none">
          Bookmark
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
