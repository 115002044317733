import { createFileRoute } from '@tanstack/react-router'
import { PromocodesTable } from './-components/promocodes-table'
import { useAdmin } from '../-hooks'

export const Route = createFileRoute('/admin/promocodes/')({
  component: PromocodesPage,
})

function PromocodesPage() {
  useAdmin()
  return (
    <div className="min-h-screen bg-neutral-900 p-6">
      <h1 className="text-2xl font-bold mb-6 text-neutral-100">Promocodes</h1>
      <PromocodesTable />
    </div>
  )
}
