import SearchBar from "./components/search-bar";
import SearchResult from "./components/search-result";
import GenericPage from "./generic-page";

const SearchPage = () => {
  return (
    <GenericPage
      header={
        <h1 className="text-3xl font-semibold warm:text-brown-800">
          Search Medical Library
        </h1>
      }
    >
      <div className="flex flex-col justify-start items-start w-full gap-4">
        <SearchBar />
      </div>
      <SearchResult />
    </GenericPage>
  );
};

export default SearchPage;
