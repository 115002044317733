import { Link } from "@tanstack/react-router"
import { ChevronLeft } from "lucide-react"

import { Button } from "@/components/ui/button"

interface PageHeaderProps {
  title: string
  description?: string
}

export function PageHeader({ title, description }: PageHeaderProps) {
  return (
    <div className="relative flex items-center justify-between gap-4">
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <Button variant="ghost" size="icon" asChild className="-ml-2">
            <Link to="/home" className="flex items-center gap-2">
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Back to home</span>
            </Link>
          </Button>
          <h1 className="text-xl font-bold sm:text-2xl">{title}</h1>
        </div>
        {description && <p className="text-sm text-muted-foreground sm:text-base">{description}</p>}
      </div>
    </div>
  )
}

