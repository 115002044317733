import { GetTestSlotOutput } from "@/api/src/server/routers/test-slot-router";

import { GetTestSlotInput } from "@/api/src/server/routers/test-slot-router/testslot-router-types";

import { trpc } from "@/utils/trpc";
import useMutateQueryCache from "./use-mutate-query-cache";

const useMutateTestSlotQueryCache = () => {
  return useMutateQueryCache<GetTestSlotInput, GetTestSlotOutput>(
    trpc.testSlot.getTestSlot,
    "query",
  );
};

export default useMutateTestSlotQueryCache;
