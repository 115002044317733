import { Point } from "@/types";
import { useEffect, useState } from "react";

export const useMousePosition = (events?: {
  onChanged?: (mousePosition: Point) => void;
}) => {
  const [mousePos, setMousePos] = useState<Point>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleTouchEnd = (e: TouchEvent) => {
      const touch = e.changedTouches[0];
      const mousePosition = {
        x: touch.clientX,
        y: touch.clientY,
      };
      setMousePos(mousePosition);
      events?.onChanged?.(mousePosition);
    };

    const handleMouseMove = (event: MouseEvent) => {
      const mousePosition = { x: event.clientX, y: event.clientY };
      setMousePos(mousePosition);
      events?.onChanged?.(mousePosition);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);
  return mousePos;
};
