import { LoadingSpinner } from "@/components/LoadingSpinner";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type WithLoadingStateProps = {
  loadingIndicator?: React.ReactNode;
  isLoading?: boolean;
} & PropsWithClassName &
  PropsWithChildren;
const WithLoadingState = ({
  className,
  loadingIndicator,
  isLoading,
  children,
}: WithLoadingStateProps) => {
  return (
    <div
      className={cn(
        "relative flex flex-col justify-center items-center",
        className,
      )}
    >
      <div
        className={cn(
          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity pointer-events-none",
          isLoading && "opacity-80",
        )}
      >
        {isLoading ? (
          (loadingIndicator ?? (
            <LoadingSpinner className="w-10 h-10 fill-white" />
          ))
        ) : (
          <></>
        )}
      </div>

      <div
        className={cn(
          "w-full h-full flex flex-col justify-center items-center transition-opacity",
          isLoading && "opacity-50 pointer-events-none",
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default WithLoadingState;
