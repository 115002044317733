import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc";
import { usePreviousTestsPageStore } from "@/utils/stores";

export const TestsButton = () => {
  const { qbankEndpoint } = useParams({ strict: false });
  const utils = trpc.useUtils();

  return (
    <Button
      onMouseEnter={() => {
        const {
          currentPage,
          testsPerPage,
          query: search,
        } = usePreviousTestsPageStore.getState();

        utils.test.getPreviousTests.prefetch({
          questionBankEndpoint: qbankEndpoint ?? "",
          page: currentPage,
          testsPerPage,
          search,
        });
      }}
      href="previous-tests"
    >
      <FontAwesomeIcon icon={faTableList} />
      <p>Previous tests</p>
    </Button>
  );
};
