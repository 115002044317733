import { useEffect, useState } from "react";
import useDOMParser from "../use-dom-parser";
import Mark from "mark.js";
import escapeRegex from "@/utils/common/escape-regex";

const useSearchedHtml = ({
  html,
  searchValue,
  targetMatchIndex,
  caseSensitive = false,
  onTotalMatchesCalculated,
  isEnabled,
}: {
  html: string;
  searchValue: string;
  targetMatchIndex: number;
  isEnabled: boolean;
  caseSensitive?: boolean;
  onTotalMatchesCalculated?: (totalMatches: number) => void;
}) => {
  const domParser = useDOMParser();
  const [parsedDocument, setParsedDocument] = useState<HTMLElement>(
    domParser.parseFromString(html, "text/html").documentElement,
  );
  useEffect(() => {
    setParsedDocument(
      domParser.parseFromString(html, "text/html").documentElement,
    );
  }, [html, domParser]);
  const [searchedHtml, setSearchedHtml] = useState<string>(html);
  const [totalMatches, setTotalMatches] = useState<number>(0);
  useEffect(() => {
    const markInstance = new Mark(parsedDocument);
    parsedDocument.querySelectorAll("a").forEach((hint) => {
      hint.setAttribute("data-id", hint.getAttribute("href") ?? "");
    });

    markInstance.unmark({
      exclude: ["[data-highlight=true]"],
      done: () => {
        if (!isEnabled) {
          setSearchedHtml(parsedDocument.querySelector("body")!.innerHTML);
          return;
        }
        let matches = 0;
        markInstance.markRegExp(
          new RegExp(escapeRegex(searchValue), caseSensitive ? "" : "i"),
          {
            each: (node) => {
              if (matches === targetMatchIndex) {
                node.setAttribute("data-is-current-match", "true");
              }
              node.className = "text-inherit dark:text-black";
              node.setAttribute("data-is-match", "true");
              matches++;
            },
          },
        );
        setTotalMatches(matches);
        onTotalMatchesCalculated?.(matches);
        setSearchedHtml(parsedDocument.querySelector("body")!.innerHTML);
      },
    });
  }, [parsedDocument, searchValue, targetMatchIndex, caseSensitive, isEnabled]);
  return { searchedHtml, totalMatches, targetMatchIndex };
};

export default useSearchedHtml;
