import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import QuestionCountBadge from "./QuestionCountBadge";
import LabeledCheckbox from "./LabeledCheckbox";
import { Setter } from "@/types";
import { trpc } from "@/utils/trpc";
import { useParams } from "@tanstack/react-router";

interface QuestionModeCheckboxProps {
  mode: "unused" | "incorrect" | "correct" | "marked" | "omitted";
  questionCount: number;
  loading?: boolean;
  checked?: boolean;
  onCheckedChange?: Setter<boolean>;
  label?: string;
}

const QuestionModeCheckbox = ({
  mode,
  questionCount,
  loading,
  label: externalLabel,
  checked: externalChecked,
  onCheckedChange: externalOnCheckedChange,
}: QuestionModeCheckboxProps) => {
  const store = useCreateTestPageStore();
  const checked =
    externalChecked ?? (store[mode as keyof typeof store] as boolean);
  const disabled = questionCount === 0;
  const utils = trpc.useUtils();
  const { qbankEndpoint } = useParams({
    from: "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test/standard",
  });
  const unused = useCreateTestPageStore((state) => state.unused);
  const incorrect = useCreateTestPageStore((state) => state.incorrect);
  const marked = useCreateTestPageStore((state) => state.marked);
  const omitted = useCreateTestPageStore((state) => state.omitted);
  const correct = useCreateTestPageStore((state) => state.correct);
  const selectedSubjects = useCreateTestPageStore(
    (state) => state.selectedSubjects,
  );

  return (
    <div className="flex justify-center items-center gap-2 lg:w-auto w-full">
      <LabeledCheckbox
        id={`${mode}-checkbox`}
        checked={checked}
        onMouseEnter={() => {
          utils.statistics.getQuestionbankUsage.prefetch({
            questionBankEndpoint: qbankEndpoint,
            //THIS SUCKS BUT I DONT KNOW HAVE TIME TO NEATLY CODE IT
            pool:
              externalLabel?.toLowerCase() === "all"
                ? checked
                  ? ["unused", "correct", "incorrect", "marked", "omitted"]
                  : []
                : [
                    ...(checked ? [mode] : []),
                    ...(unused ? ["unused" as "unused"] : []),
                    ...(incorrect ? ["incorrect" as "incorrect"] : []),
                    ...(marked ? ["marked" as "marked"] : []),
                    ...(omitted ? ["omitted" as "omitted"] : []),
                    ...(correct ? ["correct" as "correct"] : []),
                  ],
            subjects: selectedSubjects,
          });
        }}
        onCheckedChange={
          externalOnCheckedChange ??
          ((checked) => store.toggleField(mode, checked))
        }
        disabled={disabled}
        label={externalLabel ?? mode.at(0)!.toUpperCase() + mode.slice(1)}
        className="lg:w-auto w-full"
      />
      <QuestionCountBadge loading={loading} count={questionCount} />
    </div>
  );
};

export default QuestionModeCheckbox;
