import { toast, Toaster } from "sonner";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { trpc } from "@/utils/trpc";
import { Navbar } from "@/components/Navbar";
import { twMerge } from "tailwind-merge";
import { useEffect } from "react";
import { MantineProvider } from "@mantine/core";
import { useAppTheme } from "@/hooks";
import { cn } from "@/lib/utils";
import "@fortawesome/fontawesome-svg-core/styles.css";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";

import { createRootRoute, Outlet } from "@tanstack/react-router";
import { LoadingPage } from "@/components/LoadingPage";
import { NotFoundPage } from "@/components/NotFoundPage";
import { ErrorPage } from "@/components/ErrorPage";
import { useTitle } from "@/hooks/common/use-title";

export const Route = createRootRoute({
  component: RootLayout,
  pendingComponent: LoadingPage,
  notFoundComponent: NotFoundPage,
  errorComponent: ({ error }) => <ErrorPage error={error.message} />,
});

function RootLayout() {
  const { currentUser, sync } = useAuthSlice();
  const { data: syncedUser, error: syncUserError } = trpc.auth.sync.useQuery(
    undefined,
    {
      enabled: !!currentUser,
    },
  );
  useTitle("Coursology Qbanks");
  useEffect(() => {
    if (syncUserError) {
      toast.error(
        `Could not sync user data, an error occured: ${syncUserError.message}`,
      );
    }
  }, [syncUserError]);

  useEffect(() => {
    if (syncedUser) {
      sync(syncedUser);
    }
  }, [syncedUser]);

  return (
    <div className={twMerge("min-h-screen h-screen transition-colors")}>
      <Navbar />
      <main
        className={cn(
          "flex h-full flex-col items-center justify-between selection:bg-primary-500 warm:selection:bg-brown-600 selection:text-white dark:bg-neutral-950 text-gray-800 dark:text-neutral-300 bg-white",
        )}
      >
        <Toaster closeButton />
        <MantineProvider forceColorScheme={"light"}>
          <Outlet />
        </MantineProvider>
      </main>
    </div>
  );
}
