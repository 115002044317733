import { trpc } from "@/utils/trpc";
import { toast } from "sonner";
import useMutateTestPageSessionCache from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-mutate-test-page-session-cache";

const useSwitchSelectedTestSlot = () => {
  const testSessionCacheMutator = useMutateTestPageSessionCache();
  return trpc.test.switchSelectedSlot.useMutation({
    onMutate: ({ slot }) => {
      const rollback = testSessionCacheMutator((state) => {
        if (slot < 0 || slot > state.slotCount) return;
        state.selectedSlot = slot;
      });

      //Function to rollback
      return {
        rollback: () =>
          rollback((previous, oldTestSession) => {
            previous.selectedSlot = oldTestSession.selectedSlot;
          }),
      };
    },
    onError: (err, variables, ctx) => {
      toast.error(
        `Could not switch questions.., an error ocurred: ${err.message}`,
      );
      ctx?.rollback?.();
    },
  });
};

export default useSwitchSelectedTestSlot;
