import { Setter } from "@coursology/types";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export interface MedicalLibraryStore {
  variant: "desktop" | "mobile";
  setVariant: Setter<MedicalLibraryStore["variant"]>;
  location: "dashboard" | "test";
  setLocation: Setter<this["location"]>;
  sidebar: {
    isOpen: boolean;
    setIsOpen: Setter<boolean>;
    openCategories: string[];
    toggleCategory: (categoryId: string) => void;
  };
  selectedHeader: string | null;
  setSelectedHeader: Setter<this["selectedHeader"]>;
  search: {
    query: string;
    isMatchCaseSensitive: boolean;
    totalMatches: number;
    targetMatch: number;
    isEnabled: boolean;
  };

  librarySearch: {
    query: string;
    itemsPerPage: 5 | 10 | 15 | 25 | 50 | 100;
    page: number;
  };
  setLibrarySearch: Setter<this["librarySearch"]>;
  setSearch: Setter<this["search"]>;
  isSettingsVisible: boolean;
  setIsSettingsVisible: Setter<this["isSettingsVisible"]>;
  router: {
    history: ("bookmarks" | "search" | string)[];
    push: Setter<MedicalLibraryStore["router"]["history"][number]>;
    pop: () => MedicalLibraryStore["router"]["history"][number] | undefined;
  };
}

const useMedicalLibraryStore = create<MedicalLibraryStore>()(
  immer((set) => {
    return {
      variant: "desktop",
      setVariant: (newVariant) =>
        set((state) => {
          state.variant = newVariant;
        }),
      location: "dashboard",
      setLocation: (n) =>
        set((state) => {
          state.location = n;
        }),
      sidebar: {
        isOpen: true,
        setIsOpen: (newIsOpen) =>
          set((state) => {
            state.sidebar.isOpen = newIsOpen;
          }),
        openCategories: [],
        toggleCategory: (categoryId) =>
          set((state) => {
            const categoryIndex = state.sidebar.openCategories.findIndex(
              (c) => c === categoryId,
            );
            if (categoryIndex >= 0) {
              state.sidebar.openCategories.splice(categoryIndex);
            } else {
              state.sidebar.openCategories.push(categoryId);
            }
          }),
      },
      isSettingsVisible: false,
      setIsSettingsVisible: (n) => {
        set((state) => {
          state.isSettingsVisible = n;
        });
      },
      selectedHeader: null,
      setSelectedHeader: (header) =>
        set((state) => {
          state.selectedHeader = header;
        }),
      search: {
        query: "",
        isMatchCaseSensitive: false,
        totalMatches: 0,
        targetMatch: 0,
        isEnabled: false,
      },
      librarySearch: { itemsPerPage: 10, page: 1, query: "" },
      setLibrarySearch: (newLibrarySearch) => {
        set((state) => {
          state.librarySearch = newLibrarySearch;
        });
      },
      router: {
        history: ["bookmarks"],
        push: (n) => {
          set((state) => {
            const currentPage = state.router.history.at(-1);
            if (currentPage === n) return;
            state.router.history.push(n);
          });
        },
        pop: () => {
          let poppedPage: string | undefined;
          set((state) => {
            poppedPage = state.router.history.pop();
            const currentPage = state.router.history.at(-1);
            if (!currentPage) return;
          });
          return poppedPage;
        },
      },
      setSearch: (search) =>
        set((state) => {
          if (!search.query) {
            state.search = {
              query: "",
              totalMatches: 0,
              targetMatch: 0,
              isMatchCaseSensitive: search.isMatchCaseSensitive,
              isEnabled: search.isEnabled,
            };
            return;
          }
          state.search = {
            ...search,
            targetMatch:
              search.targetMatch > search.totalMatches - 1
                ? 0
                : search.targetMatch < 0
                  ? search.totalMatches - 1
                  : search.targetMatch,
          };
        }),
    };
  }),
);

export default useMedicalLibraryStore;

export const useMedicalLibraryVariant = () => {
  const variant = useMedicalLibraryStore((state) => state.variant);
  const setVariant = useMedicalLibraryStore((state) => state.setVariant);
  return [variant, setVariant] as const;
};

export const useMedicalLibrarySidebarIsOpen = () => {
  const isOpen = useMedicalLibraryStore((state) => state.sidebar.isOpen);
  const setIsOpen = useMedicalLibraryStore((state) => state.sidebar.setIsOpen);
  return [isOpen, setIsOpen] as const;
};

export const useMedicalLibraryPage = () => {
  const router = useMedicalLibraryStore((state) => state.router);

  return [router.history.at(-1) ?? null] as const;
};

export const useMedicalLibraryRouter = () => {
  const router = useMedicalLibraryStore((state) => state.router);
  return router;
};

export const useMedicalLibraryOpenCategories = () => {
  const openCategories = useMedicalLibraryStore(
    (state) => state.sidebar.openCategories,
  );
  const toggleCategory = useMedicalLibraryStore(
    (state) => state.sidebar.toggleCategory,
  );
  return [openCategories, toggleCategory] as const;
};

export const useMedicalLibrarySelectedHeader = () => {
  const selectedHeader = useMedicalLibraryStore(
    (state) => state.selectedHeader,
  );
  const setSelectedHeader = useMedicalLibraryStore(
    (state) => state.setSelectedHeader,
  );

  return [selectedHeader, setSelectedHeader] as const;
};

export const useMedicalLibrarySearch = () => {
  const search = useMedicalLibraryStore((state) => state.search);
  const setSearch = useMedicalLibraryStore((state) => state.setSearch);
  return [search, setSearch] as const;
};

export const useMedicalLibraryLibrarySearch = () => {
  const librarySearch = useMedicalLibraryStore((state) => state.librarySearch);
  const setLibrarySearch = useMedicalLibraryStore(
    (state) => state.setLibrarySearch,
  );

  return [librarySearch, setLibrarySearch] as const;
};

export const useMedicalLibraryLocation = () => {
  const location = useMedicalLibraryStore((state) => state.location);
  const setLocation = useMedicalLibraryStore((state) => state.setLocation);
  return [location, setLocation] as const;
};

export const useMedicalLibraryIsSettingsVisible = () => {
  const isSettingsVisible = useMedicalLibraryStore(
    (state) => state.isSettingsVisible,
  );
  const setIsSettingsVisible = useMedicalLibraryStore(
    (state) => state.setIsSettingsVisible,
  );

  return [isSettingsVisible, setIsSettingsVisible] as const;
};
