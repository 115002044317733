import { ComponentProps } from "react";
import { DeleteTestButton } from "./DeleteTest";
import { CopyQuestionIdsButton } from "./CopyQuestionIds";
import RecoverTestButton from "./RecoverTestButton";

export const Buttons = ({
  testId,
  testName,
  qBankEndpoint,
  questionIds,
  deletedAt,
}: ComponentProps<typeof DeleteTestButton> &
  ComponentProps<typeof CopyQuestionIdsButton> & {
    deletedAt: Date | string | null;
  }) => {
  if (deletedAt) return <RecoverTestButton testId={testId} />;
  return (
    <>
      <DeleteTestButton
        testId={testId}
        testName={testName}
        qBankEndpoint={qBankEndpoint}
      />
      <CopyQuestionIdsButton questionIds={questionIds} />
    </>
  );
};
