import { createFileRoute } from "@tanstack/react-router";
import { PageHeader } from "./-components/page-header";
import { SubscriptionSection } from "./-components/subscription-section";
import { GeneralSettingsSection } from "./-components/general-settings-section";
import { DangerZoneSection } from "./-components/danger-zone-section";
import useCurrentUser from "@/hooks/auth-hooks/use-current-user";
import { calcExpirationDate } from "@/utils/subscription";
import { DateTime } from "luxon";
import { countries } from "countries-list";

export const Route = createFileRoute("/account")({
  component: AccountPage,
});

function AccountPage() {
  const user = useCurrentUser();
  const lastRenewalDate = user.lastRenewal
    ? DateTime.fromJSDate(new Date(user.lastRenewal))
    : undefined;
  return (
    <div className="min-h-screen bg-background text-foreground">
      <div className="mx-auto max-w-2xl space-y-6 p-4 sm:space-y-8 sm:p-6 md:p-8">
        <PageHeader
          title="Account Settings"
          description="Manage your account preferences and settings"
        />

        <SubscriptionSection
          plan={user.currentSubscription.name}
          status={user.subscriptionActive ? "Active" : "Expired"}
          lastRenewed={
            lastRenewalDate
              ? lastRenewalDate.toLocaleString(DateTime.DATE_FULL)
              : "N/A"
          }
          endsAt={
            lastRenewalDate && user.currentSubscription.duration
              ? ["unlimited", "admin"].some((subname) =>
                  user.currentSubscription.name.toLowerCase().includes(subname),
                )
                ? "Never (Lifetime subscription)"
                : calcExpirationDate({
                    lastRenewal: lastRenewalDate,
                    extraMonths: user.extraMonths ?? 0,
                    subscriptionType: user.currentSubscription.type,
                    duration: user.currentSubscription.duration,
                  }).toLocaleString(DateTime.DATE_FULL)
              : ""
          }
        />

        <GeneralSettingsSection
          name={user.name}
          username={user.username}
          email={user.email}
          phone={user.phoneNumber}
          country={countries[user.country].name}
          memberSince={DateTime.fromJSDate(
            new Date(user.createdAt),
          ).toLocaleString(DateTime.DATE_FULL)}
          promoCode={user.usedPromoCode?.code}
        />

        <DangerZoneSection />
      </div>
    </div>
  );
}
