import { useAppStore } from "@/store";
import { useConfirmDeleteDialog } from ".";

export const useNoteActions = () => {
  const { setIsVisible, setTarget } = useConfirmDeleteDialog();
  const { add, rename, internalRemove, edit, notes, recover } = useAppStore(
    (state) => ({
      add: state.addNote,
      rename: state.renameNote,
      edit: state.editNote,
      notes: state.notes,
      internalRemove: state.removeNote,
      recover: state.recoverNote,
    }),
  );
  const remove = (id: string) => {
    const target = notes.find((n) => n.id === id);
    const subNotes = notes.filter((n) => n.parentId === id);
    if (subNotes.length > 0 || target?.markdown !== "") {
      setIsVisible(true);
      setTarget(target);
      return;
    }
    internalRemove(id);
  };
  return { add, remove, rename, edit, recover };
};
