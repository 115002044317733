import { Layout, NotebookTree, Header } from "./components";
import { usePages } from "./hooks";

export const Sidebar = () => {
  const pages = usePages();
  return (
    <Layout>
      <Header />
      <NotebookTree
        //TODO: Fix this
        //@ts-ignore
        pages={pages}
      />
    </Layout>
  );
};
