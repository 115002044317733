import type { ReactNode } from "react"

interface InfoItemProps {
  label: string
  children: ReactNode
}

export function InfoItem({ label, children }: InfoItemProps) {
  return (
    <div className="space-y-1">
      <dt className="text-sm font-medium text-muted-foreground">{label}</dt>
      <dd className="text-sm sm:text-base">{children}</dd>
    </div>
  )
}

interface InfoListProps {
  children: ReactNode
  className?: string
}

export function InfoList({ children, className }: InfoListProps) {
  return <dl className={`grid gap-4 sm:grid-cols-2 ${className}`}>{children}</dl>
}

