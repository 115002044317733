
interface SectionHeaderProps {
  title: string
  className?: string
}

export function SectionHeader({ title, className }: SectionHeaderProps) {
  return (
    <h3 className={`pb-2 text-sm font-medium text-muted-foreground border-b border-border/50 ${className}`}>{title}</h3>
  )
}

