import { trpc } from "@/utils/trpc";
import { toast } from "sonner";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";

const useCrossTestSlotQuestionChoice = () => {
  const mutateTestSlot = useMutateTestSlotQueryCache()();
  return trpc.testSlot.crossTestSlotQuestionChoice.useMutation({
    onMutate: ({ testSlotId, questionChoiceId }) => {
      let wasCrossed = false;
      const rollbackTestSlot = mutateTestSlot((state) => {
        if (state.id === testSlotId) {
          wasCrossed = state.crossed.includes(questionChoiceId);
          if (wasCrossed) {
            state.crossed = state.crossed.filter(
              (id) => id !== questionChoiceId,
            );
          } else {
            state.crossed.push(questionChoiceId);
          }
        }
      });

      return {
        rollback: () => {
          rollbackTestSlot((state) => {
            if (state.id === testSlotId) {
              if (wasCrossed) {
                state.crossed.push(questionChoiceId);
              } else {
                state.crossed = state.crossed.filter(
                  (id) => id !== questionChoiceId,
                );
              }
            }
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not cross test slot question choice, an error occurred: ${error.message}`,
      );
      context?.rollback();
    },
  });
};
export default useCrossTestSlotQuestionChoice;
