import useSuspendTest from "@/hooks/test-hooks/use-suspend-test";
import useTestPageTestSession from "./use-test-page-test-session";

const useTestPageSuspendTest = () => {
  const { data: test } = useTestPageTestSession();
  const { mutate: suspendTest } = useSuspendTest();
  return () => {
    if (!test) return;
    suspendTest({ testId: test.id });
  };
};

export default useTestPageSuspendTest;
