import { trpc } from "@/utils/trpc";
import useMutateTestSessionQueryCache from "../utility/use-mutate-test-session-query-cache";
import { toast } from "sonner";
import useMutatePreviousTestsQueryCache from "../utility/use-mutate-previous-tests-query-cache";

const useSuspendTest = () => {
  const mutateTestSession = useMutateTestSessionQueryCache()();
  const mutatePreviousTests = useMutatePreviousTestsQueryCache()();

  const utils = trpc.useUtils();
  let input: number | undefined;
  return trpc.test.suspendTest.useMutation({
    onMutate: ({ testId }) => {
      input = testId;
      const rollbackTestSession = mutateTestSession((state) => {
        if (state.id === testId) {
          state.suspended = true;
          state.suspendedAt = new Date();
        }
      });
      const rollbackPreviousTests = mutatePreviousTests((state) => {
        state.tests.forEach((test) => {
          if (test.id === testId) {
            test.suspended = true;
          }
        });
      });
      return {
        rollback: () => {
          rollbackTestSession((state, oldState) => {
            state.suspended = oldState.suspended;
            state.suspendedAt = oldState.suspendedAt;
          });
          rollbackPreviousTests((state) => {
            state.tests.forEach((test) => {
              if (test.id === testId) {
                test.suspended = false;
              }
            });
          });
        },
        input: { testId },
      };
    },
    onError: (err, variables, ctx) => {
      toast.error(
        `Could not suspend test, an error occurred..., ${err.message}`,
      );
      ctx?.rollback();
    },
    onSettled: () => {
      if (input) {
        utils.test.getTestSession.invalidate({ testId: input });
        utils.test.getTestSession.reset({ testId: input });
      }
    },
  });
};
export default useSuspendTest;
