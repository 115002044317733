import { trpc } from "@/utils/trpc";
import useMutateHighlightMarkersQueryCache from "../utility/use-mutate-highlight-markers-query-cache";
import { HighlightMarker } from "@/models";
import { current } from "immer";
import { toast } from "sonner";

const useEditHighlightMarker = () => {
  const mutateHighlightMarkersCache = useMutateHighlightMarkersQueryCache()();
  return trpc.highlightMarker.editHighlightMarker.useMutation({
    onMutate: ({ id, name, color }) => {
      let oldHighlightMarker: Omit<HighlightMarker, "userId"> | undefined =
        undefined;

      const rollbackHighlightMarkers = mutateHighlightMarkersCache((state) => {
        const targetHighlightMarker = state.find((m) => m.id === id);
        if (!targetHighlightMarker) return;
        oldHighlightMarker = current(targetHighlightMarker);
        targetHighlightMarker.name = name;
        targetHighlightMarker.color = color;
      });
      return {
        rollback: () => {
          rollbackHighlightMarkers((state) => {
            const targetHighlightMarker = state.find((m) => m.id === id);
            if (!targetHighlightMarker || !oldHighlightMarker) return;
            targetHighlightMarker.name = oldHighlightMarker.name;
            targetHighlightMarker.color = oldHighlightMarker.color;
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not edit highlight marker..., an error ocurred: ${error.message}`,
      );
      context?.rollback();
    },
  });
};
export default useEditHighlightMarker;
