import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PerformanceTestPageLink } from ".";
import { trpc } from "@/utils/trpc";
import {
  usePreviousTestsPageCurrentPage,
  usePreviousTestsPageSearch,
  usePreviousTestsPageTestsPerPage,
} from "@/utils/stores/previousTestsPageStore";
import { useEffect } from "react";

interface PerformanceTestPageBackLinkProps {
  qBankEndpoint: string;
  qBankId: string;
}

export const PerformanceTestPageBackLink = ({
  qBankEndpoint,
  qBankId,
}: PerformanceTestPageBackLinkProps) => {
  const utils = trpc.useUtils();
  const [page] = usePreviousTestsPageCurrentPage();
  const [search] = usePreviousTestsPageSearch();
  const [testsPerPage] = usePreviousTestsPageTestsPerPage();
  useEffect(() => {
    if (!qBankId) return;
    utils.test.getPreviousTests.prefetch({
      questionBankId: qBankId,
      page,
      search,
      testsPerPage,
    });
  }, [qBankId]);

  return (
    <PerformanceTestPageLink
      type="link"
      href={`/qbanks/${qBankEndpoint}/dashboard/previous-tests`}
    >
      <div className="flex flex-row justify-center items-center gap-2">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="group-hover:-translate-x-[50%] md:text-lg text-base transition-transform duration-300 ease-smooth"
        />
        <p>Back</p>
      </div>
    </PerformanceTestPageLink>
  );
};
