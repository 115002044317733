import { ResizableHandle, ResizablePanel } from "@/components/ui/resizable";
import { PropsWithChildren } from "react";
import Footer from "./footer";
import BookmarksPage from "./pages/bookmarks";
import { useMedicalLibraryPage } from "../store";
import SearchPage from "./pages/search";
import ArticlePage from "./pages/article";
import SettingsSidebar from "./settings-sidebar";

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <ResizableHandle withHandle />
      <ResizablePanel
        order={2}
        defaultSize={70}
        className="h-full max-h-screen flex flex-col justify-start items-start"
      >
        {children}
        <SettingsSidebar />
      </ResizablePanel>
    </>
  );
};

const Body = () => {
  const [page] = useMedicalLibraryPage();

  return (
    <Layout>
      <div className="flex-grow w-full h-full overflow-auto">
        {page === "bookmarks" ? (
          <BookmarksPage />
        ) : page === "search" ? (
          <SearchPage />
        ) : (
          <ArticlePage />
        )}
      </div>
      <Footer />
    </Layout>
  );
};

export default Body;
