import { GetTestSessionOutput } from "@/api/src/server/routers";
import { GetTestSlotOutput } from "@/api/src/server/routers/test-slot-router";
import { QuestionChoice } from "@/models";
import useMutateTestPageSelectedSlotQueryCache from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-mutate-test-page-selected-slot-query-cache";
import useMutateTestPageSessionCache from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-mutate-test-page-session-cache";
import useTestPageSelectedSlotQueryKey from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-selected-slot-query-key";
import useTestPageTestSessionQueryKey from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-test-session-query-key";
import { trpc } from "@/utils/trpc";
import { useQueryClient } from "@tanstack/react-query";
import { current } from "immer";
import { toast } from "sonner";

const useChooseTestSlotQuestionChoice = () => {
  const testSessionCacheMutator = useMutateTestPageSessionCache();
  const testSlotCacheMutator = useMutateTestPageSelectedSlotQueryCache();

  return trpc.testSlot.chooseTestSlotQuestionChoice.useMutation({
    onMutate: ({ questionChoiceId, testSlotId }) => {
      let questionChoice:
        | Pick<
            QuestionChoice,
            "id" | "order" | "text" | "html" | "uWorldChosenBy" | "correct"
          >
        | null
        | undefined = undefined;
      const rollBackTestSlot = testSlotCacheMutator((state) => {
        const targetQuestionChoice = state.question.choices.find(
          (c) => c.id === questionChoiceId,
        );
        if (targetQuestionChoice) {
          questionChoice = current(targetQuestionChoice);
        } else {
          questionChoice = null;
        }

        state.chosenId = questionChoiceId;
      });

      const rollBackTestSession = testSessionCacheMutator((state) => {
        state.slots.forEach((s) => {
          if (s.id === testSlotId) {
            if (questionChoice !== undefined) {
              s.chosen = questionChoice;
            }
          }
        });
      });

      //TODO: Update create test page cache when we finish it
      return {
        rollback: () => {
          rollBackTestSession((state, oldTestSession) => {
            state.slots.forEach((s) => {
              if (s.id === testSlotId) {
                s.chosen =
                  oldTestSession.slots.find((s) => s.id === testSlotId)
                    ?.chosen ?? null;
              }
            });
          });
          rollBackTestSlot((state, oldTestSlot) => {
            state.chosenId = oldTestSlot.chosenId;
          });
        },
      };
    },
    onError: (error, variables, context) => {
      context?.rollback();
      toast.error(
        `Could not choose question choice, an error occurred: ${error.message}`,
      );
    },
  });
};

export default useChooseTestSlotQuestionChoice;
