import { Button } from "@/components/ui/button";
import ScrollArea from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { PropsWithClassName } from "@coursology/types";
import { SettingsIcon } from "lucide-react";
import React, { PropsWithChildren } from "react";
import {
  useMedicalLibraryIsSettingsVisible,
  useMedicalLibraryLocation,
  useMedicalLibraryRouter,
  useMedicalLibraryVariant,
} from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const GenericPageHeader = ({ header }: { header: React.ReactNode }) => {
  const router = useMedicalLibraryRouter();
  return (
    <div className="flex flex-row justify-between items-center w-full border-b border-b-gray-300 py-4">
      <div className="flex flex-col justify-start items-start gap-y-4">
        {router.history.length > 1 && (
          <Button
            variant={"link"}
            className="flex flex-row justify-start items-center gap-x-2 p-0 text-sm text-primary-500 dark:text-neutral-100 warm:text-brown-700"
            onClick={() => router.pop()}
          >
            <FontAwesomeIcon className="w-3 h-3" icon={faChevronLeft} /> Back to
            previous page
          </Button>
        )}
        {header}
      </div>
      <SettingsButton />
    </div>
  );
};

const SettingsButton = () => {
  const [visible, setVisible] = useMedicalLibraryIsSettingsVisible();
  return (
    <Button
      size={"icon"}
      variant={"ghost"}
      onClick={() => {
        setVisible(!visible);
      }}
    >
      <SettingsIcon />
    </Button>
  );
};

const GenericPageFooter = ({ footer }: { footer: React.ReactNode }) => {
  return (
    <div className="flex flex-col justify-start items-start w-full border-t border-t-gray-300 py-4">
      {footer}
    </div>
  );
};

interface GenericPageProps extends PropsWithChildren, PropsWithClassName {
  header: React.ReactNode;
  footer?: React.ReactNode;
}

const GenericPage = ({
  header,
  children,
  footer,
  className,
}: GenericPageProps) => {
  const [location] = useMedicalLibraryLocation();
  const [variant] = useMedicalLibraryVariant();
  const isOnTestPage = location === "test";
  const isOnMobile = variant === "mobile";
  return (
    <ScrollArea.Root className="w-full h-full">
      <ScrollArea.Viewport className={cn("w-full h-full", className)}>
        <div
          className={cn(
            "flex flex-col justify-start items-start h-full w-full gap-5 px-20 py-10",
            (isOnMobile || isOnTestPage) && "px-7",
          )}
        >
          <GenericPageHeader header={header} />
          {children}
          {footer && <GenericPageFooter footer={footer} />}
        </div>
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar orientation="vertical">
        <ScrollArea.Thumb />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner />
    </ScrollArea.Root>
  );
};

export default GenericPage;
