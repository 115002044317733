import { trpc } from "@/utils/trpc";
import useMutateHighlightMarkersQueryCache from "../utility/use-mutate-highlight-markers-query-cache";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import { Highlight, HighlightMarker } from "@/models";
import { current } from "immer";
import { toast } from "sonner";

const useDeleteHighlightMarker = () => {
  const mutateHighlightMarkersCache = useMutateHighlightMarkersQueryCache()();

  //We're not giving it any input so we can mutate all the test slot caches
  const mutateTestSlotCache = useMutateTestSlotQueryCache()();

  let oldHighlightMarker: HighlightMarker | undefined = undefined;

  const oldHighlights: (Pick<
    Highlight,
    "id" | "start" | "end" | "markerId" | "text"
  > & {
    questionId: number | null;
    questionExplanationId: string | null;
  })[] = [];

  return trpc.highlightMarker.deleteHighlightMarker.useMutation({
    onMutate: ({ highlightMarkerId }) => {
      //Remove any highlights made with the marker to be deleted first..
      const rollbackTestSlotCache = mutateTestSlotCache((state) => {
        oldHighlights.push(
          ...state.question.highlights
            .filter((h) => h.markerId === highlightMarkerId)
            .map((h) => ({
              ...h,
              questionId: state.question.id,
              questionExplanationId: null,
            })),
        );
        oldHighlights.push(
          ...state.question.explanation.highlights
            .filter((h) => h.markerId === highlightMarkerId)
            .map((h) => ({
              ...h,
              questionId: null,
              questionExplanationId: state.question.explanation.id,
            })),
        );

        state.question.highlights = state.question.highlights.filter(
          (h) => h.markerId !== highlightMarkerId,
        );
        state.question.explanation.highlights =
          state.question.highlights.filter(
            (h) => h.markerId !== highlightMarkerId,
          );
      });
      const rollbackHighlightMarkers = mutateHighlightMarkersCache((state) => {
        const highlightMarkerIndex = state.findIndex(
          (hm) => hm.id === highlightMarkerId,
        );
        if (highlightMarkerIndex > 0) {
          oldHighlightMarker = current(state[highlightMarkerIndex]);
        }
        state.splice(highlightMarkerIndex, 1);
      });
      return {
        rollback: () => {
          rollbackHighlightMarkers((state) => {
            if (oldHighlightMarker) state.push(oldHighlightMarker);
          });
          rollbackTestSlotCache((state) => {
            state.question.highlights.push(
              ...oldHighlights
                .filter((h) => h.questionId === state.question.id)
                .map((h) => ({
                  id: h.id,
                  start: h.start,
                  end: h.end,
                  markerId: h.markerId,
                  text: h.text,
                })),
            );
            state.question.explanation.highlights.push(
              ...oldHighlights
                .filter(
                  (h) =>
                    h.questionExplanationId === state.question.explanation.id,
                )
                .map((h) => ({
                  id: h.id,
                  start: h.start,
                  end: h.end,
                  markerId: h.markerId,
                  text: h.text,
                })),
            );
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not delete highlight marker.., an error ocurred: ${error.message}`,
      );
      context?.rollback();
    },
  });
};
export default useDeleteHighlightMarker;
