import { trpc } from "@/utils/trpc";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@tanstack/react-router";
import { useEffect } from "react";

const ResumeTestLink = ({
  testId,
  selectedSlot,
}: {
  testId: number;
  selectedSlot: number;
}) => {
  const utils = trpc.useUtils();
  useEffect(() => {
    utils.test.getTestSession.prefetch({ testId });
    utils.testSlot.getTestSlot.prefetch({ testId, slot: selectedSlot });
  }, [testId]);

  trpc.highlightMarker.getHighlightMarkers.usePrefetchQuery();

  return (
    <Link
      from={`/qbanks/$qbankEndpoint/dashboard/previous-tests`}
      to={`/qbanks/$qbankEndpoint/tests/$testId`}
      params={{ testId: String(testId) }}
    >
      <FontAwesomeIcon icon={faPlayCircle} />
    </Link>
  );
};
export default ResumeTestLink;
