import SubjectCheckbox from "./SubjectCheckbox";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import splitArray from "@/lib/utils/split-array";
import { useOnDesktop, useOnMediumScreen, useOnMobile } from "@/hooks";
import useCreateTestPageQuestionBankUsage from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-createtest-page-questionbank-usage";

const CreateTestSubjects = () => {
  const {
    data: usage,
    isLoading: isUsageLoading,
    isPlaceholderData,
  } = useCreateTestPageQuestionBankUsage();
  const subjects = Object.keys(usage?.subjects ?? {});
  const onMediumScreen = useOnMediumScreen();
  const onLargeScreen = useOnDesktop();
  const onSmallScreen = useOnMobile();

  //Sorts array alphabetically
  const orderedSubjects = subjects.sort((a, b) => a.localeCompare(b));
  const subjectGroupCount = onLargeScreen ? 2 : onMediumScreen ? 1 : 1;
  const subjectGroups = splitArray(orderedSubjects, subjectGroupCount);

  return (
    <div className="flex flex-col md:flex-row gap-1 justify-start items-start lg:gap-8">
      {subjectGroups.map((sg, index) => {
        return (
          <div
            key={index}
            className="flex flex-col justify-start items-center lg:items-start gap-y-2 w-full"
          >
            {sg &&
              sg.map((s) => {
                const subject = usage?.subjects?.[s];
                return (
                  <SubjectCheckbox
                    subjectName={subject?.name ?? ""}
                    key={s}
                    subjectId={s}
                    questionCount={usage?.subjects[s]?.count ?? 0}
                    loading={isUsageLoading || isPlaceholderData}
                  />
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default CreateTestSubjects;
