import NotesPageNoteCard from "./notes-page-note-card";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { twMerge } from "tailwind-merge";
import useNotesPageNotes from "../-notes-page-hooks/use-notes-page-notes";
import useEditQuestionNote from "@/hooks/question-note-hooks/use-edit-question-note";
import useDeleteQuestionNote from "@/hooks/question-note-hooks/use-delete-question-note";
import { BlurInOut } from "@/ncomponents/utilities/animations/blur-in-out";
import _ from "underscore";
import { useNotesPageSortBy } from "../-store";
import { AnimatePresence } from "framer-motion";

const NotesPageNotesContainer = ({
  qbankEndpoint,
}: {
  qbankEndpoint: string;
}) => {
  const { data: response, isLoading, isPlaceholderData } = useNotesPageNotes();
  const { mutate: editQuestionNote } = useEditQuestionNote();
  const { mutate: deleteQuestionNote } = useDeleteQuestionNote();
  const [sortBy] = useNotesPageSortBy();

  return (
    <AnimatePresence mode="wait">
      <BlurInOut
        className={twMerge(
          "w-full min-h-full h-full flex flex-col justify-start items-center gap-5",
        )}
        delay={0}
        duration={0.3}
        state={isLoading || isPlaceholderData ? "hidden" : "visible"}
        key={sortBy}
      >
        {!isLoading && response?.notes && (
          <p className="w-full text-start text-sm md:text-base text-gray-800 dark:text-neutral-300">
            {response.notesCount} Total Note
            {response.notesCount !== 1 ? "s" : ""}
          </p>
        )}
        <div
          className={twMerge(
            "w-full min-h-full h-full flex flex-col justify-start items-center gap-5",
            isLoading && "justify-center",
          )}
        >
          {isLoading && (
            <LoadingSpinner key={"loading"} className="w-12 h-12" />
          )}
          {!isLoading &&
            response?.notes &&
            _.sortBy([...response.notes], (n) => {
              switch (sortBy) {
                case "questionId":
                  return n.question.uWolrdId;
                case "topic":
                  return n.question.topic;
                case "subject":
                  return n.question.subject;
                case "system":
                  return n.question.system;
              }
            }).map((note) => (
              <NotesPageNoteCard
                key={note.question.id}
                text={note.text}
                subject={note.question.subject}
                system={note.question.system}
                uWorldId={note.question.uWolrdId}
                topic={note.question.topic}
                onNoteEdited={(text) =>
                  editQuestionNote({ questionId: note.question.id, text })
                }
                qbankEndpoint={qbankEndpoint}
                onNoteDeleted={() =>
                  deleteQuestionNote({ questionId: note.question.id })
                }
              />
            ))}
        </div>
      </BlurInOut>
    </AnimatePresence>
  );
};

export default NotesPageNotesContainer;
