import { trpc } from "@/utils/trpc";
import { GetQuestionBankQuestionNotesInput } from "@/api/src/server/routers/question-note-router/question-note-router-queries";
import { useEffect } from "react";

const useQuestionBankQuestionNotes = (
  input: GetQuestionBankQuestionNotesInput,
) => {
  const utils = trpc.useUtils();
  const query = trpc.questionNote.getQuestionBankQuestionNotes.useQuery(input, {
    enabled: !!input.questionBankId || !!input.questionBankEndpoint,
    placeholderData: (previous) => previous,
  });
  useEffect(() => {
    if (input.page > 1) {
      utils.questionNote.getQuestionBankQuestionNotes.prefetch({
        ...input,
        page: input.page - 1,
      });
    }
    if (query.data && !!query.data.nextPage) {
      utils.questionNote.getQuestionBankQuestionNotes.prefetch({
        ...input,
        page: input.page + 1,
      });
    }
  }, [input.page, input.questionBankId, query.data]);
  return query;
};

export default useQuestionBankQuestionNotes;
