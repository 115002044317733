import { useAppStore } from "@/store";
import { trpc } from "@/utils/trpc";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import { toast } from "sonner";
import { current } from "immer";

const useClearQuestionHighlights = () => {
  const mutateTestSlot = useMutateTestSlotQueryCache()();
  let oldQuestionHighlights: {
    id: string;
    start: number;
    end: number;
    text: string;
    markerId: string;
  }[] = [];
  let oldExplanationHighlights: {
    id: string;
    start: number;
    end: number;
    text: string;
    markerId: string;
  }[] = [];
  return trpc.highlight.clearQuestionHighlights.useMutation({
    onMutate: ({ questionId }) => {
      const rollbackTestSlot = mutateTestSlot((state) => {
        if (state.question.id === questionId) {
          oldQuestionHighlights = current(state.question.highlights);
          oldExplanationHighlights = current(
            state.question.explanation.highlights,
          );
          state.question.highlights = [];
          state.question.explanation.highlights = [];
        }
      });
      return {
        rollback: () => {
          rollbackTestSlot((state) => {
            if (state.question.id === questionId) {
              state.question.highlights = oldQuestionHighlights;
              state.question.explanation.highlights = oldExplanationHighlights;
            }
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not clear question highlights, an error occurred: ${error.message}`,
      );
      context?.rollback();
    },
  });
};
export default useClearQuestionHighlights;
