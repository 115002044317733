"use client";

import * as React from "react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { X } from "lucide-react";
import { cn } from "@/lib/utils";
import { trpc } from "@/utils/trpc";
import useSignIn from "@/hooks/auth-hooks/use-sign-in";

interface PromoCodeInputProps extends React.HTMLAttributes<HTMLFormElement> {}

export default function PromoCodeInput({
  className,
  ...props
}: PromoCodeInputProps) {
  const [code, setCode] = React.useState("");
  const signIn = useSignIn();
  const {
    mutate: checkPromoCode,
    data: promoCode,
    isPending: isChecking,
    error,
    reset,
  } = trpc.promoCode.checkPromoCode.useMutation();
  const { mutate: usePromoCode, isPending: isUsingPromoCode } =
    trpc.promoCode.usePromoCode.useMutation();
  const isValid = !!promoCode;
  const isLoading = isChecking || isUsingPromoCode;

  const handleApply = () => {
    if (isValid) {
      usePromoCode(
        { code: promoCode.code },
        {
          onSuccess: (newUserToken) => {
            if (newUserToken) signIn(newUserToken);
          },
        },
      );
    } else {
      checkPromoCode({ code });
    }
  };

  const handleClear = () => {
    setCode("");
    reset();
  };

  const getButtonClassName = () => {
    if (error)
      return "bg-red-500 hover:bg-red-600 dark:bg-red-500 dark:hover:bg-red-600 text-white";
    if (isValid)
      return "bg-green-500 dark:bg-green-500 dark:hover:bg-green-600 hover:bg-green-600 text-white";
    return "bg-primary-400 dark:bg-primary-400 dark:hover:bg-primary-500 hover:bg-primary-500 text-white";
  };

  return (
    <form
      className={cn("w-full max-w-md space-y-3", className)}
      onSubmit={(e) => {
        e.preventDefault();
        handleApply();
      }}
      {...props}
    >
      <Label
        htmlFor="promo-code"
        className="text-lg text-center font-medium w-full dark:text-neutral-200"
      >
        Got a promo code?
      </Label>
      <div
        className={cn(
          "flex h-14 w-full items-center rounded-lg border bg-background px-3 py-2 text-sm ring-offset-background",
          error
            ? "border-red-500"
            : isValid
              ? "border-green-500"
              : "border-primary-500",
          "focus-within:ring-1 focus-within:ring-ring",
        )}
      >
        <input
          id="promo-code"
          type="text"
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
            reset();
          }}
          className="flex-1 border-0 bg-transparent p-2 text-sm placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          placeholder="Enter promo code"
          disabled={isValid}
        />
        <div className="flex items-center gap-2">
          {isValid && (
            <Button
              type="button"
              onClick={handleClear}
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0 hover:bg-gray-100"
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Clear promo code</span>
            </Button>
          )}
          <Button
            type="submit"
            disabled={!code || isLoading}
            className={cn(
              "transition-colors",
              getButtonClassName(),
              (isLoading || !code) && "opacity-50",
            )}
            size="sm"
          >
            {isLoading
              ? isChecking
                ? "Checking..."
                : isUsingPromoCode
                  ? "Loading..."
                  : ""
              : isValid
                ? "Apply"
                : "Check"}
          </Button>
        </div>
      </div>
      {error && <p className="text-sm text-red-500">{error.message}</p>}
      {isValid && (
        <p className="text-sm font-medium text-green-500">
          Promo code {promoCode.code} adds {promoCode.extraDays}{" "}
          {promoCode.extraDays === 1 ? "Day" : "Days"} to your subscription,
          Apply?
        </p>
      )}
    </form>
  );
}
