import useMutatePreviousTestsQueryCache from "@/hooks/utility/use-mutate-previous-tests-query-cache";
import {
  usePreviousTestsPageCurrentPage,
  usePreviousTestsPageSearch,
  usePreviousTestsPageTestsPerPage,
} from "@/utils/stores/previousTestsPageStore";
import { useParams } from "@tanstack/react-router";
import { useMemo } from "react";

const useMutatePreviousTestsPageQueryCache = () => {
  const { qbankEndpoint } = useParams({ strict: false });
  const [page] = usePreviousTestsPageCurrentPage();
  const [search] = usePreviousTestsPageSearch();
  const [testsPerPage] = usePreviousTestsPageTestsPerPage();
  const cacheMutator = useMutatePreviousTestsQueryCache();
  return useMemo(
    () =>
      cacheMutator({
        page,
        search,
        testsPerPage,
        questionBankEndpoint: qbankEndpoint ?? "",
      }),
    [page, search, testsPerPage, qbankEndpoint],
  );
};

export default useMutatePreviousTestsPageQueryCache;
