import useMutateQueryCache from "./use-mutate-query-cache";
import { trpc } from "@/utils/trpc";
import {
  GetQuestionBankQuestionNotesInput,
  GetQuestionBankQuestionNotesOutput,
} from "@/api/src/server/routers/question-note-router/question-note-router-queries";

const useMutateQuestionBankQuestionNotesQueryCache = () => {
  return useMutateQueryCache<
    GetQuestionBankQuestionNotesInput,
    GetQuestionBankQuestionNotesOutput
  >(trpc.questionNote.getQuestionBankQuestionNotes, "query");
};

export default useMutateQuestionBankQuestionNotesQueryCache;
