import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import {
  faChartColumn,
  faChartLine,
  faChevronDown,
  faChevronUp,
  faClipboardCheck,
  faPieChart,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import usePerformanceReportsPageStore from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.performance/reports/-store";
import { trpc } from "@/utils/trpc";
import { useParams } from "@tanstack/react-router";

export const PerformanceButton = () => {
  const { qbankEndpoint } = useParams({ strict: false });
  const utils = trpc.useUtils();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  return (
    <>
      <Button
        hideSidebarOnClick={false}
        onClick={() => setIsCollapsed((prev) => !prev)}
        className="relative"
      >
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="flex flex-row justify-start items-center gap-2">
            <FontAwesomeIcon icon={faChartColumn} />
            <p>Performance</p>
          </div>
          <div className="absolute right-7">
            <FontAwesomeIcon icon={isCollapsed ? faChevronUp : faChevronDown} />
          </div>
        </div>
      </Button>
      {isCollapsed && (
        <div className="flex flex-col justify-start items-start gap-1 ml-5">
          <Button
            onMouseEnter={() => {
              utils.statistics.getQuestionBankOverallStatistics.prefetch({
                questionBankEndpoint: qbankEndpoint ?? "",
              });
            }}
            href="performance/overall"
          >
            <FontAwesomeIcon icon={faClipboardCheck} />
            <p>Overall</p>
          </Button>
          <Button
            onMouseEnter={() => {
              const {
                currentTab,
                currentPage: page,
                reportsPerPage,
                query: search,
              } = usePerformanceReportsPageStore.getState();
              utils.statistics.getQuestionBankPerformanceReports.prefetch({
                for: currentTab,
                questionBankEndpoint: qbankEndpoint ?? "",
                page,
                reportsPerPage,
                search,
              });
            }}
            href="performance/reports"
          >
            <FontAwesomeIcon icon={faPieChart} />
            <p>Reports</p>
          </Button>
          <Button href="performance/graphs">
            <FontAwesomeIcon icon={faChartLine} />
            <p>Graphs</p>
          </Button>
        </div>
      )}
    </>
  );
};
