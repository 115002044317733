import { QuestionBankUsageInput } from "@/api/src/server/routers/statistics/types";
import { trpc } from "@/utils/trpc";

const useQuestionBankUsage = (input: QuestionBankUsageInput) => {
  return trpc.statistics.getQuestionbankUsage.useQuery(input, {
    placeholderData: (previous) => previous,
    staleTime: 1000 * 60 * 1,
  });
};

export default useQuestionBankUsage;
