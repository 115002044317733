"use client";
import React, { useMemo, useRef } from "react";
import { NbmeQuestionText } from "../NbmeQuestionText";
import { twMerge } from "tailwind-merge";
import { NbmeChoiceContainer } from "../NbmeChoiceContainer";
import { NbmeChoices } from "../NbmeChoices";
import { Button } from "../Button";
import { useTestStore } from "@/utils/stores/testStore";
import { Interweave } from "interweave";
import { trpc } from "@/utils/trpc";
import { NbmeResultCard } from "../NbmeResultCard";
import {
  audioFilter,
  imageFilter,
  linkFilter,
  listFilter,
  paragraphFilter,
  tableFilter,
  uWorldImageFilter,
  vignetteFilter,
} from "@/utils/interweave/filters";
import { LoadingSpinner } from "../LoadingSpinner";
import {
  highlightTransformer,
  imageTransformer,
  youtubeLinkTransformer,
} from "@/utils/interweave/transformers";
import { analyzeSelection } from "@/utils/highlights/analyzeSelection";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import {
  useNbmeAlignment,
  useNbmeConfirmAnswerOmission,
  useNbmeFontSizeFactor,
  useNbmeFontWeight,
  useNbmeLayoutStore,
} from "@/utils/stores/nbmeLayoutStore";
import { FontSizes } from "@/types";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { useHighlightMarker } from "./hooks";
import { cn } from "@/lib/utils";
import combineInterweaveTransformers from "@/lib/utils/combine-interweave-transformers";
import useTestPageSubmitTestSlot from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-submit-test-slot";
import useCreateHighlight from "@/hooks/highlight-hooks/use-create-highlight";
import { hintTransformer } from "@/utils/interweave/transformers/hintTransformer";
import useTestSlot from "@/hooks/test-slot-hooks/use-testslot";
import { useTestPageSelectedTestSlot } from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";
import useTestPageTestSession from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-test-session";
import gen from "@/lib/gen";
import { useTriggerNbmeContextMenu } from "@/ncomponents/nbme/context-menu";

export const NbmeQuestion = () => {
  const { data: testSession } = useTestPageTestSession();
  const { data: testSlot } = useTestSlot({
    testId: testSession?.id,
    slot: testSession?.selectedSlot,
  });
  const question = testSlot?.question;

  const { setConfirmAnswerOmissionDialogVisible } = useNbmeLayoutStore(
    (state) => ({
      setConfirmAnswerOmissionDialogVisible:
        state.setConfirmAnswerOmissionDialogVisible,
    }),
  );
  const { confirmAnswerOmission } = useNbmeConfirmAnswerOmission();
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const [fontWeight] = useNbmeFontWeight();
  const breakPoint = useBreakPoint();
  const { mutate: addHighlight } = useCreateHighlight();
  const mode = testSession?.mode;
  const { data: selectedSlot } = useTestPageSelectedTestSlot();
  const selectedMarker = useTestStore((state) => state.selectedHighlightMarker);

  const doesQuestionHaveAudio = !!question?.audioId;

  const {
    data: questionAudio,
    isSuccess: isQuestionAudioSuccess,
    isError: isQuestionAudioError,
    error: questionAudioError,
    isLoading: isQuestionAudioLoading,
  } = trpc.audio.getById.useQuery(
    { id: question?.audioId ?? "" },
    {
      enabled: doesQuestionHaveAudio,
    },
  );

  const questionTextRef = useRef<HTMLDivElement>(null);
  const highlightedHtml = useHighlightMarker(
    question?.id ?? 0,
    question?.html ?? "",
  );
  const [alignment] = useNbmeAlignment();
  const submitSelectedSlot = useTestPageSubmitTestSlot();

  const memoizedParagraphFilter = useMemo(
    () => paragraphFilter(fontSizeFactor, fontWeight, alignment),
    [fontSizeFactor, fontWeight, alignment],
  );
  const memoizedTableFilter = useMemo(
    () => tableFilter(breakPoint, fontSizeFactor, fontWeight),
    [breakPoint, fontSizeFactor, fontWeight],
  );
  const memoizedAudioFilter = useMemo(
    () => audioFilter(questionAudio?.dataUrl),
    [questionAudio?.dataUrl],
  );
  const memoizedCombinedTransformer = useMemo(
    () =>
      combineInterweaveTransformers(
        highlightTransformer,
        imageTransformer(),
        hintTransformer({ questionSource: question?.source ?? "" }),
        youtubeLinkTransformer,
      ),
    [question?.source],
  );
  const memoizedInterweaveFilters = useMemo(
    () => [
      listFilter,
      linkFilter,
      vignetteFilter,
      memoizedParagraphFilter,
      memoizedTableFilter,
    ],
    [memoizedParagraphFilter, memoizedTableFilter],
  );
  const trigger = useTriggerNbmeContextMenu();
  return (
    <div
      className={cn(
        "flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start w-full gap-4 lg:p-3 p-4",
        alignment === "center" && "md:justify-center md:items-center",
        alignment === "right" && "md:justify-end md:items-end",
      )}
      style={{
        fontSize: scaleUnitInRem(FontSizes.Base, fontSizeFactor)
          .resultInRemAsCss,
      }}
    >
      {question?.abstract && (
        <div className="flex-[50%]">
          <Interweave
            blockList={["br"]}
            content={question?.abstract}
            filters={memoizedInterweaveFilters}
          />
        </div>
      )}

      <div
        id={`${question?.id}text`}
        ref={questionTextRef}
        className={twMerge(
          "flex w-full flex-col items-center justify-start gap-4 lg:items-start ",
          alignment === "center" && "lg:items-center",
          alignment === "right" && "lg:items-end",
          question?.abstract && "flex-[50%]",
        )}
      >
        {question && question.html ? (
          <div
            id={`question-text`}
            key={`question-${question.id}-text`}
            ref={questionTextRef}
            onMouseUp={() => handleHighlight()}
            onTouchEnd={(e) => handleHighlight(e)}
          >
            <Interweave
              content={highlightedHtml}
              filters={memoizedInterweaveFilters}
              transform={memoizedCombinedTransformer}
            />
          </div>
        ) : (
          <>{/*<NbmeQuestionText>{question?.text}</NbmeQuestionText>*/}</>
        )}
        {question &&
          question.mediaHtml &&
          (doesQuestionHaveAudio ? (
            isQuestionAudioLoading ? (
              <div className="flex w-full flex-row items-center justify-center md:w-auto">
                <div className="w-10 md:w-12 lg:w-14">
                  <LoadingSpinner />
                </div>
              </div>
            ) : (
              <Interweave
                content={question.mediaHtml}
                filters={[memoizedAudioFilter]}
              />
            )
          ) : (
            <Interweave content={question.mediaHtml} />
          ))}
        <NbmeChoiceContainer>
          <NbmeChoices />
        </NbmeChoiceContainer>
        <NbmeResultCard />
        {!selectedSlot?.submitted && mode?.split(",").includes("tutor") && (
          <Button
            NBME
            type="button"
            className="w-min"
            onClick={() => {
              if (confirmAnswerOmission && !selectedSlot?.chosenId) {
                setConfirmAnswerOmissionDialogVisible(true);
                return;
              }
              submitSelectedSlot();
            }}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );

  function handleHighlight(touchEvent?: React.TouchEvent) {
    const windowSelection = window.getSelection();
    const analysis = analyzeSelection(windowSelection);
    if (!analysis) return;
    if (!analysis.selectionText) return;
    if (analysis.selectionText === " " || analysis.selectionText.length === 0) {
      return;
    }
    //If the selection is one character long we ignore it...
    //just to be on the safe side....
    if (analysis.selectionText.length === 1) return;

    const newHighlight = {
      id: gen.cuid(),
      text: analysis.selectionText!,
      ocurrence: analysis.ocurrence,
      questionId: question?.id ?? null,
      questionExplanationId: null,
      articleId: null,
      start: analysis.startIndex,
      end: analysis.endIndex,
    };
    if (selectedMarker) {
      addHighlight({
        highlight: { ...newHighlight, markerId: selectedMarker.id },
      });
      windowSelection?.removeAllRanges();
      return;
    }

    trigger(
      [
        {
          type: "create-highlight",
          newHighlight,
          onNewHighlight: () => {
            windowSelection?.removeAllRanges();
          },
        },
        { type: "copy-to-clipboard", textToCopy: analysis.selectionText },
      ],
      touchEvent
        ? {
            position: {
              x: touchEvent.changedTouches[0].clientX,
              y: touchEvent.changedTouches[0].clientY,
            },
          }
        : undefined,
    );
  }
};
