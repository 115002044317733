import { trpc } from "@/utils/trpc";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import { toast } from "sonner";

const useClearTestHighlights = () => {
  const mutateTestSlots = useMutateTestSlotQueryCache()();

  return trpc.highlight.clearTestHighlights.useMutation({
    onMutate: ({ testId }) => {
      const oldQuestionHighlights: {
        id: string;
        start: number;
        end: number;
        text: string;
        markerId: string;
        slotId: string;
      }[] = [];
      const oldExplanationHighlights: {
        id: string;
        start: number;
        end: number;
        text: string;
        markerId: string;
        slotId: string;
      }[] = [];
      const rollbackTestSlots = mutateTestSlots((state) => {
        if (state.testId === testId) {
          oldQuestionHighlights.push(
            ...state.question.highlights.map((h) => ({
              ...h,
              slotId: state.id,
            })),
          );
          oldExplanationHighlights.push(
            ...state.question.explanation.highlights.map((h) => ({
              ...h,
              slotId: state.id,
            })),
          );
          state.question.highlights = [];
          state.question.explanation.highlights = [];
        }
      });
      return {
        rollback: () => {
          rollbackTestSlots((state) => {
            if (state.testId === testId) {
              state.question.highlights = oldQuestionHighlights.filter(
                (h) => h.slotId === state.id,
              );
              state.question.explanation.highlights =
                oldExplanationHighlights.filter((h) => h.slotId === state.id);
            }
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not clear test highlights, an error occurred: ${error.message}`,
      );
      context?.rollback();
    },
  });
};
export default useClearTestHighlights;
