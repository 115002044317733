import { NbmeHighlight } from "@/components/NbmeHighlight";
import { TransformCallback } from "interweave";

export const highlightTransformer: TransformCallback = (
  node,
  children,
): React.ReactNode => {
  const isHighlight = node.getAttribute("data-markjs") === "true";
  const id = node.getAttribute("data-id");
  const markerId = node.getAttribute("data-marker-id");
  const textToCopy = node.getAttribute("data-text");

  if (!id) return;
  if (!isHighlight) return;

  return (
    <NbmeHighlight
      id={id}
      markerId={markerId!}
      color={node.style.backgroundColor}
      text={node.textContent || ""}
      textToCopy={textToCopy || ""}
    >
      {children}
    </NbmeHighlight>
  );
};
