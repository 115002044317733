import { trpc } from "@/utils/trpc";
import useMutateHighlightMarkersQueryCache from "../utility/use-mutate-highlight-markers-query-cache";
import { toast } from "sonner";

const useCreateHighlightMarker = () => {
  const mutateHighlightMarkersCache = useMutateHighlightMarkersQueryCache()();
  return trpc.highlightMarker.createHighlightMarker.useMutation({
    onMutate: ({ id, name, color }) => {
      const rollbackHighlightMarkers = mutateHighlightMarkersCache((state) => {
        const anyHighlightMarkerWithUserId = state.find(
          (hm) => hm.userId !== null,
        );

        const userId = anyHighlightMarkerWithUserId
          ? anyHighlightMarkerWithUserId.userId
          : null;

        state.push({ id, name, color, userId });
      });
      return {
        rollback: () => {
          rollbackHighlightMarkers((state) => {
            const highlightMarkerIndex = state.findIndex(
              (marker) => marker.id === id,
            );
            if (highlightMarkerIndex > 0) {
              state.splice(highlightMarkerIndex, 1);
            }
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not create highlight marker..., an error ocurred: ${error.message}`,
      );
      context?.rollback();
    },
    onSuccess: (data, variables, context) => {
      mutateHighlightMarkersCache((state) => {
        const targetHighlightMarker = state.find((hm) => hm.id === data.id);
        if (targetHighlightMarker) {
          targetHighlightMarker.userId = data.userId;
        }
      });
    },
  });
};

export default useCreateHighlightMarker;
