import { GetArticleContentInput } from "@/api/src/server/routers/article";
import { trpc } from "@/utils/trpc";

const useArticleContent = (
  input: GetArticleContentInput,
  options = { enabled: true },
) => {
  return trpc.article.getArticleContent.useQuery(input, {
    enabled: !!input.articleId && options.enabled,
    placeholderData: (previous) => previous,
  });
};

export default useArticleContent;
