import { trpc } from "@/utils/trpc";
import { current } from "immer";
import { toast } from "sonner";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import useMutateTestSessionQueryCache from "../utility/use-mutate-test-session-query-cache";
import useMutateNotesPageNotesQueryCache from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.notes/-notes-page-hooks/use-mutate-notes-page-notes-query-cache";

const useDeleteQuestionNote = () => {
  const utils = trpc.useUtils();
  const mutateTestSlotQueryCache = useMutateTestSlotQueryCache()(undefined);

  const mutateQuestionNotesQueryCache = useMutateNotesPageNotesQueryCache();

  const mutateTestSessionQueryCache =
    useMutateTestSessionQueryCache()(undefined);

  let oldQuestionNote:
    | {
        text: string;
        question: {
          id: number;
          uWolrdId: number;
          subject: string;
          system: string;
          topic: string;
        };
      }
    | null
    | undefined = undefined;
  const mutation = trpc.questionNote.deleteQuestionNote.useMutation({
    onMutate: ({ questionId }) => {
      utils.questionNote.getQuestionBankQuestionNotes.invalidate();
      const rollbackTestSlot = mutateTestSlotQueryCache((state) => {
        oldQuestionNote = null;
        if (state.question.id === questionId && state.question.note) {
          oldQuestionNote = {
            ...current(state.question.note),
            question: {
              id: state.question.id,
              uWolrdId: state.question.uWolrdId,
              subject: state.question.subject,
              system: state.question.system,
              topic: state.question.topic,
            },
          };
          state.question.note = null;
        }
      });
      const rollbackTestSession = mutateTestSessionQueryCache((state) => {
        const targetSlot = state.slots.find((s) => s.questionId === questionId);
        if (targetSlot) {
          targetSlot.hasNote = false;
        }
      });
      const rollbackQuestionNotes = mutateQuestionNotesQueryCache((state) => {
        const targetNoteIndex = state.notes.findIndex(
          (n) => n.question.id === questionId,
        );
        if (targetNoteIndex < 0) return;
        oldQuestionNote = current(state.notes[targetNoteIndex]);
        state.notes.splice(targetNoteIndex, 1);
      });
      return {
        rollback: () => {
          rollbackTestSlot((state) => {
            if (
              state.question.id === questionId &&
              oldQuestionNote !== undefined
            ) {
              state.question.note = oldQuestionNote
                ? { text: oldQuestionNote.text }
                : null;
            }
          });
          rollbackTestSession((state) => {
            const targetSlot = state.slots.find(
              (s) => s.questionId === questionId,
            );
            if (targetSlot) {
              targetSlot.hasNote = true;
            }
          });
          rollbackQuestionNotes((state) => {
            if (oldQuestionNote) {
              state.notes.push(oldQuestionNote);
            }
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Failed to delete question note.., an error ocurred: ${error.message}`,
      );
      context?.rollback?.();
    },
  });

  return mutation;
};
export default useDeleteQuestionNote;
