import useMutateQuestionBankOverallStatisticsQueryCache from "@/hooks/utility/use-mutate-questionbank-overall-statistics-query-cache";
import { useParams } from "@tanstack/react-router";
import { useMemo } from "react";

const useMutateOverallStatisticsPageQueryCache = () => {
  const { qbankEndpoint } = useParams({ strict: false });
  const cacheMutator = useMutateQuestionBankOverallStatisticsQueryCache();
  return useMemo(
    () =>
      cacheMutator({
        questionBankEndpoint: qbankEndpoint,
      }),
    [qbankEndpoint],
  );
};

export default useMutateOverallStatisticsPageQueryCache;
