import {
  useNbmeLayoutStore,
  useNbmeTheme,
} from "@/utils/stores/nbmeLayoutStore";
import Dialog from "./Dialog";
import { Button } from "./Button";
import useTestPageSubmitTestSlot from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-submit-test-slot";

export const NbmeConfirmOmissionDialog = () => {
  const [theme] = useNbmeTheme();
  const submitSelectedSlot = useTestPageSubmitTestSlot();

  const {
    confirmAnswerOmissionDialogVisible,
    setConfirmAnswerOmissionDialogVisible,
  } = useNbmeLayoutStore((state) => ({
    confirmAnswerOmissionDialogVisible:
      state.confirmAnswerOmissionDialogVisible,
    setConfirmAnswerOmissionDialogVisible:
      state.setConfirmAnswerOmissionDialogVisible,
  }));

  return (
    <Dialog
      dark={theme === "dark"}
      title={"Omit Question?"}
      description="You have not answered this question, Do you wish to continue without answering?"
      open={confirmAnswerOmissionDialogVisible}
      onOpenChange={setConfirmAnswerOmissionDialogVisible}
      onCloseClicked={() => setConfirmAnswerOmissionDialogVisible(false)}
    >
      <div className="flex flex-row justify-end items-center mt-4">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button
            className="p-1 px-2"
            onClick={() => setConfirmAnswerOmissionDialogVisible(false)}
          >
            No
          </Button>
          <Button
            className="p-1 px-2"
            error
            onClick={() => {
              setConfirmAnswerOmissionDialogVisible(false);
              submitSelectedSlot();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
