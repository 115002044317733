import { buttonVariants } from "@/components/ui/button";
import { trpc } from "@/utils/trpc";
import { Link } from "@tanstack/react-router";
import useTestResult from "../results/-hooks/use-test-result";
import { useEffect } from "react";

interface PerformanceTestPageReviewTestButtonProps {
  testId: string;
}

export const PerformanceTestPageReviewTestButton = ({
  testId,
}: PerformanceTestPageReviewTestButtonProps) => {
  const { data } = useTestResult({ testId: Number(testId) });

  const utils = trpc.useUtils();

  useEffect(() => {
    if (data?.selectedSlot && testId) {
      utils.testSlot.getTestSlot.prefetch({
        testId: Number(testId),
        slot: data.selectedSlot,
      });
      utils.test.getTestSession.prefetch({ testId: Number(testId) });
    }
  }, [data?.selectedSlot, testId]);

  trpc.highlightMarker.getHighlightMarkers.usePrefetchQuery();
  return (
    <Link
      type="button"
      className={buttonVariants({ variant: "dashboard" })}
      from={"/qbanks/$qbankEndpoint/dashboard/performance/test/$testId"}
      to={"/qbanks/$qbankEndpoint/tests/$testId"}
      params={{ testId }}
    >
      Review Test
    </Link>
  );
};
