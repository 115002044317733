import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { usePreviousTestsPageShowDeletedTests } from "@/utils/stores/previousTestsPageStore";
import React from "react";

const ShowDeletedTests: React.FC = () => {
  const [showDeletedTests, setShowDeletedTests] =
    usePreviousTestsPageShowDeletedTests();

  return (
    <div className="flex flex-row justify-center items-center gap-3">
      <Label>Show Deleted Tests</Label>
      <Switch
        checked={showDeletedTests}
        onCheckedChange={(v) => setShowDeletedTests(v)}
      />
    </div>
  );
};

export default ShowDeletedTests;
