"use client"

import * as React from "react"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Input } from "@/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, ArrowUpDown, Search, Loader2 } from "lucide-react"
import { Button } from "@/components/ui/button"
import { useDebounce } from "@/hooks/common/use-debounce"
import { usePromocodes } from "@/hooks/promocodes/use-promocodes"
import { useNavigate } from "@tanstack/react-router"

type SortField = "code" | "extraDays" | "usedBy"
type SortOrder = "asc" | "desc"

const PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100]

export function PromocodesTable() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1)
  const [sortField, setSortField] = React.useState<SortField>("code")
  const [sortOrder, setSortOrder] = React.useState<SortOrder>("asc")
  const [codesPerPage, setCodesPerPage] = React.useState(10)

  const debouncedSearch = useDebounce(searchQuery, 300)

  const { data, isLoading, isError, isPlaceholderData } = usePromocodes({
    page: currentPage,
    sortBy: sortField,
    order: sortOrder,
    search: debouncedSearch,
    codesPerPage,
  })

  // Reset to first page when changing codes per page
  const handleCodesPerPageChange = (value: string) => {
    setCodesPerPage(Number(value))
    setCurrentPage(1)
  }

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    } else {
      setSortField(field)
      setSortOrder("asc")
    }
  }

  if (isError) {
    return (
      <div className="rounded-md bg-neutral-800 border border-neutral-700 p-4">
        <p className="text-neutral-200">Failed to load promocodes. Please try again later.</p>
      </div>
    )
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-4 flex-1">
          <div className="relative flex-1 max-w-sm">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-neutral-400" />
            <Input
              type="search"
              placeholder="Search promocodes..."
              className="pl-8 bg-neutral-800 border-neutral-700 text-neutral-100 placeholder:text-neutral-400 focus-visible:ring-neutral-700"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <Button
            onClick={() => navigate({ to: "/admin/promocodes/new" })}
            className="bg-neutral-800 dark:bg-neutral-800 dark:text-neutral-100 dark:border-neutral-700 dark:hover:bg-neutral-700"
          >
            Create Promocode
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-sm text-neutral-400">Codes per page</span>
          <Select value={codesPerPage.toString()} onValueChange={handleCodesPerPageChange}>
            <SelectTrigger className="w-[100px] bg-neutral-800 border-neutral-700 text-neutral-100 focus:ring-neutral-700">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="bg-neutral-800 border-neutral-700">
              {PAGE_SIZE_OPTIONS.map((size) => (
                <SelectItem
                  key={size}
                  value={size.toString()}
                  className="text-neutral-100 focus:bg-neutral-700 focus:text-neutral-100"
                >
                  {size} rows
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div
        className={`rounded-md border border-neutral-700 bg-neutral-800 transition-opacity duration-200 ${isPlaceholderData ? "opacity-60" : "opacity-100"
          }`}
      >
        <Table>
          <TableHeader>
            <TableRow className="border-neutral-700 hover:bg-neutral-800">
              <TableHead>
                <Button
                  variant="ghost"
                  onClick={() => handleSort("code")}
                  className="h-8 text-left font-medium text-neutral-200 hover:text-neutral-100 hover:bg-neutral-700"
                >
                  Code
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              </TableHead>
              <TableHead>
                <Button
                  variant="ghost"
                  onClick={() => handleSort("extraDays")}
                  className="h-8 text-left font-medium text-neutral-200 hover:text-neutral-100 hover:bg-neutral-700"
                >
                  Extra Days
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              </TableHead>
              <TableHead>
                <Button
                  variant="ghost"
                  onClick={() => handleSort("usedBy")}
                  className="h-8 text-left font-medium text-neutral-200 hover:text-neutral-100 hover:bg-neutral-700"
                >
                  Usage Count
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow className="hover:bg-neutral-800 border-neutral-700">
                <TableCell colSpan={3} className="h-24 text-center text-neutral-400">
                  <Loader2 className="h-6 w-6 animate-spin mx-auto" />
                </TableCell>
              </TableRow>
            ) : data?.promocodes.length === 0 ? (
              <TableRow className="hover:bg-neutral-800 border-neutral-700">
                <TableCell colSpan={3} className="h-24 text-center text-neutral-400">
                  No promocodes found.
                </TableCell>
              </TableRow>
            ) : (
              data?.promocodes.map((promocode) => (
                <TableRow key={promocode.id} className="hover:bg-neutral-700/50 border-neutral-700">
                  <TableCell className="font-medium text-neutral-100">{promocode.code}</TableCell>
                  <TableCell className="text-neutral-200">{promocode.extraDays} days</TableCell>
                  <TableCell className="text-neutral-200">{promocode.usedBy} Users</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-between text-neutral-200">
        <div className="text-sm text-neutral-400">
          Showing {data?.promocodes.length ?? 0} of {data?.totalPages ? data.totalPages * codesPerPage : 0} results
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            size="icon"
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1 || isLoading}
            className="border-neutral-700 bg-neutral-800 hover:bg-neutral-700 hover:text-neutral-100 disabled:opacity-50"
          >
            <ChevronsLeft className="h-4 w-4" />
            <span className="sr-only">First page</span>
          </Button>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1 || isLoading}
            className="border-neutral-700 bg-neutral-800 hover:bg-neutral-700 hover:text-neutral-100 disabled:opacity-50"
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Previous page</span>
          </Button>
          <span className="text-sm text-neutral-300">
            Page {currentPage} of {data?.totalPages ?? 1}
          </span>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === (data?.totalPages ?? 1) || isLoading}
            className="border-neutral-700 bg-neutral-800 hover:bg-neutral-700 hover:text-neutral-100 disabled:opacity-50"
          >
            <ChevronRight className="h-4 w-4" />
            <span className="sr-only">Next page</span>
          </Button>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setCurrentPage(data?.totalPages ?? 1)}
            disabled={currentPage === (data?.totalPages ?? 1) || isLoading}
            className="border-neutral-700 bg-neutral-800 hover:bg-neutral-700 hover:text-neutral-100 disabled:opacity-50"
          >
            <ChevronsRight className="h-4 w-4" />
            <span className="sr-only">Last page</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

