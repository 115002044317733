import { useParams } from "@tanstack/react-router";
import {
  useNotesPageCurrentPage,
  useNotesPageNotesPerPage,
  useNotesPageSearch,
  useNotesPageSortBy,
} from "../-store";
import { useMemo } from "react";
import useMutateQuestionBankQuestionNotesQueryCache from "@/hooks/utility/use-mutate-question-bank-question-notes-query-cache";
const useMutateNotesPageNotesQueryCache = () => {
  const [page] = useNotesPageCurrentPage();
  const [notesPerPage] = useNotesPageNotesPerPage();
  const [search] = useNotesPageSearch();
  const [orderBy] = useNotesPageSortBy();
  const getCacheMutator = useMutateQuestionBankQuestionNotesQueryCache();

  return useMemo(
    () =>
      getCacheMutator({
        page,
        notesPerPage,
        search,
        orderBy,
      }),
    [page, notesPerPage, search, orderBy, getCacheMutator],
  );
};

export default useMutateNotesPageNotesQueryCache;
