import useQuestionBankUsage from "@/hooks/statistics/use-questionbank-usage";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import { useParams } from "@tanstack/react-router";

const useCreateTestPageQuestionBankUsage = () => {
  const { qbankEndpoint } = useParams({
    from: "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test",
  });
  const { unused, incorrect, marked, correct, omitted, selectedSubjects } =
    useCreateTestPageStore();

  return useQuestionBankUsage({
    questionBankEndpoint: qbankEndpoint,
    pool: [
      ...(unused ? ["unused"] : []),
      ...(incorrect ? ["incorrect"] : []),
      ...(marked ? ["marked"] : []),
      ...(correct ? ["correct"] : []),
      ...(omitted ? ["omitted"] : []),
    ] as ("unused" | "incorrect" | "marked" | "correct" | "omitted")[],
    subjects: selectedSubjects,
  });
};

export default useCreateTestPageQuestionBankUsage;
