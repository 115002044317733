import { Input } from "@/components/ui/input"
import { InfoList, InfoItem } from "./info-list"
import { SectionHeader } from "./section-header"

interface GeneralSettingsSectionProps {
  name: string
  username: string
  email: string
  phone: string
  country: string
  memberSince: string
  promoCode?: string | null
}

export function GeneralSettingsSection({
  name,
  username,
  email,
  phone,
  country,
  memberSince,
  promoCode,
}: GeneralSettingsSectionProps) {
  return (
    <div className="space-y-4 rounded-lg border border-border bg-card p-3 sm:p-4">
      <h2 className="text-lg font-bold sm:text-xl mb-6">General Settings</h2>
      <div className="grid gap-6">
        {/* Personal Information */}
        <div className="space-y-3">
          <SectionHeader title="Personal Information" />
          <InfoList>
            <InfoItem label="Name">{name}</InfoItem>
            <InfoItem label="Username">{username}</InfoItem>
          </InfoList>
        </div>

        {/* Contact Information */}
        <div className="space-y-3">
          <SectionHeader title="Contact Information" />
          <InfoList>
            <InfoItem label="Email">
              <Input value={email} disabled className="bg-muted/50 text-sm sm:text-base [&:not(:focus)]:opacity-70" />
            </InfoItem>
            <InfoItem label="Phone Number">
              <Input value={phone} disabled className="bg-muted/50 text-sm sm:text-base [&:not(:focus)]:opacity-70" />
            </InfoItem>
            <InfoItem label="Country">{country}</InfoItem>
          </InfoList>
        </div>

        {/* Account Details */}
        <div className="space-y-3">
          <SectionHeader title="Account Details" />
          <InfoList>
            <InfoItem label="Member Since">{memberSince}</InfoItem>
            {promoCode && <InfoItem label="Promo Code Used">{promoCode}</InfoItem>}
          </InfoList>
        </div>
      </div>
    </div>
  )
}

