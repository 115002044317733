import { trpc } from "@/utils/trpc";
import useMutateTestSessionQueryCache from "../utility/use-mutate-test-session-query-cache";
import { toast } from "sonner";
import resumeTestManipulator from "@/models/test-model/test-model-manipulators/resume-test";

const useResumeTest = () => {
  const mutateTestSession = useMutateTestSessionQueryCache()();
  return trpc.test.resumeTest.useMutation({
    onMutate: ({ testId }) => {
      const rollback = mutateTestSession((state) => {
        const lastSelectedSlot = state.slots.find(
          (s) => s.order === state.selectedSlot,
        )!;
        const manipulationResult = resumeTestManipulator({
          test: state,
          lastSelectedSlot: {
            submitted: lastSelectedSlot.isSubmitted,
            submittedAt: lastSelectedSlot.submittedAt,
            lastSelected: lastSelectedSlot.lastSelectedAt,
          },
        });
        state.suspended = manipulationResult.suspended;
        state.suspendedAt = manipulationResult.suspendedAt;
        state.timeElapsed = manipulationResult.timeElapsed;
        state.endsAt = manipulationResult.endsAt;
        state.lastUpdatedTimeAt = manipulationResult.lastUpdatedTimeAt;
      });
      return { rollback };
    },
    onError: (err, vars, ctx) => {
      toast.error(
        `Could not resume test, an error occurred..., ${err.message}`,
      );
      ctx?.rollback();
    },
  });
};
export default useResumeTest;
