import useQuestionBankQuestionNotes from "@/hooks/question-note-hooks/use-question-bank-question-notes";
import { useParams } from "@tanstack/react-router";
import {
  useNotesPageCurrentPage,
  useNotesPageNotesPerPage,
  useNotesPageSearch,
  useNotesPageSortBy,
} from "../-store";

const useNotesPageNotes = () => {
  const { qbankEndpoint } = useParams({
    from: "/qbanks/$qbankEndpoint/dashboard/_dashboard/notes",
  });
  const [search] = useNotesPageSearch();
  const [page] = useNotesPageCurrentPage();
  const [notesPerPage] = useNotesPageNotesPerPage();
  const [orderBy] = useNotesPageSortBy();
  return useQuestionBankQuestionNotes({
    questionBankEndpoint: qbankEndpoint,
    search,
    notesPerPage,
    page,
    orderBy,
  });
};

export default useNotesPageNotes;
