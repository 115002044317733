import { LabeledSelect } from "@/components/LabeledSelect";
import { SelectItem } from "@/components/SelectItem";
import { useNotesPageSortBy } from "../-store";

const NotesPageSortBySelect = () => {
  const [sortBy, setSortBy] = useNotesPageSortBy();
  const formatValue = () => {
    switch (sortBy) {
      case "topic":
        return "Topic";
      case "subject":
        return "Subject";
      case "system":
        return "System";
      case "questionId":
        return "Question ID";
    }
  };
  return (
    <LabeledSelect
      value={formatValue()}
      id="sort-notes-by-select"
      hideItemsOnClick
      label="Sort By:"
      selectClassName="min-w-[8rem]"
      valueClassName="text-gray-500"
      labelClassName="text-xs md:text-sm lg:text-base text-gray-400"
      containerClassName="z-[900]"
    >
      <SelectItem>
        <button onClick={() => setSortBy("questionId")}>Question Id</button>
      </SelectItem>
      <SelectItem>
        <button onClick={() => setSortBy("subject")}>Subject</button>
      </SelectItem>
      <SelectItem>
        <button onClick={() => setSortBy("system")}>System</button>
      </SelectItem>
      <SelectItem>
        <button onClick={() => setSortBy("topic")}>Topic</button>
      </SelectItem>
    </LabeledSelect>
  );
};

export default NotesPageSortBySelect;
