import { Button } from "@/components/Button";
import Dialog from "@/components/Dialog";
import {
  useConfirmDeleteDialog,
  useNotes,
  usePages,
  useSelectedNote,
} from "@/hooks/notebook";
import { Note } from "@/models";

export const ConfirmDeleteDialog = () => {
  const { isVisible, setIsVisible, onConfirm, onCancel, target } =
    useConfirmDeleteDialog();
  const item = (target as Note)?.pageId ? "note" : "page";
  const childItems = item === "note" ? "sub-note(s)" : "note(s)";
  const notes = useNotes();
  if (!target) return <></>;
  const children =
    item === "note"
      ? notes.filter((n) => n.parentId === target.id && !n.isDeleted)
      : notes.filter((n) => n.pageId === target.id && !n.isDeleted);

  const hasNoChildren = children.length === 0;
  const description = hasNoChildren ? (
    <>This {item} is not empty.</>
  ) : (
    <>
      This {item} has{" "}
      <span className="text-red-500">
        {children.length} {childItems}
      </span>{" "}
      within it, proceeding to delete it will delete all the {childItems}{" "}
      within.
    </>
  );

  return (
    <Dialog
      onOpenChange={setIsVisible}
      open={isVisible}
      title="Are you sure you want to continue?"
      description={description}
    >
      <span className="text-sm text-lime-600 font-semibold">
        Don't worry you can restore it at any time.
      </span>
      <div className="flex flex-row justify-end items-center w-full mt-3">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button error onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </div>
      </div>
    </Dialog>
  );
};
