import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { trpc } from "@/utils/trpc";
import { useParams } from "@tanstack/react-router";

export const WelcomeButton = () => {
  const { qbankEndpoint } = useParams({ strict: false });
  const utils = trpc.useUtils();
  return (
    <Button
      onMouseEnter={() => {
        utils.statistics.getQuestionBankOverallStatistics.prefetch({
          questionBankEndpoint: qbankEndpoint ?? "",
        });
      }}
      href="welcome"
    >
      <FontAwesomeIcon icon={faHome} />
      <p>Welcome</p>
    </Button>
  );
};
