import { trpc } from "@/utils/trpc";
import useMutateQueryCache from "./use-mutate-query-cache";
import {
  GetTestSessionInput,
  GetTestSessionOutput,
} from "@/api/src/server/routers";

const useMutateTestSessionQueryCache = () => {
  return useMutateQueryCache<GetTestSessionInput, GetTestSessionOutput>(
    trpc.test.getTestSession,
    "query",
  );
};

export default useMutateTestSessionQueryCache;
