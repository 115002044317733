import Text from "@/components/ui/text";
import useSearchQuestionbankLibrary from "@/hooks/library/use-search-library";
import { cn } from "@/lib/utils";
import {
  useMedicalLibraryLibrarySearch,
  useMedicalLibraryRouter,
  useMedicalLibrarySearch,
} from "@/ncomponents/medical-library/store";
import { useParams } from "@tanstack/react-router";
import { Interweave } from "interweave";
import librarySearchMatcher from "../matchers/library-search-matcher";
import { Paginator } from "@/components/Paginator";
import PulsatingDots from "@/ncomponents/loaders/pulsating-dots";

const SearchResult = ({ inBookmarks = false }: { inBookmarks?: boolean }) => {
  const { qbankEndpoint } = useParams({ strict: false });
  const router = useMedicalLibraryRouter();
  const [librarySearch, setLibrarySearch] = useMedicalLibraryLibrarySearch();
  const { page, query, itemsPerPage } = librarySearch;
  const [, setSearch] = useMedicalLibrarySearch();
  const {
    data: result,
    isPlaceholderData,
    isLoading,
  } = useSearchQuestionbankLibrary({
    page,
    search: query,
    itemsPerPage,
    questionBankEndpoint: qbankEndpoint ?? "",
    inBookmarks,
  });

  if (!query) return;

  if (!result) {
    if (isLoading)
      return (
        <div className="flex flex-col justify-center items-center w-full h-full">
          <PulsatingDots dotClassName="bg-primary-500 dark:bg-neutral-300 warm:bg-brown-700" />
        </div>
      );
    else return;
  }
  return (
    <div
      className={cn(
        "flex flex-col justify-start items-start w-full",
        isPlaceholderData && "opacity-50",
      )}
    >
      <div className="w-full flex flex-row justify-start items-center border-b border-b-gray-300 dark:border-b-neutral-700 warm:border-b-brown-400 pb-2">
        <Text>
          {result.totalItems} results found for "{query}"
        </Text>
      </div>
      <div className="flex flex-col justify-start items-start w-full">
        {result.items.map((i) => {
          return (
            <button
              key={i.id}
              className="flex flex-col justify-start items-start w-full gap-y-2 border-b border-b-gray-300 dark:border-b-neutral-700 warm:border-b-brown-300 py-5 text-start hover:bg-gray-100 dark:hover:bg-neutral-900 warm:hover:bg-brown-100 transition-colors"
              onClick={() => {
                router.push(i.id);
                setSearch({
                  query,
                  isEnabled: true,
                  targetMatch: 0,
                  isMatchCaseSensitive: false,
                  totalMatches: 1,
                });
              }}
            >
              <Interweave
                className="capitalize text-lg font-medium warm:text-brown-800"
                content={i.title}
              />
              <Interweave
                content={i.content}
                matchers={[librarySearchMatcher(query)]}
                className="warm:text-brown-700"
              />
            </button>
          );
        })}
      </div>
      {result.items.length > 0 && (
        <div className="flex flex-row justify-end items-center w-full py-2">
          <div className="flex flex-row justify-start items-center gap-x-4">
            <Paginator
              itemsPerPage={itemsPerPage}
              currentPage={page}
              numberOfItems={result.totalItems}
              itemSingularName="result"
              onCurrentPageChange={(page) =>
                setLibrarySearch({ ...librarySearch, page })
              }
              onItemsPerPageChange={(itemsPerPage) =>
                setLibrarySearch({ ...librarySearch, itemsPerPage })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResult;
