import { PropsWithClassName } from "@/types";
import { CSSProperties, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface LayoutProps extends PropsWithChildren, PropsWithClassName {
  style?: CSSProperties;
}

export const Layout = ({ children, className, style }: LayoutProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-col justify-start items-start w-[95%] max-w-md",
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};
