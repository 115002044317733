import { GetTestSessionInput } from "@/api/src/server/routers";
import { trpc } from "@/utils/trpc";

const useTestSession = (input: GetTestSessionInput) => {
  return trpc.test.getTestSession.useQuery(input, {
    enabled: !!input.testId,
    staleTime: Infinity,
  });
};

export default useTestSession;
