import useMutateOverallStatisticsPageQueryCache from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.performance/overall/-hooks/use-mutate-overallstatistics-page-query-cache";
import useMutatePreviousTestsPageQueryCache from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.previous-tests/-hooks/use-mutate-previoustests-page-query-cache";
import { useCreateTestPageStore } from "@/utils/stores";
import { trpc } from "@/utils/trpc";
import { toast } from "sonner";

const useCreateTest = () => {
  const utils = trpc.useUtils();
  const mutatePreviousTests = useMutatePreviousTestsPageQueryCache();
  const mutateOverallStatistics = useMutateOverallStatisticsPageQueryCache();

  return trpc.test.createTest.useMutation({
    onMutate: () => {
      const rollbackOverallStatistics = mutateOverallStatistics((state) => {
        state.tests.total += 1;
      });
      return {
        rollback: () => {
          rollbackOverallStatistics((state) => {
            state.tests.total -= 1;
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(`Could not create test.., an error ocurred ${error.message}`);
      context?.rollback();
    },
    onSuccess: (newTest) => {
      utils.statistics.getQuestionBankOverallStatistics.invalidate();
      useCreateTestPageStore.getState().reset();
      mutatePreviousTests((state) => {
        state.tests.push({
          ...newTest,
          startedAt: new Date(newTest.startedAt),
          deletedAt: null,
        });
      });
    },
  });
};
export default useCreateTest;
