import { useAppStore } from "@/store";
import { PageAction } from "../../types";
import { recoverPage as recoverPageMutation } from "./mutations";
import { toast } from "sonner";

export const recoverPage: PageAction =
  ([set]) =>
  (id: string) => {
    const appState = useAppStore.getState();
    const { pages: oldPages } = appState;
    const { notes: oldNotes } = appState;

    set((state) => {
      state.pages.map((page) => {
        if (page.id === id) {
          page.isDeleted = false;
        }
        state.notes.forEach((note) => {
          if (note.pageId === id) {
            note.isDeleted = false;
          }
        });
        return page;
      });
    });
    recoverPageMutation({ pageId: id })
      .catch((error) => {
        toast.error(
          `Could not recover page, an error occurred: ${error.message}`,
        );
        set((state) => {
          state.pages = oldPages;
          state.notes = oldNotes;
        });
      })
      .then((recoveredPage) =>
        toast.success(`Successfully recovered page: ${recoveredPage!.title}`),
      );
  };
