import { DecodedUserToken } from "@/api/types";
import { jwt } from "@/lib/client";
import { AuthSliceCreator } from ".";
import { queryClient, trpcProxyClient } from "@/utils/trpc";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import handleTRPCMutationError from "@/utils/trpc/handle-trpc-mutation-error";
import handleTRPCMutationSuccess from "@/utils/trpc/handle-trpc-mutation-success";

const createAuthSlice: AuthSliceCreator = (set, get) => {
  return {
    currentUser: undefined,
    signIn: (userToken: string) =>
      set((state) => {
        const user: DecodedUserToken = jwt.decode(userToken);
        const currentUser = { ...user, token: userToken };
        state.currentUser = currentUser;
      }),
    signOut: () => {
      set((state) => {
        state.currentUser = undefined;
      });
      const state = get();
      queryClient.clear();
      state.resetApplication();
    },
    sync: (userToken: string) =>
      set((state) => {
        const user: DecodedUserToken = jwt.decode(userToken);
        state.currentUser = { ...user, token: userToken };
      }),

    reset: (password: string) => {
      const oldState = get();
      const oldUser = oldState.currentUser;
      if (!oldUser) return;
      set((state) => {
        if (!state.currentUser) return;
        state.currentUser.lastResetAt = new Date();
      });
      trpcProxyClient.auth.reset
        .mutate(password)
        .then(
          handleTRPCMutationSuccess(
            { mutationPastPrinciple: "reset account" },
            (newUserToken) => {
              set((state) => {
                const newUser: DecodedUserToken = jwt.decode(newUserToken);
                state.currentUser = { ...newUser, token: newUserToken };
              });
            },
          ),
        )
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "reset account" },
            () => {
              set(() => {
                return oldState;
              });
            },
          ),
        );
    },
    resetQbank: ({ password, qbankId }) => {
      const initialState = get();
      const oldUser = initialState.currentUser;
      if (!oldUser) return;
      trpcProxyClient.auth.resetQbank
        .mutate({ password, qbankId })
        .then(
          handleTRPCMutationSuccess(
            { mutationPastPrinciple: "reset Qbank" },
            (newUserToken) => {
              set((state) => {
                const newUser: DecodedUserToken = jwt.decode(newUserToken);
                state.currentUser = { ...newUser, token: newUserToken };
              });
            },
          ),
        )
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "Reset Qbank" },
            () => {
              set(() => {
                return initialState;
              });
            },
          ),
        );
    },
  };
};

export default createAuthSlice;
