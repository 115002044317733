import { useAppStore } from "@/store";
import { PageAction } from "../../types";
import { deletePage } from "./mutations";
import { toast } from "sonner";

export const removePage: PageAction =
  ([set]) =>
  (id: string) => {
    const appState = useAppStore.getState();
    const { pages: oldPages } = appState;
    set((state) => {
      state.pages.forEach((page) => {
        if (page.id === id) {
          page.isDeleted = true;
        }
      });
    });
    deletePage({ pageId: id })
      .catch((error) => {
        toast.error(
          `Could not delete page, an error occurred: ${error.message}`,
        );
        set((state) => {
          state.pages = oldPages;
        });
      })
      .then((deletedPage) =>
        toast.success(`Successfully deleted page: ${deletedPage!.title}`),
      );
  };
