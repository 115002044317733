import { trpc } from "@/utils/trpc";
import useMutateQueryCache from "./use-mutate-query-cache";
import {
  GetHighlightMarkersInput,
  GetHighlightMarkersOutput,
} from "@/api/src/server/routers/highlightmarker-router";

const useMutateHighlightMarkersQueryCache = () => {
  return useMutateQueryCache<
    GetHighlightMarkersInput,
    GetHighlightMarkersOutput
  >(trpc.highlightMarker.getHighlightMarkers, "query");
};

export default useMutateHighlightMarkersQueryCache;
