import { DashboardFooter } from "@/components/DashboardFooter";
import { DashboardHeader } from "@/components/DashboardHeader";
import { DashboardSidebar } from "@/components/pages/dashboard";
import { LoadingPage } from "@/components/LoadingPage";
import { CoursologyErrorPage } from "@/components/CoursologyErrorPage";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { PropsWithChildren, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { DashboardScrollArea } from "./-components";
import { DashboardResetAccountDialog } from "@/components/DashboardResetAccountDialog";
import { useDimensions } from "@/utils/hooks";
import { remToPx } from "@/utils/common/remToPx";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import DashboardContextProvider from "./-components/dashboard-context-provider";
import useCurrentUser from "@/hooks/auth-hooks/use-current-user";
import {
  createFileRoute,
  Outlet,
  useRouterState,
} from "@tanstack/react-router";
import { trpc } from "@/utils/trpc";
import { OnFreeTrial } from "@/ncomponents/utilities/on-free-trial";
import CollapsibleBanner from "@/ncomponents/advertisement/collapsible-banner";
import { cn } from "@/lib/utils";
import ScrollArea from "@/components/ui/scroll-area";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard",
)({
  component: QBankDashboardLayout,
});

function QBankDashboardLayout() {
  const utils = trpc.useUtils();
  const params = Route.useParams();
  const mainLayoutRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(mainLayoutRef);
  const breakPoint = useBreakPoint();
  const user = useCurrentUser();
  const questionBankQuery = useQuestionBank({
    questionBankEndpoint: params.qbankEndpoint,
  });
  const {
    data: qBank,
    isLoading: isQBankLoading,
    error: qBankError,
  } = questionBankQuery;

  const pathname = useRouterState().location.pathname;

  const pageLayoutMapper: { [k: string]: string } = {
    notebook: "p-0",
    "medical-library": "p-0",
  };
  trpc.statistics.getQuestionBankOverallStatistics.usePrefetchQuery({
    questionBankEndpoint: params.qbankEndpoint,
  });

  const { showSidebar, hideSidebar, setViewportDimensions } =
    useDashboardLayoutStore((state) => ({
      showSidebar: state.showSidebar,
      hideSidebar: state.hideSidebar,
      setViewportDimensions: state.setViewPortDimensions,
    }));

  useEffect(() => {
    const height = dimensions.height - (remToPx(3.5) + remToPx(2.1));
    const width = dimensions.width;
    setViewportDimensions({ height, width });
  }, [dimensions]);

  //Always show sidebar
  useEffect(() => {
    showSidebar();
  }, [breakPoint, hideSidebar, showSidebar]);

  if (isQBankLoading) {
    return <LoadingPage />;
  }

  if (qBankError && qBankError.data?.code === "NOT_FOUND") {
    return (
      <CoursologyErrorPage>
        <h1 className="font-bold text-2xl">Error 404!</h1>
        <p className="text-gray-900 font-semibold text-xl">
          This QBank does not exist on Coursology
        </p>
      </CoursologyErrorPage>
    );
  }

  if (qBankError && qBankError.data?.code === "FORBIDDEN") {
    return (
      <CoursologyErrorPage>
        <h1 className="font-bold text-2xl">Not Allowed</h1>
        <p className="text-gray-900 font-semibold text-xl">
          {`This QBank is not included in your current subscription (${user?.currentSubscription.name})`}
        </p>
      </CoursologyErrorPage>
    );
  }

  return (
    <DashboardContextProvider
      value={{
        questionBank: questionBankQuery,
      }}
    >
      <div
        className={twMerge(
          "flex flex-row justify-start items-start h-full min-h-full w-full bg-gray-100 warm:bg-brown-50 dark:bg-neutral-950 transition-colors",
        )}
      >
        <DashboardResetAccountDialog />
        <DashboardSidebar />
        <div
          ref={mainLayoutRef}
          className="flex flex-col justify-start items-center flex-grow h-full min-h-full"
        >
          <DashboardHeader />
          <OnFreeTrial>
            <CollapsibleBanner>
              <p className="relative">
                For a limited time only get our lifetime subscription for only
                75$!
                <a
                  href="https://t.me/UW_Coursology"
                  className="ml-3 absolute left-full w-max flex-none rounded-md warm:bg-brown-900 dark:bg-neutral-600 bg-primary-800 px-3 py-1 text-xs text-white shadow-sm hover:bg-gray-900/80"
                  target="_empty"
                >
                  Contact Us
                </a>
              </p>
            </CollapsibleBanner>
          </OnFreeTrial>
          <ScrollArea.Root className="w-full flex-grow">
            <ScrollArea.Viewport
              className={cn(
                //ALL LAYOUT ISSUES GET RESOLVED WHEN ADDING flex-row
                //................WHAT????????
                "w-full flex-grow flex flex-row p-5 max-w-[100vw]",
                pageLayoutMapper[pathname.split("/").at(-1) ?? ""],
              )}
            >
              <Outlet />
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar orientation="vertical">
              <ScrollArea.Thumb />
            </ScrollArea.Scrollbar>
            <ScrollArea.Corner />
          </ScrollArea.Root>
          <DashboardFooter />
        </div>
      </div>
    </DashboardContextProvider>
  );
}
