import { trpc } from "@/utils/trpc";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import useMutateTestSessionQueryCache from "../utility/use-mutate-test-session-query-cache";
import useMutatePreviousTestsQueryCache from "../utility/use-mutate-previous-tests-query-cache";
import { percent } from "@/utils";
import { toast } from "sonner";
import { usePreviousTestsPageStore } from "@/utils/stores";

const useSubmitTest = () => {
  const utils = trpc.useUtils();
  const mutateTestSlotCache = useMutateTestSlotQueryCache()();
  const mutateTestSessionCache = useMutateTestSessionQueryCache()();
  const mutatePreviousTestsCache = useMutatePreviousTestsQueryCache()();

  let correctSlots = 0;
  return trpc.test.submitTest.useMutation({
    onMutate: ({ testId }) => {
      const rollbackTestSlots = mutateTestSlotCache((state) => {
        if (state.testId === testId) {
          state.submitted = true;
        }
      });
      const rollbackTestSessions = mutateTestSessionCache((state) => {
        if (state.id === testId) {
          state.submitted = true;
          state.submittedAt = new Date();
          state.slots.forEach((s) => {
            s.isSubmitted = true;
            if (s.chosen?.correct) {
              correctSlots++;
            }
          });
        }
      });
      const rollbackPreviousTests = mutatePreviousTestsCache((state) => {
        const targetTest = state.tests.find((t) => t.id === testId);
        if (targetTest) {
          targetTest.submitted = true;
          targetTest.score = percent(correctSlots, targetTest.questions.length);
        }
      });
      return {
        rollback: () => {
          rollbackTestSlots((state) => {
            if (state.testId === testId) {
              state.submitted = false;
            }
          });
          rollbackTestSessions((state, oldTestSession) => {
            if (state.id === testId) {
              state.slots = oldTestSession.slots;
              state.submitted = false;
              state.submittedAt = null;
            }
          });
          rollbackPreviousTests((state) => {
            const targetTest = state.tests.find((t) => t.id === testId);
            if (targetTest) {
              targetTest.submitted = false;
              targetTest.score = null;
            }
          });
        },
      };
    },
    onError: (err, variables, ctx) => {
      toast.error(
        `Could not submit test, an error occurred..., ${err.message}`,
      );
      ctx?.rollback();
    },
    onSettled: (data, variables, context) => {
      const previousTestsPageState = usePreviousTestsPageStore.getState();
      utils.test.getPreviousTests.invalidate({
        page: previousTestsPageState.currentPage,
        testsPerPage: previousTestsPageState.testsPerPage,
        questionBankId: data?.questionBankId ?? "",
      });
    },
  });
};
export default useSubmitTest;
