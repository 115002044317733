import { useAppStore } from "@/store";
import { NoteAction } from "../../types";
import { recoverNote as recoverNoteMutation } from "./mutations";
import { toast } from "sonner";

export const recoverNote: NoteAction =
  ([set]) =>
  (id: string) => {
    const appState = useAppStore.getState();
    const { notes: oldNotes } = appState;
    const children = oldNotes.filter((n) => n.parentId === id);
    const hasChildren = !!children.length;
    set((state) => {
      state.notes.forEach((n) => {
        const isTargetNote = n.id === id;
        const isChildOfTargetNote = n.parentId === id;
        if (isTargetNote || isChildOfTargetNote) {
          n.isDeleted = false;
        }
      });
    });
    recoverNoteMutation(id)
      .catch((error) => {
        toast.error(
          `Could not recover note, an error occurred: ${error.message}`,
        );
        set((state) => {
          state.notes = oldNotes;
        });
      })
      .then((recoveredNote) => {
        toast.success(
          `Successfully recovered note: "${recoveredNote!.title}" ${
            hasChildren ? `along with ${children.length} sub-note(s).` : ""
          }`,
        );
      });
  };
