import useOnClickOutside from "@/hooks/use-on-click-outside";
import { Setter } from "@coursology/types";
import { AnimatePresence, Point, motion } from "framer-motion";
import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { fadeAnimation } from "@/assets/framer";
import { useMousePosition } from "@/utils/hooks";
import { Highlight, HighlightMarker } from "@/models";
import NbmeContextMenuChangeHighlightMarker from "./change-highlight-color";
import NbmeContextMenuCopyToClipboard from "./copy-to-clipboard";
import NbmeContextMenuDelete from "./delete-highlight-button";
import { CreateHighlightInput } from "@/api/src/server/routers/highlight-router";

type ContextMenuAction =
  | {
      type: "change-highlight-marker";
      currentHighlightMarkerId: HighlightMarker["id"] | null;
      onHighlightMarkerChange?: (
        newHighlightMarkerId: HighlightMarker["id"],
      ) => void;
      highlightId: Highlight["id"];
    }
  | {
      type: "create-highlight";
      newHighlight: Omit<CreateHighlightInput["highlight"], "markerId">;
      onNewHighlight?: (
        newHighlight: CreateHighlightInput["highlight"],
      ) => void;
    }
  | {
      type: "copy-to-clipboard";
      textToCopy: string;
    }
  | {
      type: "delete-highlight";
      highlightId: Highlight["id"];
    };

type ContextMenuState = {
  visible: boolean;
  setVisible: Setter<boolean>;
  trigger: (
    actions: ContextMenuState["actions"],
    options?: { position?: Point },
  ) => void;
  position: Point;
  setPosition: Setter<Point>;
  actions: ContextMenuAction[];
  setActions: Setter<ContextMenuState["actions"]>;
};

export const useNbmeContextMenuState = create<ContextMenuState>()(
  immer((set, get) => {
    return {
      visible: false,
      setVisible: (v) =>
        set((state) => {
          state.visible = v;
        }),
      position: { x: 0, y: 0 },
      setPosition: (v) =>
        set((state) => {
          state.position = v;
        }),
      trigger: (actions, options) => {
        const state = get();
        const wasVisible = state.visible;
        if (!wasVisible) state.setVisible(true);
        state.setActions(actions);
        if (options?.position) {
          if (wasVisible) return;
          state.setPosition(options.position);
        }
      },
      actions: [],
      setActions: (newActions) =>
        set((state) => {
          state.actions = newActions;
        }),
    };
  }),
);

export const useTriggerNbmeContextMenu = () => {
  return useNbmeContextMenuState((state) => state.trigger);
};

export const useNbmeContextMenuSetPosition = () =>
  useNbmeContextMenuState((state) => state.setPosition);

const useNbmeContextMenuPosition = () =>
  useNbmeContextMenuState((state) => state.position);

export const useNbmeContextMenuPositionTracker = () => {
  const visible = useNbmeContextMenuState((state) => state.visible);
  const setPosition = useNbmeContextMenuSetPosition();
  const mousePos = useMousePosition();
  useEffect(() => {
    if (visible) return;
    setPosition(mousePos);
  }, [mousePos, visible]);
};
export const useNbmeContextMenuActions = () =>
  useNbmeContextMenuState((state) => state.actions);

export const NbmeContextMenu = () => {
  const visible = useNbmeContextMenuState((state) => state.visible);
  const setVisible = useNbmeContextMenuState((state) => state.setVisible);
  const { x, y } = useNbmeContextMenuPosition();
  const ref = useOnClickOutside(() => {
    setVisible(false);
  });
  const OFFSET = 10;
  useNbmeContextMenuPositionTracker();
  const actions = useNbmeContextMenuActions();

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          {...fadeAnimation}
          ref={ref as RefObject<HTMLDivElement>}
          className="dark:bg-neutral-800 bg-nbme-primary-600 warm:bg-brown-600 font-semibold px-2 py-2 md:py-1 rounded-primary flex flex-col md:flex-row justify-center items-center gap-3.5 z-[9999]"
          style={{ position: "fixed", left: x + OFFSET, top: y + OFFSET }}
        >
          {actions.map((action, index) => {
            switch (action.type) {
              case "change-highlight-marker":
                return (
                  <NbmeContextMenuChangeHighlightMarker
                    key={index}
                    onNewMarkerSelected={action.onHighlightMarkerChange}
                    currentMarkerId={action.currentHighlightMarkerId}
                    highlightId={action.highlightId}
                  />
                );
              case "create-highlight":
                return (
                  <NbmeContextMenuChangeHighlightMarker
                    key={index}
                    newHighlight={action.newHighlight}
                    onNewHighlight={action.onNewHighlight}
                  />
                );
              case "copy-to-clipboard":
                return (
                  <NbmeContextMenuCopyToClipboard
                    key={index}
                    onTextCopy={() => setVisible(false)}
                    textToCopy={action.textToCopy}
                  />
                );
              case "delete-highlight":
                return (
                  <NbmeContextMenuDelete
                    key={index}
                    onHighlightDelete={() => setVisible(false)}
                    highlightId={action.highlightId}
                  />
                );
            }
          })}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
