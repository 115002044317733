import { Tabs } from "@/components/Tabs";
import { usePerformanceReportsPageTabs } from "../-hooks";
import { titlize } from "@/utils/common/titlize";
import { trpc } from "@/utils/trpc";
import usePerformanceReportsPageStore from "../-store";
import { useEffect } from "react";
import { useParams } from "@tanstack/react-router";

export const PerformanceReportsPageTabs = ({
  questionBankId,
}: {
  questionBankId: string;
}) => {
  const { qbankEndpoint } = useParams({ strict: false });
  const utils = trpc.useUtils();
  const { reportsPerPage, search } = usePerformanceReportsPageStore(
    (state) => ({
      reportsPerPage: state.reportsPerPage,
      search: state.query,
    }),
  );

  const { tabs, setCurrentTab, currentTab } = usePerformanceReportsPageTabs();

  useEffect(() => {
    utils.statistics.getQuestionBankPerformanceReports.prefetch({
      questionBankEndpoint: qbankEndpoint ?? "",
      for: "topics",
      page: 1,
      reportsPerPage,
      search,
    });
    utils.statistics.getQuestionBankPerformanceReports.prefetch({
      questionBankEndpoint: qbankEndpoint ?? "",
      for: "subjects",
      page: 1,
      reportsPerPage,
      search,
    });
    utils.statistics.getQuestionBankPerformanceReports.prefetch({
      questionBankEndpoint: qbankEndpoint ?? "",
      for: "systems",
      page: 1,
      reportsPerPage,
      search,
    });
  }, [questionBankId]);

  return (
    <Tabs
      variant="dashboard"
      tabs={tabs.map((t) => titlize(t))}
      currentTab={titlize(currentTab)}
      // 'as' here to put the typescript compiler to sleep
      onCurrentTabChange={(t) =>
        setCurrentTab(t.toLowerCase() as "subjects" | "systems" | "topics")
      }
    />
  );
};
