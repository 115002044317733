import useCreateTest from "@/hooks/test-hooks/use-create-test";
import { useCreateTestPageStore } from "@/utils/stores";
import _ from "underscore";
import { useParams } from "@tanstack/react-router";
import toast from "@/lib/client/toast";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { useCallback, useMemo } from "react";
import { MutateOptions } from "@tanstack/react-query";

const useCreateTestPageCreateTest = () => {
  const { qbankEndpoint } = useParams({
    from: "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test",
  });
  const {
    selectedSubjects,
    selectedTopics,
    questionCount,
    pool,
    mode,
    qmode,
    minutesPerQuestion,
    questionIds,
    name,
    blueprint,
  } = useCreateTestPageStore((state) => ({
    selectedTopics: state.selectedTopics,
    questionIds: state.questionIds,
    selectedSubjects: state.selectedSubjects,
    name: state.name,
    blueprint: state.selectedBlueprint,
    questionCount: state.questionCount,
    minutesPerQuestion: state.minutesPerQuestion,
    qmode: state.qmode,
    mode: [
      ...(state.tutor ? ["tutor"] : []),
      ...(state.timed ? ["timed"] : []),
    ] as ("tutor" | "timed")[],
    pool: [
      ...(state.unused ? ["unused"] : []),
      ...(state.correct ? ["correct"] : []),
      ...(state.incorrect ? ["incorrect"] : []),
      ...(state.omitted ? ["omitted"] : []),
      ...(state.marked ? ["marked"] : []),
    ] as ("unused" | "correct" | "incorrect" | "marked" | "unused")[],
  }));
  const maximumQuestionCountPerTest = 40;
  const { data: questionBank } = useQuestionBank({
    questionBankEndpoint: qbankEndpoint,
  });

  const { mutate: createTest } = useCreateTest();

  return useMemo(() => {
    return {
      mutate: (opts?: Parameters<typeof createTest>[1]) => {
        if (!questionBank) return;
        const standardOptions = {
          type: qmode as "standard",
          questionCount: Number(questionCount),
          subjectIds: selectedSubjects,
          topicIds: selectedTopics,
          pool: pool,
        };
        const customOptions = {
          type: qmode as "custom",
          questionIds: questionIds,
        };

        const orderedOptions = {
          type: qmode as "ordered",
          orderedTestBlueprintOrder: blueprint?.order ?? 0,
        };

        createTest(
          {
            test: {
              name,
              questionBankId: questionBank.id,
              mode: mode,
              minutesPerQuestion: minutesPerQuestion,
              parentId: null,
            },
            options:
              qmode === "standard"
                ? standardOptions
                : qmode === "custom"
                  ? customOptions
                  : qmode === "ordered"
                    ? orderedOptions
                    : standardOptions,
          },
          opts,
        );
      },
    };
  }, [
    questionBank,
    name,
    createTest,
    selectedSubjects,
    selectedTopics,
    pool,
    mode,
    minutesPerQuestion,
    questionCount,
    blueprint,
    questionIds,
    qmode,
  ]);
};

export default useCreateTestPageCreateTest;
