import { OrderedTestBlueprint } from "@/models";
import { Subject, System, Topic } from "@/api/types";
import { Setter } from "@/types";
import { create } from "zustand";

type CreateTestPageState = {
  tutor: boolean;
  timed: boolean;
  unused: boolean;
  incorrect: boolean;
  marked: boolean;
  omitted: boolean;
  correct: boolean;
  qmode: "standard" | "custom" | "ordered";
  questionIds: number[];
  questionCount: string;
  selectedSubjects: Subject["name"][];
  selectedTopics: Topic["name"][];
  toggledSections: string[];
  selectedBlueprint?: OrderedTestBlueprint;
  name: string;
  minutesPerQuestion: number;
};

type CreateTestPageActions = {
  setSelectedBlueprint: Setter<CreateTestPageState["selectedBlueprint"]>;
  setQmode: (mode: "standard" | "custom" | "ordered") => void;
  setQuestionIds: (ids: number[]) => void;
  setQuestionCount: (count: string) => void;
  toggleSection: (section: string) => void;
  toggleField: (field: keyof CreateTestPageState, checked: boolean) => void;
  setSelectedSubjects: Setter<CreateTestPageState["selectedSubjects"]>;
  setSelectedTopics: Setter<CreateTestPageState["selectedTopics"]>;
  setMinutesPerQuestion: Setter<CreateTestPageState["minutesPerQuestion"]>;
  setName: Setter<CreateTestPageState["name"]>;
  toggleSelection: (
    array: "selectedSubjects" | "selectedTopics",
    checked: boolean,
    item: string,
  ) => void;
  reset: () => void;
};

type CreateTestPageStore = CreateTestPageState & CreateTestPageActions;

const initialState: CreateTestPageState = {
  tutor: true,
  timed: false,
  unused: true,
  incorrect: false,
  correct: false,
  marked: false,
  questionCount: "0",
  omitted: false,
  qmode: "standard",
  name: "",
  minutesPerQuestion: 1.5,
  selectedSubjects: [],
  selectedTopics: [],
  questionIds: [],
  toggledSections: [
    "mode",
    "subjects",
    "systems",
    "qnum",
    "qmode",
    "ordered-tests",
    "name",
  ],
};

export const useCreateTestPageStore = create<CreateTestPageStore>()((set) => ({
  ...initialState,
  setQuestionCount: (count) => set(() => ({ questionCount: count })),
  setSelectedSubjects: (subjects) =>
    set(() => ({ selectedSubjects: subjects })),
  setSelectedTopics: (topics) => set(() => ({ selectedTopics: topics })),
  setName: (name) => set(() => ({ name })),
  toggleSection: (section) =>
    set((state) => {
      if (!state.toggledSections.includes(section)) {
        const sectionsCopy = [...state.toggledSections];
        sectionsCopy.push(section);
        return { toggledSections: sectionsCopy };
      } else {
        return {
          toggledSections: state.toggledSections.filter((s) => s !== section),
        };
      }
    }),
  toggleField: (field, checked) => set(() => ({ [field]: checked })),
  setQmode: (mode) => set(() => ({ qmode: mode })),
  setMinutesPerQuestion: (minutesPerQuestion) =>
    set(() => ({ minutesPerQuestion })),
  setQuestionIds: (ids) => set(() => ({ questionIds: ids })),
  setSelectedBlueprint: (newSelectedBlueprint) =>
    set(() => ({ selectedBlueprint: newSelectedBlueprint })),
  toggleSelection: (array, checked, item) =>
    set((state) =>
      checked
        ? !state[array].find((i) => i === item)
          ? { [array]: [...state[array], item] }
          : {}
        : { [array]: state[array].filter((s) => s !== item) },
    ),
  reset: () => set((state) => ({ ...initialState, qmode: state.qmode })),
}));
