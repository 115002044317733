import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { trpc } from "@/utils/trpc";
import { useParams } from "@tanstack/react-router";
import { useCreateTestPageStore } from "@/utils/stores";

export const CreateTestButton = () => {
  const { qbankEndpoint } = useParams({ strict: false });
  const utils = trpc.useUtils();

  return (
    <Button
      href="create-test"
      onMouseEnter={() => {
        const createTestPageState = useCreateTestPageStore.getState();
        const { unused, incorrect, marked, correct, omitted } =
          createTestPageState;
        utils.statistics.getQuestionbankUsage.prefetch({
          questionBankEndpoint: qbankEndpoint ?? "",
          pool: [
            ...(unused ? ["unused"] : []),
            ...(incorrect ? ["incorrect"] : []),
            ...(marked ? ["marked"] : []),
            ...(correct ? ["correct"] : []),
            ...(omitted ? ["omitted"] : []),
          ] as ("unused" | "incorrect" | "marked" | "omitted" | "correct")[],
          subjects: createTestPageState.selectedSubjects,
        });
      }}
    >
      <FontAwesomeIcon icon={faPenToSquare} />
      <p>Create test</p>
    </Button>
  );
};
