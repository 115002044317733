import { trpc } from "@/utils/trpc";
import { toast } from "sonner";
import useMutateTestSessionQueryCache from "../utility/use-mutate-test-session-query-cache";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import useMutateNotesPageNotesQueryCache from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.notes/-notes-page-hooks/use-mutate-notes-page-notes-query-cache";
import { current } from "immer";

const useCreateQuestionNote = () => {
  const mutateTestSlotCache = useMutateTestSlotQueryCache()(undefined);
  const mutateTestSessionCache = useMutateTestSessionQueryCache()(undefined);
  const mutateQuestionNotesCache = useMutateNotesPageNotesQueryCache();

  return trpc.questionNote.createQuestionNote.useMutation({
    onMutate: ({ text, questionId }) => {
      let question:
        | {
            id: number;
            uWolrdId: number;
            subject: string;
            system: string;
            topic: string;
          }
        | undefined = undefined;
      const rollbackTestSlot = mutateTestSlotCache((state) => {
        if (state.question.id === questionId) {
          state.question.note = { text };

          //We use current to leak the object outside of draft scope
          const currentQuestion = current(state.question);
          question = {
            id: currentQuestion.id,
            uWolrdId: currentQuestion.uWolrdId,
            subject: currentQuestion.subject,
            system: currentQuestion.system,
            topic: currentQuestion.topic,
          };
        }
      });
      const rollbackTestSession = mutateTestSessionCache((state) => {
        const targetSlot = state.slots.find((s) => s.questionId === questionId);
        if (targetSlot) {
          targetSlot.hasNote = true;
        }
      });
      const rollbackQuestionNotes = mutateQuestionNotesCache((state, input) => {
        if (!question) return;
        const newNote = { text, question };
        if (!input?.search) {
          state.notes.push(newNote);
          return;
        }

        let shouldBeIncludedInPage = false;
        const searchAsNumber = Number(input?.search);
        const searchIsNumber = !isNaN(searchAsNumber);

        if (searchIsNumber) {
          if (newNote.question.uWolrdId === searchAsNumber)
            shouldBeIncludedInPage = true;
        }
        if (
          newNote.question.subject
            .toLowerCase()
            .includes(input?.search?.toLowerCase() ?? "")
        ) {
          shouldBeIncludedInPage = true;
        }
        if (
          newNote.question.system
            .toLowerCase()
            .includes(input?.search?.toLowerCase() ?? "")
        ) {
          shouldBeIncludedInPage = true;
        }

        if (
          newNote.question.topic
            .toLowerCase()
            .includes(input?.search?.toLowerCase() ?? "")
        ) {
          shouldBeIncludedInPage = true;
        }

        if (newNote.text.includes(input?.search?.toLowerCase() ?? "")) {
          shouldBeIncludedInPage = true;
        }

        if (shouldBeIncludedInPage) state.notes.push(newNote);
      });

      return {
        rollback: () => {
          rollbackTestSlot((state) => {
            if (state.question.id === questionId) state.question.note = null;
          });
          rollbackTestSession((state) => {
            const targetSlot = state.slots.find(
              (s) => s.questionId === questionId,
            );
            if (targetSlot) {
              targetSlot.hasNote = false;
            }
          });
          rollbackQuestionNotes((state) => {
            state.notes = state.notes.filter(
              (n) => n.question.id === questionId,
            );
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Failed to create question note.., an error ocurred: ${error.message}`,
      );
      context?.rollback?.();
    },
  });
};
export default useCreateQuestionNote;
