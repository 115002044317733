import { trpc } from "@/utils/trpc";
import useMutateQueryCache from "./use-mutate-query-cache";
import {
  QuestionBankOverallStatisticsInput,
  QuestionBankOverallStatisticsOutput,
} from "@/api/src/server/routers/statistics/types";

const useMutateQuestionBankOverallStatisticsQueryCache = () => {
  return useMutateQueryCache<
    QuestionBankOverallStatisticsInput,
    QuestionBankOverallStatisticsOutput
  >(trpc.statistics.getQuestionBankOverallStatistics, "query");
};

export default useMutateQuestionBankOverallStatisticsQueryCache;
