import {
  useNbmeExhibitStyle,
  useNbmeFontWeight,
  useNbmeLayoutStore,
} from "@/utils/stores/nbmeLayoutStore";
import { PropsWithClassName } from "@/types";
import { cva, VariantProps } from "class-variance-authority";
import { MouseEventHandler, PropsWithChildren } from "react";

export const nbmeHintTriggerVariants = cva("font-bold", {
  variants: {
    style: {
      button:
        "bg-nbme-primary-600 warm:bg-brown-700 dark:bg-nbme-primary-dark-600 px-2 py-1 text-white rounded-full hover:scale-110 transition-transform",
      link: "underline text-nbme-primary-500 warm:text-sky-500 warm:hover:text-sky-600 hover:text-nbme-primary-600 dark:text-sky-300 dark:hover:text-sky-200",
      plain:
        "font-normal text-nbme-primary-600 warm:text-sky-500 warm:hover:text-sky-600 hover:text-nbme-primary-600 dark:text-sky-300 dark:hover:text-sky-200",
    },
  },
  defaultVariants: { style: "button" },
});

interface NbmeHintTriggerProps
  extends PropsWithClassName,
    VariantProps<typeof nbmeHintTriggerVariants>,
    PropsWithChildren {
  hint: { text: string; id: string };
  onClick?: MouseEventHandler;
}

export const NbmeHintTrigger = ({
  hint,
  onClick,
  className,
  style: externalStyle,
  children,
}: NbmeHintTriggerProps) => {
  const [fontWeight] = useNbmeFontWeight();
  const [exhibitStyle] = useNbmeExhibitStyle();
  const toggleHint = useNbmeLayoutStore((state) => state.toggleHint);

  return (
    <button
      className={nbmeHintTriggerVariants({
        style: externalStyle || exhibitStyle,
        className,
      })}
      onClick={onClick ? onClick : () => handleClick()}
      style={{ fontWeight: fontWeight !== 400 ? fontWeight : undefined }}
    >
      {children ?? hint.text}
    </button>
  );

  function handleClick() {
    toggleHint({ hintId: hint.id });
  }
};
