import SystemCheckbox from "./SystemCheckbox";
import useCreateTestPageQuestionBankUsage from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-createtest-page-questionbank-usage";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";

const CreateTestSystems = () => {
  const { toggleSelection, selectedTopics } = useCreateTestPageStore();
  const {
    data: usage,
    isLoading,
    isPlaceholderData,
  } = useCreateTestPageQuestionBankUsage();

  const systems = Object.keys(usage?.systems ?? {}).sort((a, b) =>
    a.localeCompare(b),
  );

  const systemGroups = [
    systems.slice(0, systems.length - 10),
    systems.slice(systems.length - 10),
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 grid-flow-row lg:gap-x-16 w-full lg:max-w-[80%]">
      {systemGroups.map((sg, index) => {
        return (
          <div
            key={index}
            className="flex flex-col justify-start items-center lg:items-start gap-y-2 w-full"
          >
            {sg &&
              sg.map((systemId) => {
                const system = usage?.systems[systemId];
                let hasUncheckedTopic = false;
                for (const topicId of Object.keys(
                  usage?.systems[systemId]?.topics ?? {},
                )) {
                  if (!selectedTopics.includes(topicId)) {
                    if (usage?.systems[systemId].topics[topicId]?.count !== 0)
                      hasUncheckedTopic = true;
                  }
                }
                const checked = !hasUncheckedTopic;

                return (
                  <SystemCheckbox
                    className="w-full"
                    key={systemId}
                    systemId={systemId}
                    systemName={system?.name ?? ""}
                    questionCount={usage?.systems[systemId]?.count ?? 0}
                    isLoading={isLoading || isPlaceholderData}
                    checked={checked}
                    onClick={() => {
                      for (const topicId of Object.keys(
                        usage?.systems[systemId]?.topics ?? {},
                      )) {
                        if (
                          usage?.systems[systemId].topics[topicId]?.count !== 0
                        ) {
                          toggleSelection("selectedTopics", !checked, topicId);
                        }
                      }
                    }}
                  />
                );
              })}
          </div>
        );
      })}
    </div>
  );
};
export default CreateTestSystems;
