import { SearchQuestionBankLibraryInput } from "@/api/src/server/routers/library";
import { trpc } from "@/utils/trpc";

const useSearchQuestionbankLibrary = (
  input: SearchQuestionBankLibraryInput,
) => {
  return trpc.library.searchQuestionbankLibrary.useQuery(input, {
    enabled:
      (!!input.questionBankEndpoint || !!input.questionBankId) &&
      !!input.search,
    placeholderData: (previous) => previous,
  });
};
export default useSearchQuestionbankLibrary;
