import { nbmeHintTriggerVariants } from "./NbmeHintTrigger";
import { NbmeSettingsSegment } from "./NbmeSettingsSegment";
import { useNbmeFontWeight } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSlider } from "./NbmeSettingsSlider";

export const NbmeSettingsFontWeightSegment = () => {
  const [fontWeight, setFontWeight] = useNbmeFontWeight();
  return (
    <NbmeSettingsSegment className="flex-col justify-start items-start gap-2">
      <div className="flex flex-row justify-between items-center w-full text-xs">
        <p>Font weight</p>
        <div className="flex flex-row justify-start items-center gap-2">
          <button
            className={nbmeHintTriggerVariants({
              style: "button",
              className: "text-2xs",
            })}
            onClick={() => setFontWeight(400)}
          >
            Reset
          </button>
        </div>
      </div>
      <NbmeSettingsFontWeightControl />
    </NbmeSettingsSegment>
  );
};

const NbmeSettingsFontWeightControl = () => {
  const [fontWeight, setFontWeight] = useNbmeFontWeight();
  return (
    <div className="flex flex-row justify-start items-center w-full gap-2">
      <span className="font-thin">A</span>
      <NbmeSettingsSlider
        value={fontWeight}
        onValueChange={setFontWeight}
        max={900}
        min={100}
      />
      <span className="font-black">A</span>
    </div>
  );
};
