import { GetPreviousTestsInput } from "@/api/src/server/routers";
import { trpc } from "@/utils/trpc";
import { useEffect } from "react";

const usePreviousTests = (input: GetPreviousTestsInput) => {
  const utils = trpc.useUtils();
  const query = trpc.test.getPreviousTests.useQuery(input, {
    enabled: !!input.questionBankId || !!input.questionBankEndpoint,
    placeholderData: (previous) => previous,
  });
  useEffect(() => {
    if (!input.questionBankId && !input.questionBankEndpoint) return;
    if (input.page > 1) {
      utils.test.getPreviousTests.prefetch({
        ...input,
        page: input.page - 1,
      });
    }
    if (query.data && !!query.data.nextPage) {
      utils.test.getPreviousTests.prefetch({
        ...input,
        page: input.page + 1,
      });
    }
  }, [input.page, input.questionBankId, query.data]);
  return query;
};

export default usePreviousTests;
