import { NbmeSettingsFontSizeControl } from "./NbmeSettingsFontSizeControl";
import { NbmeSettingsBarSection } from "./NbmeSettingsBarSection";
import { NbmeSettingsSegment } from "./NbmeSettingsSegment";
import { NbmeSettingsThemeControl } from "./NbmeSettingsThemeControl";
import { NbmeSettingsTimerControl } from "./NbmeSettingsTimerControl";
import { useNbmeNightMode } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSplitViewControl } from "./NbmeSettingsSplitViewControl";
import { NbmeSettingsUnsubmittedMarkControl } from "./NbmeSettingsUnsubmittedMarkControl";
import { NbmeSettingsExhibitStyleControl } from "./NbmeSettingsExhibitStyleControl";
import { NbmeSettingsLineWidthSegment } from "./NbmeSettingsLineWidthSegment";
import { NbmeSettingsAlignmentControl } from "./NbmeSettingsAlignmentControl";
import { NbmeSettingsAllocateSpaceForSidebarControl } from "./NbmeSettingsAllocateSpaceForSidebarControl";
import { NbmeSettingsAutoHideSidebarControl } from "./NbmeSettingsAutoHideSidebarControl";
import { NbmeSettingsAutoShowSidebarControl } from "./NbmeSettingsAutoShowSidebarControl";
import { NbmeSettingsShowSidebarOnHoverControl } from "./NbmeSettingsShowSidebarOnHoverControl";
import { NbmeSettingsImageAlignmentControl } from "./NbmeSettingsImageAlignmentControl";
import { NbmeSettingsPaddingSegment } from "./NbmeSettingsPaddingSegment";
import { NbmeSettingsFontWeightSegment } from "./NbmeSettingsFontWeightSegment";

export const NbmeSettingsAppearanceSection = () => {
  const { nightMode } = useNbmeNightMode();

  return (
    <NbmeSettingsBarSection title={"Appearance"}>
      <NbmeSettingsSegment>
        <p className="text-xs">Font Size</p>
        <NbmeSettingsFontSizeControl />
      </NbmeSettingsSegment>
      <NbmeSettingsFontWeightSegment />
      <NbmeSettingsSegment disabled={nightMode.on}>
        <p className="text-xs">Color Theme</p>
        <NbmeSettingsThemeControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Split view</p>
        <NbmeSettingsSplitViewControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Show unsubmitted question indicator</p>
        <NbmeSettingsUnsubmittedMarkControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Show Timer</p>
        <NbmeSettingsTimerControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Exhibit Style</p>
        <NbmeSettingsExhibitStyleControl />
      </NbmeSettingsSegment>
      <NbmeSettingsLineWidthSegment />
      <NbmeSettingsSegment>
        <p className="text-xs">Text Alignment</p>
        <NbmeSettingsAlignmentControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Image Alignment</p>
        <NbmeSettingsImageAlignmentControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Save Space for Sidebar</p>
        <NbmeSettingsAllocateSpaceForSidebarControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Show Sidebar Automatically</p>
        <NbmeSettingsAutoShowSidebarControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Hide Sidebar Automatically</p>
        <NbmeSettingsAutoHideSidebarControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Show Sidebar by hovering over screen edge</p>
        <NbmeSettingsShowSidebarOnHoverControl />
      </NbmeSettingsSegment>
      <NbmeSettingsPaddingSegment />
    </NbmeSettingsBarSection>
  );
};
