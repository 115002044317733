import { GetPreviousTestsOutput } from "@/api/src/server/routers";
import {
  QuestionBankOverallStatisticsOutput,
  TestResultOutput,
} from "@/api/src/server/routers/statistics/types";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import usePerformanceReportsPageStore from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.performance/reports/-store";
import { usePreviousTestsPageStore } from "@/utils/stores";
import { queryClient, trpc, trpcProxyClient } from "@/utils/trpc";
import { ArrayElement } from "@coursology/types";
import { getQueryKey } from "@trpc/react-query";
import { toast } from "sonner";
import useMutatePreviousTestsQueryCache from "../utility/use-mutate-previous-tests-query-cache";
import useMutateOverallStatisticsPageQueryCache from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.performance/overall/-hooks/use-mutate-overallstatistics-page-query-cache";
import { current } from "immer";

//TODO: Refactor this
const useDeleteTest = () => {
  const { data: questionBank } = useDashboardQuestionBank();
  const mutatePreviousTests = useMutatePreviousTestsQueryCache()();
  const mutateOverallStatistics = useMutateOverallStatisticsPageQueryCache();
  return trpc.test.deleteTest.useMutation({
    onMutate: ({ testId, resetQuestions }) => {
      const { query, testsPerPage, currentPage } =
        usePreviousTestsPageStore.getState();

      const overallStatsQueryKey = getQueryKey(
        trpc.statistics.getQuestionBankOverallStatistics,
        { questionBankId: questionBank?.id ?? "" },
        "query",
      );

      const testResultQueryKey = getQueryKey(
        trpc.statistics.getTestResult,
        { testId },
        "query",
      );

      const previousTestpageQueryKey = getQueryKey(
        trpc.test.getPreviousTests,
        {
          search: query,
          page: currentPage,
          testsPerPage,
          questionBankId: questionBank?.id ?? "",
        },
        "query",
      );

      let targetTest:
        | ArrayElement<GetPreviousTestsOutput["tests"]>
        | undefined = undefined;

      const rollbackPreviousTests = mutatePreviousTests((state) => {
        const targetTestIndex = state.tests.findIndex((t) => t.id === testId);
        if (targetTestIndex !== -1) {
          targetTest = current(state.tests[targetTestIndex]);
          state.tests[targetTestIndex].deletedAt = new Date();
        }
      });

      const rollbackOverallStatistics = mutateOverallStatistics((state) => {
        state.tests.total -= 1;
        if (targetTest && targetTest.submitted) {
          state.tests.completed -= 1;
        }
        if (targetTest && targetTest.suspended) {
          state.tests.suspended -= 1;
        }

        if (resetQuestions) {
          const testResult =
            queryClient.getQueryData<TestResultOutput>(testResultQueryKey);
          if (!testResult) return;
          state.questions.used -= testResult.total;
          state.questions.unused += testResult.total;
          state.questions.correct -= testResult.correct;
          state.questions.incorrect -= testResult.incorrect;
          state.questions.omitted -= testResult.omitted;
        }
      });

      //Function to rollback change
      return {
        previousTestpageQueryKey,
        overallStatsQueryKey,
        rollback: () => {
          if (!targetTest) return;
          rollbackPreviousTests();
          rollbackOverallStatistics();
        },
      };
    },
    onSuccess: (
      data,
      variables,
      { overallStatsQueryKey, previousTestpageQueryKey },
    ) => {
      if (variables.resetQuestions) {
        queryClient
          .invalidateQueries({ queryKey: overallStatsQueryKey })
          .then(() => {
            queryClient.prefetchQuery({
              queryKey: overallStatsQueryKey,
              queryFn: () =>
                trpcProxyClient.statistics.getQuestionBankOverallStatistics.query(
                  { questionBankId: questionBank?.id ?? "" },
                ),
            });
          });
        const performanceReportsPageState =
          usePerformanceReportsPageStore.getState();
        (["subjects", "systems", "topics"] as const).forEach((articleType) => {
          const queryInputs = {
            questionBankId: questionBank?.id ?? "",
            search: performanceReportsPageState.query,
            for: articleType,
            page: performanceReportsPageState.currentPage,
            reportsPerPage: performanceReportsPageState.reportsPerPage,
          };
          const performanceReportsQueryKey = getQueryKey(
            trpc.statistics.getQuestionBankPerformanceReports,
            queryInputs,
            "query",
          );
          queryClient
            .invalidateQueries({ queryKey: performanceReportsQueryKey })
            .then(() => {
              queryClient.prefetchQuery({
                queryKey: performanceReportsQueryKey,
                queryFn: () =>
                  trpcProxyClient.statistics.getQuestionBankPerformanceReports.query(
                    queryInputs,
                  ),
              });
            });
        });
      }

      queryClient.invalidateQueries({ queryKey: previousTestpageQueryKey });
    },
    onError: (err, _, ctx) => {
      toast.error(`Could not delete test..., an error ocurred: ${err.message}`);
      ctx?.rollback?.();
    },
  });
};
export default useDeleteTest;
