import { SetTestModeInput } from "@/api/routers";
import useTestPageTest from "./use-test-page-test";
import useSetTestMode from "@/hooks/test-hooks/use-set-test-mode";

const useTestPageSetTestMode = () => {
  const { data: test } = useTestPageTest();
  const { mutate: setTestMode } = useSetTestMode();
  return ({ mode }: Pick<SetTestModeInput, "mode">) => {
    test && setTestMode({ testId: test.id, mode });
  };
};

export default useTestPageSetTestMode;
