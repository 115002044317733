import { useOnClient } from "@/hooks";
import { useUsersDashboard } from "../hooks";
import { DashboardTable } from "@/components/DashboardTable";
import { formatDate } from "./utils";
import {
  AllowResetButton,
  ExtraMonthsController,
  RenewButton,
  SubscriptionController,
} from "./components";
import { Paginator } from "@/components/Paginator";
import { useState } from "react";
import { Input } from "@/components/Input";
import _ from "underscore";
import PasswordInput from "./components/password-input";

export const Table = () => {
  const onClient = useOnClient();
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const { users, isLoading } = useUsersDashboard((state) => ({
    users: state.filteredUsers,
    isLoading: state.areUsersLoading,
  }));
  return (
    <div className="flex flex-col justify-start items-center w-full">
      <Input value={query} onChange={(e) => setQuery(e.target.value)} />
      <Paginator
        currentPage={page}
        onCurrentPageChange={setPage}
        itemsPerPage={50}
        numberOfItems={users.length}
        onItemsPerPageChange={() => undefined}
        itemSingularName="user"
      />
      <DashboardTable
        className="w-full overflow-y-scroll"
        items={_.sortBy(users, (u) => u.createdAt).filter(
          (u) =>
            u.email.toLowerCase().includes(query.toLowerCase()) ||
            u.username.toLowerCase().includes(query.toLowerCase()) ||
            u.phoneNumber.includes(query.toLowerCase()),
        )}
        itemsPerPage={50}
        currentPage={1}
        isLoading={isLoading}
        columns={{
          columns: [
            {
              name: "Name",
              visible: true,
              content: (user) => <p className="text-sm">{user.name}</p>,
            },
            {
              name: "Email",
              visible: true,
              content: (user) => <p className="text-sm">{user.email}</p>,
            },
            {
              name: "Password",
              visible: true,
              content: (user) => (
                <PasswordInput
                  initialPassword={user.password}
                  userId={user.id}
                />
              ),
            },
            {
              name: "Phone Number",
              visible: true,
              content: (user) => <p className="text-sm">{user.phoneNumber}</p>,
            },
            {
              name: "Country",
              visible: true,
              content: (user) => <p className="text-sm">{user.country}</p>,
            },
            {
              name: "Subscription",
              visible: true,
              content: (user) => (
                <div className="flex flex-col justify-start items-start gap-1">
                  <SubscriptionController
                    userId={user.id}
                    currentSubscriptionId={user.currentSubscriptionId ?? ""}
                  />
                  <RenewButton userId={user.id} />
                </div>
              ),
            },
            {
              name: "Last Renewed in:",
              visible: true,
              content: (user) =>
                user.lastRenewal ? formatDate(user.lastRenewal) : "Never",
            },
            {
              name: "Joined in:",
              visible: true,
              content: (user) => formatDate(user.createdAt),
            },
            {
              name: "Extra Months",
              visible: true,
              content: (user) => (
                <ExtraMonthsController
                  userId={user.id}
                  userExtraMonths={user.extraMonths ?? 0}
                />
              ),
            },
            {
              name: "Allow Reset",
              visible: true,
              content: (user) => (
                <AllowResetButton
                  lastResetAt={user.lastResetAt}
                  userId={user.id}
                />
              ),
            },
          ],
          collapsible: false,
        }}
      />
    </div>
  );
};
