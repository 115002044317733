import useMutateTestSessionQueryCache from "@/hooks/utility/use-mutate-test-session-query-cache";
import { useParams } from "@tanstack/react-router";
import { useMemo } from "react";

const useMutateTestPageSessionCache = () => {
  const { testId } = useParams({
    from: "/qbanks/$qbankEndpoint/tests/$testId",
  });
  const cacheMutator = useMutateTestSessionQueryCache();
  return useMemo(
    () => cacheMutator({ testId: Number(testId) }),
    [testId, cacheMutator],
  );
};

export default useMutateTestPageSessionCache;
