import { Button } from "@/components/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useCreateTestPageStore } from "@/utils/stores";
import useCreateTestPageCreateTest from "../-hooks/use-create-test-page-create-test";
import { useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-router";

const CreateTestButton = ({ isLoading }: { isLoading: boolean }) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [isCreatingTest, setIsCreatingTest] = useState<boolean>(false);
  const { questionCount, questionIds, selectedBlueprint, qmode } =
    useCreateTestPageStore((state) => ({
      toggledSections: state.toggledSections,
      selectedSubjects: state.selectedSubjects,
      selectedTopics: state.selectedTopics,
      selectedBlueprint: state.selectedBlueprint,
      name: state.name,
      minutesPerQuestion: state.minutesPerQuestion,
      setMinutesPerQuestion: state.setMinutesPerQuestion,
      qPool: {
        unused: state.unused,
        marked: state.marked,
        incorrect: state.incorrect,
        correct: state.correct,
        omitted: state.omitted,
      },
      qmode: state.qmode,
      questionCount: state.questionCount,
      mode: {
        tutor: state.tutor,
        timed: state.timed,
      },
      unused: state.unused,
      incorrect: state.incorrect,
      marked: state.marked,
      omitted: state.omitted,
      correct: state.correct,

      questionIds: state.questionIds,
      reset: state.reset,
    }));
  const { mutate: createTest } = useCreateTestPageCreateTest();

  const handleSubmit = () => {
    setIsCreatingTest(true);
    setDisabled(true);
    createTest({
      onError: () => {
        setDisabled(false);
        setIsCreatingTest(false);
      },
      onSuccess: (newTest) => {
        //This will reset everytime this component is dismounted so no worries.
        setIsCreatingTest(false);
        setDisabled(false);
        newTest &&
          navigate({
            from: "/qbanks/$qbankEndpoint/dashboard/create-test",
            to: "/qbanks/$qbankEndpoint/tests/$testId",
            params: { testId: String(newTest.id) },
          });
      },
    });
  };
  const isButtonDisabled = () => {
    let isDisabled = disabled;
    if (qmode === "custom") {
      return questionIds.length === 0;
    }
    if (qmode === "ordered") {
      return !selectedBlueprint;
    }

    return isDisabled || Number(questionCount) === 0;
  };
  return (
    <div className="flex flex-row justify-start items-center gap-2">
      <Button
        disabled={isButtonDisabled() || isLoading || isCreatingTest}
        className="text-xs md:text-sm lg:text-sm px-4 w-fit h-10"
        onClick={handleSubmit}
      >
        Generate Test
      </Button>
      {(isLoading || isCreatingTest) && (
        <div className="w-10 h-10">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default CreateTestButton;
