import {
  GetQuestionBankLibraryInput,
  GetQuestionBankLibraryOutput,
} from "@/api/src/server/routers/library";
import useMutateQueryCache from "./use-mutate-query-cache";
import { trpc } from "@/utils/trpc";

const useMutateLibraryQueryCache = () => {
  return useMutateQueryCache<
    GetQuestionBankLibraryInput,
    GetQuestionBankLibraryOutput
  >(trpc.library.getQuestionBankLibrary, "query");
};

export default useMutateLibraryQueryCache;
