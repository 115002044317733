import { cn } from "@/lib/utils";

const Text = ({ className, ...props }: React.ComponentProps<"span">) => (
  <span
    className={cn(
      "text-gray-800 dark:text-neutral-300 warm:text-brown-800",
      className,
    )}
    {...props}
  />
);

export default Text;
