import MedicalLibrary from "@/ncomponents/medical-library";
import { useDashboardLayoutStore } from "@/utils/stores";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/medical-library",
)({
  component: MedicalLibraryPage,
});

function MedicalLibraryPage() {
  const setHeaderText = useDashboardLayoutStore((state) => state.setHeaderText);
  useEffect(() => {
    setHeaderText("Medical Library");
  }, [setHeaderText]);

  return <MedicalLibrary location="dashboard" />;
}
