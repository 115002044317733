import { trpc } from "@/utils/trpc";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import { toast } from "sonner";

const useChangeHighlightHighlightMarker = () => {
  const mutateTestSlotCache = useMutateTestSlotQueryCache()();
  return trpc.highlight.changeMarker.useMutation({
    onMutate: ({ highlightId, highlightMarkerId }) => {
      let oldHighlightMarkerId: string | undefined;
      const rollbackTestSlots = mutateTestSlotCache((state) => {
        const targetHighlight =
          state.question.highlights.find((h) => h.id === highlightId) ??
          state.question.explanation.highlights.find(
            (h) => h.id === highlightId,
          );
        if (!targetHighlight) return;
        oldHighlightMarkerId = targetHighlight.markerId;
        targetHighlight.markerId = highlightMarkerId;
      });
      return {
        rollback: () => {
          rollbackTestSlots((state) => {
            const targetHighlight =
              state.question.highlights.find((h) => h.id === highlightId) ??
              state.question.explanation.highlights.find(
                (h) => h.id === highlightId,
              );
            if (!targetHighlight || !oldHighlightMarkerId) return;
            targetHighlight.markerId = oldHighlightMarkerId;
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not change highlight marker, an error occurred: ${error.message}`,
      );
      context?.rollback();
    },
  });
};
export default useChangeHighlightHighlightMarker;
