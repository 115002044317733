import { Button } from "@/components/ui/button";
import { SearchInput } from "./SearchInput";
import { FiltersIcon } from "@/components/icons";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { useNotebookUi } from "@/hooks/notebook";
import { PopoverPortal } from "@radix-ui/react-popover";

const OptionsPopover = () => {
  const showDeletedNotes = useNotebookUi((state) => state.showDeletedNotes);
  const setShowDeletedNotes = useNotebookUi(
    (state) => state.setShowDeletedNotes,
  );
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={"ghost"} size={"icon"} className="w-auto h-auto p-2">
          <FiltersIcon className="text-gray-700 dark:text-neutral-500 warm:text-brown-700" />
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent className="!w-auto z-[10000000]">
          <div className="flex flex-col justify-start items-center">
            <div className="flex flex-row justify-start items-center gap-3">
              <Switch
                checked={showDeletedNotes}
                onCheckedChange={setShowDeletedNotes}
                id="show-deleted-tests-input"
              />
              <Label htmlFor="show-deleted-tests-input">
                Show Deleted Notes
              </Label>
            </div>
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

export const Header = () => {
  return (
    <div className="flex flex-row justify-between items-center w-full">
      <SearchInput />
      <OptionsPopover />
    </div>
  );
};
