import { MobileDrawer } from "@/components/common";
import ArticleIcon from "@/components/icons/hollow/article";
import NbmeSidePanel from "@/components/nbme-side-panel";
import { NbmeIconNavbarButton } from "@/components/NbmeIconNavbarButton";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import MedicalLibrary from "@/ncomponents/medical-library";
import { useMedicalLibraryVariant } from "@/ncomponents/medical-library/store";
import { Setter } from "@coursology/types";
import { useParams } from "@tanstack/react-router";
import { PropsWithChildren, useState } from "react";

const SidePanel = ({
  open,
  onOpenChange,
  children,
}: { open: boolean; onOpenChange: Setter<boolean> } & PropsWithChildren) => {
  return (
    <NbmeSidePanel.Root
      draggable={false}
      open={open}
      onOpenChanged={onOpenChange}
      emergeFrom="left"
      closeOnClickOutside={false}
      setCloseOnClickOutside={() => false}
    >
      <NbmeSidePanel.Body className="flex flex-row h-screen w-[50vw] p-0 border-l-gray-300 overflow-hidden max-h-screen dark:border-l-neutral-600 dark:bg-neutral-900">
        <NbmeSidePanel.CloseButton className="absolute top-3 right-3" />
        <div className="max-h-screen h-full w-full flex flex-row">
          {children}
        </div>
      </NbmeSidePanel.Body>
    </NbmeSidePanel.Root>
  );
};

export const NbmeMedicalLibraryButton = () => {
  const [open, setOpen] = useState(false);
  const [variant] = useMedicalLibraryVariant();
  const isOnMobile = variant === "mobile";
  const Parent = isOnMobile ? MobileDrawer : SidePanel;

  return (
    <>
      <NbmeIconNavbarButton
        className="w-full md:w-auto"
        icon={<ArticleIcon className="w-6 h-6" />}
        label="Medical Library"
        onClick={() => setOpen(!open)}
      />
      <Parent open={open} onOpenChange={setOpen}>
        <MedicalLibrary location="test" />
      </Parent>
    </>
  );
};
