import { StarIcon, SearchIcon } from "@/components/icons/hollow";
import { Button } from "@/components/ui/button";
import { ComponentProps } from "react";
import {
  useMedicalLibraryPage,
  useMedicalLibraryRouter,
  useMedicalLibrarySidebarIsOpen,
} from "../store";
import { cn } from "@/lib/utils";

const HeaderButton = ({
  children,
  page,
  ...props
}: ComponentProps<typeof Button> & { page: "bookmarks" | "search" }) => {
  const [, setIsOpen] = useMedicalLibrarySidebarIsOpen();
  const [libraryPage] = useMedicalLibraryPage();
  const router = useMedicalLibraryRouter();
  return (
    <Button
      variant={"ghost"}
      {...props}
      className={cn(
        "flex flex-row justify-start items-center gap-x-3 font-normal text-base w-full text-gray-600",
        libraryPage === page &&
          "bg-primary-500 warm:bg-brown-700 warm:text-white warm:hover:text-white warm:hover:bg-brown-700 text-white hover:bg-primary-600 hover:text-white dark:bg-white dark:hover:bg-white dark:text-neutral-900 dark:hover:text-neutral-900",
      )}
      onClick={() => {
        router.push(page);
        setIsOpen(false);
      }}
    >
      {children}
    </Button>
  );
};

const Header = () => {
  return (
    <div className="p-2 flex flex-col gap-y-1 justify-start items-center w-full border-b border-b-gray-300">
      <HeaderButton page="search">
        <SearchIcon />
        <span className="capitalize truncate">Search Medical Library</span>
      </HeaderButton>
      <HeaderButton page="bookmarks">
        <StarIcon />
        <span className="capitalize truncate">Bookmarks</span>
      </HeaderButton>
    </div>
  );
};

export default Header;
