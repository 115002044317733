import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";
import { useEffect } from "react";
import { z } from "zod";

const schema = z.object({ token: z.string() });
export const Route = createFileRoute("/auth/token")({
  component: TokenPage,
  validateSearch: zodValidator(schema),
});

function TokenPage() {
  const { signIn } = useAuthSlice();
  const { token } = Route.useSearch();
  const router = useRouter();
  useEffect(() => {
    if (token) {
      signIn(token);
      router.navigate({ to: "/home" });
    }
  }, [token, router, signIn]);
  return <></>;
}
