"use client";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import { Input } from "./Input";
import QuestionCountBadge from "./QuestionCountBadge";
import { useEffect, useMemo } from "react";
import useCreateTestPageQuestionBankUsage from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-createtest-page-questionbank-usage";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";

const CreateTestQNumSection = () => {
  const { data: questionBank } = useDashboardQuestionBank();
  const {
    toggleSection,
    toggledSections,
    questionCount,
    setQuestionCount,
    selectedSubjects,
    unused,
    correct,
    incorrect,
    marked,
    omitted,
    selectedTopics,
  } = useCreateTestPageStore();

  const {
    data: usage,
    isLoading,
    isPlaceholderData,
  } = useCreateTestPageQuestionBankUsage();

  const progressLoading = isLoading || isPlaceholderData;

  const absoluteMaxQCount = useMemo(() => {
    let count = 0;
    if (unused) {
      count += usage?.unused ?? 0;
    }
    if (correct) {
      count += usage?.correct ?? 0;
    }
    if (incorrect) {
      count += usage?.incorrect ?? 0;
    }
    if (marked) {
      count += usage?.marked ?? 0;
    }
    if (omitted) {
      count += usage?.omitted ?? 0;
    }
    if (selectedSubjects.length > 0) {
      count = 0;
      for (const subject of selectedSubjects) {
        const subjectUsage = usage?.subjects[subject]?.count ?? 0;
        count += subjectUsage;
      }
    }

    if (selectedTopics.length > 0) {
      count = 0;
      for (const topic of selectedTopics) {
        const topicUsage = usage?.topics[topic]?.count ?? 0;
        count += topicUsage;
      }
    }
    return count;
  }, [
    usage,
    selectedSubjects,
    selectedTopics,
    correct,
    incorrect,
    marked,
    omitted,
    unused,
  ]);

  const clampedMaxQCount = useMemo(() => {
    return Math.min(
      absoluteMaxQCount,
      questionBank?.maximumQuestionCountPerTest ?? 40,
    );
  }, [absoluteMaxQCount, questionBank]);

  useEffect(() => {
    if (Number(questionCount) > clampedMaxQCount) {
      setQuestionCount(clampedMaxQCount.toString());
    }
  }, [
    questionCount,
    selectedSubjects,
    selectedTopics,
    unused,
    clampedMaxQCount,
    setQuestionCount,
    correct,
    incorrect,
    marked,
    omitted,
  ]);

  return (
    <DashboardAccordionSection
      title="No. of Questions"
      value="qnum"
      className=""
      onToggle={() => toggleSection("qnum")}
      toggled={toggledSections.includes("systems")}
    >
      <div className="ml-8 flex flex-row justify-start items-center gap-3  pb-5 pt-2">
        <div className="w-16">
          <Input
            className="text-sm md:text-sm lg:text-sm py-2 text-center rounded-sm bg-gray-200 border-gray-300 outline-gray-400 text-gray-500"
            min={0}
            max={clampedMaxQCount}
            type="number"
            value={Number(questionCount) < 0 ? "0" : questionCount}
            onChange={(e) => setQuestionCount(e.target.value)}
          />
        </div>
        <p className="flex-grow text-sm text-gray-500 dark:text-neutral-400">
          Max Allowed per block
        </p>
        <QuestionCountBadge
          className="bg-gray-200 border border-gray-300 text-gray-500 no-spinners"
          count={clampedMaxQCount}
          loading={progressLoading}
        />
      </div>
    </DashboardAccordionSection>
  );
};

export default CreateTestQNumSection;
