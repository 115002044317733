import { createFileRoute, useParams } from "@tanstack/react-router";
import { DashboardFrame } from "@/components/DashboardFrame";
import {
  PerformanceReportsPageQueryInput,
  PerformanceReportsPageSelect,
  PerformanceReportsPageTabs,
} from "./-components";
import { useBreakPoint } from "@/utils/hooks";
import { BreakPoints } from "@/types";
import { useDashboardLayoutStore } from "@/utils/stores";
import { useEffect } from "react";
import usePerformanceReportsPageStore from "./-store";
import { PerformanceReportsPageTable } from "./-components";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useTitle } from "@/hooks/common/use-title";
import useDashboardQuestionBank from "../../-dashboard-hooks/use-dashboard-question-bank";
import useQuestionBankPerformanceReports from "@/hooks/statistics/use-questionbank-performance-reports";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/performance/reports",
)({
  component: PerformanceReportsPage,
});

function PerformanceReportsPage() {
  const { qbankEndpoint } = useParams({ strict: false });
  const { data: questionBank } = useDashboardQuestionBank();
  useTitle(`Performance Reports ${questionBank?.name}`);
  const breakPoint = useBreakPoint();
  const isScreenSmall = breakPoint < BreakPoints.Medium;
  const { currentTab, currentPage, reportsPerPage, search } =
    usePerformanceReportsPageStore((state) => ({
      currentTab: state.currentTab,
      currentPage: state.currentPage,
      reportsPerPage: state.reportsPerPage,
      search: state.query,
    }));

  const { isLoading, isPlaceholderData } = useQuestionBankPerformanceReports({
    for: currentTab,
    questionBankEndpoint: qbankEndpoint ?? "",
    page: currentPage,
    reportsPerPage,
    search,
  });

  return (
    <DashboardFrame className="min-h-full w-full">
      <div
        className={`w-full p-9 flex flex-col justify-start items-start gap-7`}
      >
        <PerformanceReportsPageTabs questionBankId={questionBank?.id ?? ""} />
        {isScreenSmall ? (
          <div className="relative flex flex-col justify-start items-center gap-6 w-full">
            <PerformanceReportsPageSelect />
            <div className="flex flex-row justify-center items-center w-full">
              <PerformanceReportsPageQueryInput
                isLoading={isLoading || isPlaceholderData}
              />
            </div>
          </div>
        ) : (
          <div className="relative flex w-full flex-row items-center justify-between">
            <PerformanceReportsPageSelect />
            <PerformanceReportsPageQueryInput
              isLoading={isLoading || isPlaceholderData}
            />
          </div>
        )}
      </div>
      <PerformanceReports questionBankId={questionBank?.id ?? ""} />
    </DashboardFrame>
  );
}

function PerformanceReports({ questionBankId }: { questionBankId: string }) {
  const params = Route.useParams();

  const setDashboardHeader = useDashboardLayoutStore(
    (state) => state.setHeaderText,
  );
  useEffect(() => {
    setDashboardHeader("Reports");
    return () => setDashboardHeader("");
  }, []);
  const { currentTab, currentPage, reportsPerPage, search } =
    usePerformanceReportsPageStore((state) => ({
      currentTab: state.currentTab,
      currentPage: state.currentPage,
      reportsPerPage: state.reportsPerPage,
      search: state.query,
    }));

  const { isLoading } = useQuestionBankPerformanceReports({
    for: currentTab,
    questionBankEndpoint: params.qbankEndpoint,
    page: currentPage,
    reportsPerPage,
    search,
  });

  if (isLoading) {
    return (
      <div className="relative min-h-[50vh] min-w-full flex flex-col justify-center items-center">
        <div className="w-16 h-16 absolute left-[0.5] top-[0.5]">
          <LoadingSpinner />
        </div>
      </div>
    );
  }
  return <PerformanceReportsPageTable questionBankId={questionBankId} />;
}
