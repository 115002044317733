import { CrossTestSlotQuestionChoiceInput } from "@/api/routers/test-slot-router";
import useCrossTestSlotQuestionChoice from "@/hooks/test-slot-hooks/use-cross-testslot-questionchoice";
import { useTestPageSelectedTestSlot } from "./use-test-page-selected-test-slot";

const useTestPageCrossTestSlotQuestionChoice = () => {
  const { data: selectedSlot } = useTestPageSelectedTestSlot();
  const { mutate: crossChoice } = useCrossTestSlotQuestionChoice();
  return ({
    questionChoiceId,
  }: Omit<CrossTestSlotQuestionChoiceInput, "testSlotId">) =>
    selectedSlot &&
    crossChoice({ testSlotId: selectedSlot.id, questionChoiceId });
};

export default useTestPageCrossTestSlotQuestionChoice;
