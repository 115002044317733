import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useAdmin } from '../../-hooks'
import CreatePromoForm from './-components/create-promocode-form'
import { Button } from '@/ui/button'
import { ChevronLeft } from 'lucide-react'

export const Route = createFileRoute('/admin/promocodes/new')({
  component: NewPromocodePage,
})

function NewPromocodePage() {
  useAdmin()
  const navigate = useNavigate()
  return (
    <div className="min-h-screen bg-neutral-900 p-6">
      <Button
        variant="ghost"
        onClick={() => navigate({ to: '/admin/promocodes' })}
        className="mb-6 text-neutral-400 hover:text-neutral-100 hover:bg-neutral-800"
      >
        <ChevronLeft className="mr-2 h-4 w-4" />
        Back to promocodes
      </Button>
      <h1 className="text-2xl font-bold mb-6 text-neutral-100">
        Create Promocode
      </h1>
      <CreatePromoForm />
    </div>
  )
}
