import HollowSolidTransition from "@/components/hollow-solid-transition";
import { DeleteIcon } from "@/components/icons";
import HollowDeleteIcon from "@/components/icons/hollow/hollow-delete-icon";
import { WithTooltip } from "@/components/pages/dashboard";
import useDeleteHighlight from "@/hooks/highlight-hooks/use-delete-highlight";
import { ComponentProps } from "react";

const NbmeContextMenuDelete = ({
  className,
  highlightId,
  onHighlightDelete,
  ...props
}: ComponentProps<"button"> & {
  highlightId: string;
  onHighlightDelete?: () => void;
}) => {
  const { mutate: deleteHighlight } = useDeleteHighlight();
  return (
    <WithTooltip tooltip="Delete" className={className}>
      <button
        {...props}
        onClick={(e) => {
          props.onClick?.(e);
          deleteHighlight({ highlightId });
          onHighlightDelete?.();
        }}
      >
        <HollowSolidTransition.Root className="p-1 dark:bg-neutral-600 warm:bg-brown-700 bg-nbme-primary-700/70 dark:hover:shadow-none hover:shadow-md hover:shadow-nbme-primary-800 warm:hover:shadow-brown-800 rounded-primary hover:scale-110 transition-[transform,_box-shadow] active:scale-95">
          <HollowSolidTransition.Solid>
            <DeleteIcon className="w-5 h-5" />
          </HollowSolidTransition.Solid>
          <HollowSolidTransition.Hollow>
            <HollowDeleteIcon className="w-5 h-5" />
          </HollowSolidTransition.Hollow>
        </HollowSolidTransition.Root>
      </button>
    </WithTooltip>
  );
};

export default NbmeContextMenuDelete;
