import useMutateTestSlotQueryCache from "@/hooks/utility/use-mutate-test-slot-query-cache";
import useTestPageTestSession from "./use-test-page-test-session";
import { useMemo } from "react";

const useMutateTestPageSelectedSlotQueryCache = () => {
  const { data: testSession } = useTestPageTestSession();
  const cacheMutator = useMutateTestSlotQueryCache();
  return useMemo(
    () =>
      cacheMutator({
        testId: testSession?.id ?? 0,
        slot: testSession?.selectedSlot ?? 0,
      }),
    [testSession, cacheMutator],
  );
};

export default useMutateTestPageSelectedSlotQueryCache;
