import { trpc } from "@/utils/trpc";
import { current } from "immer";
import { toast } from "sonner";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import useMutateNotesPageNotesQueryCache from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.notes/-notes-page-hooks/use-mutate-notes-page-notes-query-cache";

const useEditQuestionNote = () => {
  const mutateTestSlotCache = useMutateTestSlotQueryCache()(undefined);
  const mutateQuestionNotesCache = useMutateNotesPageNotesQueryCache();

  return trpc.questionNote.editQuestionNote.useMutation({
    onMutate: ({ text, questionId }) => {
      let oldQuestionNote: { text: string } | null | undefined = undefined;
      const rollbackTestSlot = mutateTestSlotCache((state) => {
        //We use current to leak values out of the immer state
        if (state.question.id !== questionId) return;
        oldQuestionNote = state.question.note
          ? current(state.question.note)
          : null;
        if (state.question.id === questionId && state.question.note)
          state.question.note.text = text;
      });

      const rollbackQuestionNotes = mutateQuestionNotesCache((state) => {
        const targetNote = state.notes.find(
          (n) => n.question.id === questionId,
        );
        if (!oldQuestionNote && targetNote) {
          oldQuestionNote = current(targetNote);
        }
        if (targetNote) targetNote.text = text;
      });

      return {
        rollback: () => {
          rollbackTestSlot((state) => {
            if (
              state.question.id === questionId &&
              state.question.note &&
              oldQuestionNote !== undefined
            )
              state.question.note = oldQuestionNote ?? null;
          });
          rollbackQuestionNotes((state) => {
            if (!oldQuestionNote) return;
            const targetNote = state.notes.find(
              (n) => n.question.id === questionId,
            );

            if (targetNote) targetNote.text = oldQuestionNote.text;
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Failed to edit question note.., an error ocurred: ${error.message}`,
      );
      context?.rollback?.();
    },
  });
};

export default useEditQuestionNote;
