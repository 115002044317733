import QuestionCountBadge from "./QuestionCountBadge";
import LabeledCheckbox from "./LabeledCheckbox";
import { useCreateTestPageStore } from "@/utils/stores";

interface SubjectCheckBoxProps {
  topicId: string;
  topicName: string;
  onCheckedChange: (checked: boolean) => void;
  className?: string;
  systemId: string;
  systemName: string;
  labelClassName?: string;
  isLoading: boolean;
  questionCount: number;
}

const TopicCheckbox = ({
  topicId,
  topicName,
  onCheckedChange,
  className,
  labelClassName,
  isLoading,
  questionCount,
}: SubjectCheckBoxProps) => {
  const disabled = questionCount === 0;
  const { selectedTopics } = useCreateTestPageStore();
  const checked = selectedTopics.includes(topicId);

  return (
    <div className="flex justify-between items-center gap-2 w-full">
      <LabeledCheckbox
        className={className}
        id={`${topicId}-subject-checkbox`}
        checked={checked && questionCount !== 0}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
        label={topicName.at(0)!.toUpperCase() + topicName.slice(1)}
        labelClassName={labelClassName}
        checkBoxClassName={"min-w-max"}
      />
      <QuestionCountBadge count={questionCount} loading={isLoading} />
    </div>
  );
};

export default TopicCheckbox;
