import { trpc } from "@/utils/trpc";
import useMutateLibraryQueryCache from "../utility/use-mutate-library-query-cache";
import { toast } from "sonner";
import useMutateArticleContentQueryCache from "../utility/use-mutate-article-content-query-cache";

const useBookmarkArticle = () => {
  const mutateCache = useMutateLibraryQueryCache()();
  const mutateArticleContentCache = useMutateArticleContentQueryCache()();
  return trpc.articleUsage.bookmarkArticle.useMutation({
    onMutate: ({ articleId }) => {
      const rollbackLibrary = mutateCache((previous) => {
        previous.articles.forEach((a) => {
          if (a.id === articleId) {
            a.usage.bookmarkedAt = a.usage.bookmarkedAt ? null : new Date();
          }
        });
        previous.categories.forEach((c) => {
          c.articles.forEach((a) => {
            if (a.id === articleId) {
              a.usage.bookmarkedAt = a.usage.bookmarkedAt ? null : new Date();
            }
          });
        });
      });

      const rollbackArticleContent = mutateArticleContentCache((previous) => {
        if (previous.id === articleId)
          previous.usage.bookmarkedAt = previous.usage.bookmarkedAt
            ? null
            : new Date();
      });

      return {
        rollback: () => {
          rollbackLibrary();
          rollbackArticleContent();
        },
      };
    },
    onError: (err, vars, ctx) => {
      toast.error(
        `Could not bookmark article, an error ocurred... ${err.message}`,
      );
      ctx?.rollback();
    },
  });
};

export default useBookmarkArticle;
