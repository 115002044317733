import { NbmeFloatingWindow } from "./NbmeFloatingWindow";
import { Hint } from "@/models";
import { blobToDataUri } from "@/utils/common/blobToDataUri";
import { titlize } from "@/utils/common/titlize";
import {
  copyBlobToClipboard,
  copyImageToClipboard,
} from "copy-image-clipboard";
import { LoadingSpinner } from "./LoadingSpinner";
import { Interweave } from "interweave";
import { tableFilter } from "@/utils/interweave/filters";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import {
  useNbmeFontSizeFactor,
  useNbmeFontWeight,
} from "@/utils/stores/nbmeLayoutStore";
import { useHintMedia } from "@/hooks/hints";
import { trpc } from "@/utils/trpc";
import { toast } from "sonner";
import FloatingWindow from "./floating-window";
import { useViewportDimensions } from "@/utils/hooks";
import { useRef } from "react";
import domToImage from "dom-to-image";
import { Media } from "@/api/types";

interface NbmeHintProps {
  hint: Pick<Hint, "id" | "medias" | "videoId" | "imageId" | "html" | "text">;
  visible: boolean;
  onCloseClicked: () => void;
}

export const NbmeHint = ({ hint, visible, onCloseClicked }: NbmeHintProps) => {
  const breakPoint = useBreakPoint();
  const viewport = useViewportDimensions();
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const [fontWeight] = useNbmeFontWeight();
  const { image, video } = useHintMedia(hint, { enabled: visible });

  const { data: hintMedias, isLoading: isHintMediaLoading } =
    trpc.media.getByHint.useQuery(
      { hintId: hint.id },
      {
        enabled: hint.medias.length > 0 && visible,
      },
    );

  const isLoading =
    (hint.imageId && image.isLoading) || (hint.videoId && video.isLoading);

  const tableContentRef = useRef<HTMLDivElement>(null);

  if (hintMedias && hintMedias?.length > 0) {
    return (
      <>
        {isHintMediaLoading && (
          <div className="fixed left-[50%] right-[50%] -translate-x-1/2 -translate-y-1/2 z-[999]">
            <LoadingSpinner />
          </div>
        )}
        {hintMedias &&
          hintMedias.map((untypedMedia, index) => {
            const media = untypedMedia as Media & { dataUrl: string };
            const isMediaHtml = media.type === "HTML";
            const isMediaImage = media.type === "IMAGE";
            const isMediaVideo = media.type === "VIDEO";
            return (
              <NbmeFloatingWindow
                onCopy={
                  media.type === "VIDEO"
                    ? undefined
                    : media.type === "HTML"
                      ? async () => {
                          if (!tableContentRef || !tableContentRef.current)
                            return;
                          const blob = await domToImage.toBlob(
                            tableContentRef.current,
                          );
                          await copyBlobToClipboard(blob);
                          toast.success(`Successfully copied to clipboard`);
                        }
                      : async () => {
                          if (!media.url) return;
                          toast.promise(
                            copyImageToClipboard(
                              import.meta.env.VITE_CORS_PROXY_ENDPOINT +
                                media.url,
                            ),
                            {
                              loading: "Downloading image to copy...",
                              success:
                                "Successfully copied image to clipboard.",
                            },
                          );
                        }
                }
                onDownload={
                  media.url
                    ? () =>
                        handleDownload(
                          media.url ?? "",
                          media.type === "VIDEO"
                            ? hint.text.split(".").at(0) + ".mp4"
                            : hint.text,
                        )
                    : media.type === "HTML"
                      ? async () => {
                          if (!tableContentRef || !tableContentRef.current)
                            return;
                          const blob = await domToImage.toBlob(
                            tableContentRef.current,
                          );
                          const dataUri = await blobToDataUri(blob);
                          handleDownload(dataUri, hint.text, false);
                        }
                      : undefined
                }
                maximizable
                key={index}
                visible={visible}
                title={titlize(hint.text)}
                onCloseClicked={onCloseClicked}
                draggableFromBody={isMediaHtml ? false : true}
                initialDimensions={
                  isMediaHtml
                    ? { x: viewport.width / 1.5, y: viewport.height / 1.5 }
                    : undefined
                }
              >
                {
                  <FloatingWindow.Viewer>
                    <div ref={tableContentRef} className="w-full">
                      {media.html ? (
                        <Interweave
                          className="w-full"
                          content={media.html}
                          filters={[
                            tableFilter(breakPoint, fontSizeFactor, fontWeight),
                          ]}
                        />
                      ) : isMediaImage ? (
                        <img src={media.url ?? ""} />
                      ) : isMediaVideo ? (
                        <video controls>
                          <source src={media.url ?? ""} />
                        </video>
                      ) : (
                        <></>
                      )}
                    </div>
                  </FloatingWindow.Viewer>
                }
              </NbmeFloatingWindow>
            );
          })}
      </>
    );
  }
};

function handleDownload(dataUri?: string, name?: string, useProxy = true) {
  if (!dataUri) return;
  const imagePromise = fetch(
    (useProxy ? import.meta.env.VITE_CORS_PROXY_ENDPOINT : "") + dataUri,
  ).then((res) => res.blob());
  toast.promise(imagePromise, {
    loading: "Downloading file...",
    success: async (data) => {
      const url = await blobToDataUri(data);
      const a = document.createElement("a");
      a.href = url;
      a.download = name ?? "";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return `Successfully downloaded file!`;
    },
    error: "An error ocurred while downloading the file...",
  });
}
