import { useAppStore } from "@/store";
import { useNotebookDraft, useNoteFinder, useUnsavedChangesDialog } from ".";

export const useSetSelectedNoteId = () => {
  const {
    setIsVisible: setIsDialogVisible,
    setOnConfirm: setOnDialogConfirm,
    setOnCancel: setOnDialogCancel,
  } = useUnsavedChangesDialog();
  const { oldSelectedNoteId, internalSetSelectedNote, editNote } = useAppStore(
    (state) => ({
      oldSelectedNoteId: state.selectedNoteId,
      internalSetSelectedNote: state.setSelectedNoteId,
      editNote: state.editNote,
    }),
  );

  const [draft] = useNotebookDraft();
  const findNote = useNoteFinder();
  const oldSelectedNote = findNote(oldSelectedNoteId);

  const setSelectedNoteId = (noteId: string) => {
    if (!noteId) return internalSetSelectedNote("");
    const noteIsAlreadySelected = noteId === oldSelectedNoteId;
    if (noteIsAlreadySelected) return;

    const newSelectedNote = findNote(noteId);
    const selectedNoteDoesNotExist = !newSelectedNote;
    if (selectedNoteDoesNotExist) return;

    const oldSelectedNoteHadUnsavedChanges =
      oldSelectedNote && oldSelectedNote.html !== draft;

    if (oldSelectedNoteHadUnsavedChanges) {
      setIsDialogVisible(true);
      setOnDialogConfirm(() => {
        setIsDialogVisible(false);
        editNote({ id: oldSelectedNoteId, newContent: draft });
        internalSetSelectedNote(newSelectedNote.id);
      });
      setOnDialogCancel(() => {
        setIsDialogVisible(false);
        internalSetSelectedNote(newSelectedNote.id);
      });
      return;
    }

    internalSetSelectedNote(newSelectedNote.id);
  };

  return setSelectedNoteId;
};
