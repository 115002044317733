import { ArrayElement, Setter } from "@/types";
import { TableRow } from "@/types";
import { getPageCount } from "@/utils/common/getPageCount";
import { trpcProxyClient } from "@/utils/trpc";
import { toast } from "sonner";

import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { TestWithArticles } from "@/models";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { createJSONStorage, persist } from "zustand/middleware";
import { GetPreviousTestsOutput } from "@/api/src/server/routers";

interface PreviousTestsPageStore {
  tests: ArrayMap<TestWithArticles>;
  query: string;
  setQuery: (q: string) => void;
  row: TableRow<ArrayElement<GetPreviousTestsOutput["tests"]>>;
  setColumnVisible: (
    column: ArrayElement<this["row"]["columns"]>,
    visible: boolean,
  ) => void;
  showDeletedTests: boolean;
  setShowDeletedTests: Setter<boolean>;
  testsLoading: boolean;
  setTestsLoading: (value: boolean) => void;
  testsPerPage: 5 | 10 | 15 | 25 | 50 | 100;
  setTestsPerPage: (testsPerPage: this["testsPerPage"]) => void;
  pageCount: number;
  currentPage: number;
  setCurrentPage: (currentPage: this["currentPage"]) => void;
}

export const usePreviousTestsPageStore = create(
  persist(
    immer<PreviousTestsPageStore>((set) => ({
      row: {
        collapsible: false,
        columns: [
          {
            name: "Score",
            visible: true,
            content: (test) => ({
              score: 0,
              submitted: test.submitted,
              testId: test.id,
              isBeingEvaluated: test.isBeingEvaluated,
            }),
          },
          {
            name: "Name",
            visible: true,
            content: (test) => ({ name: test.name, id: test.id }),
          },
          { name: "Date", visible: true, content: (test) => test.startedAt },
          { name: "Mode", visible: true, content: (test) => test.mode },
          { name: "Q. Pool", visible: false, content: (test) => test.pool },
          {
            name: "Subjects",
            visible: true,
            content: (test) => test.subjects,
          },
          {
            name: "Systems",
            visible: true,
            content: (test) => {
              return test.systems;
            },
          },
          {
            name: "# Qs",
            visible: true,
            content: (test) => test.questions.length,
          },
          {
            name: "Actions",
            visible: true,
            content: (test) => ({
              id: test.id,
              submitted: test.submitted,
              name: test.name,
              // questionIds: test.slots.map((s) => s.question.uWolrdId),
            }),
          },
        ],
      },
      setColumnVisible: (column, visible) =>
        set((state) => {
          const targetColumn = state.row.columns.find(
            (c) => column.name === c.name,
          );
          if (!targetColumn) return;
          targetColumn.visible = visible;
        }),
      query: "",
      currentPage: 1,
      setCurrentPage: (currentPage) =>
        set((state) => {
          state.currentPage = currentPage;
        }),
      setQuery: (q) =>
        set((state) => {
          state.query = q;
        }),
      tests: {},
      pageCount: 1,
      testsLoading: false,
      setTestsLoading: (v) =>
        set((state) => {
          const testsArray = Object.keys(state.tests);
          state.testsLoading = v;
          state.pageCount = getPageCount(testsArray.length, state.testsPerPage);
        }),
      testsPerPage: 10,
      setTestsPerPage: (testsPerPage) =>
        set((state) => {
          state.testsPerPage = testsPerPage;
        }),
      showDeletedTests: false,
      setShowDeletedTests: (v) =>
        set((state) => {
          state.showDeletedTests = v;
        }),
    })),
    {
      name: "previous-tests-page",
      storage: createJSONStorage(() => localStorage),
      partialize(state) {
        return {
          currentPage: state.currentPage,
          testsPerPage: state.testsPerPage,
          row: state.row,
        };
      },
    },
  ),
);

export const usePreviousTestsPageCurrentPage = () => {
  const { currentPage, setCurrentPage } = usePreviousTestsPageStore(
    (state) => ({
      currentPage: state.currentPage,
      setCurrentPage: state.setCurrentPage,
    }),
  );
  return [currentPage, setCurrentPage] as const;
};

export const usePreviousTestsPageTestsPerPage = () => {
  const { testsPerPage, setTestsPerPage } = usePreviousTestsPageStore(
    (state) => ({
      testsPerPage: state.testsPerPage,
      setTestsPerPage: state.setTestsPerPage,
    }),
  );
  return [testsPerPage, setTestsPerPage] as const;
};

export const usePreviousTestsPageSearch = () => {
  const query = usePreviousTestsPageStore((state) => state.query);
  const setQuery = usePreviousTestsPageStore((state) => state.setQuery);
  return [query, setQuery] as const;
};

export const usePreviousTestsPageShowDeletedTests = () => {
  const showDeletedTests = usePreviousTestsPageStore(
    (state) => state.showDeletedTests,
  );
  const setShowDeletedTests = usePreviousTestsPageStore(
    (state) => state.setShowDeletedTests,
  );
  return [showDeletedTests, setShowDeletedTests] as const;
};
