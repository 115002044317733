import {
  faArrowLeft,
  faCheck,
  faCircle,
  faFlag,
  faPencil,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import _ from "underscore";

export interface NbmeSidebarProps {
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  currentSlot: number;
  onCurrentSlotChange: (v: number) => void;
  isTestSubmitted: boolean;
  isTestTutor: boolean;
  slots: {
    isOmitted: boolean;
    isCorrect: boolean;
    isSubmitted: boolean;
    isMarked: boolean;
    order: number;
    isNotedOn: boolean;
  }[];
}

export const NbmeSidebar = ({
  isVisible,
  onIsVisibleChange,
  currentSlot,
  onCurrentSlotChange,
  slots,
  isTestSubmitted,
  isTestTutor,
}: NbmeSidebarProps) => {
  return (
    <nav
      className={twMerge(
        "fixed top-0 left-0 lg:sticky lg:top-0 min-h-screen bg-white dark:bg-nbme-primary-dark-700 dark:text-white h-full flex flex-col justify-start items-center border border-r-gray-300 dark:border-nbme-primary-dark-600 z-[950] md:z-[950] lg:z-[900] overflow-y-auto lg:overflow-y-visible lg:w-max max-h-screen",
        !isVisible && "hidden lg:hidden translate-x-full",
      )}
    >
      <button
        type="button"
        className="text-center text-nbme-primary-500 text-lg mr-3 w-full lg:hidden"
        onClick={() => onIsVisibleChange(!isVisible)}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      {_.sortBy([...slots], (s) => s.order).map((slot, index) => {
        const {
          isCorrect,
          isOmitted,
          isMarked,
          isSubmitted,
          isNotedOn,
          order,
        } = slot;

        const slotIcon = isOmitted ? faCircle : isCorrect ? faCheck : faXmark;
        const isSelected = currentSlot === order;
        const isEven = index % 2 === 0;

        return (
          <button
            className={twMerge(
              "relative flex flex-row justify-center items-center w-full text-center pl-10 pr-14 py-0.5 text-sm",
              isEven && "bg-gray-200 dark:bg-nbme-primary-dark-800",
              isSelected &&
                "bg-nbme-primary-500 dark:bg-nbme-primary-dark-400 text-white",
            )}
            key={index}
            onClick={() => onCurrentSlotChange(order)}
          >
            <div className="absolute left-0 ml-4 flex flex-row justify-center items-center">
              {isSubmitted &&
                (isTestTutor || (!isTestTutor && isTestSubmitted)) && (
                  <FontAwesomeIcon
                    icon={slotIcon}
                    className={twMerge(
                      "w-4 h-4 opacity-0",
                      !isCorrect && "text-red-500",
                      isCorrect && "text-lime-500",
                      isOmitted && "text-blue-500",
                      isOmitted && isSelected && "text-white",
                      isSubmitted && "opacity-100",
                    )}
                  />
                )}
            </div>
            <p>{order + 1}</p>
            <div className="absolute right-0 mr-3 flex flex-row justify-center items-center gap-2">
              {
                <FontAwesomeIcon
                  icon={faFlag}
                  className={twMerge(
                    "w-3 h-3 text-red-500 invisible",
                    isMarked && "visible",
                  )}
                />
              }
              {
                <FontAwesomeIcon
                  icon={faPencil}
                  className={twMerge(
                    "w-3 h-3 text-amber-500 invisible",
                    isNotedOn && "visible",
                  )}
                />
              }
            </div>
          </button>
        );
      })}
    </nav>
  );
};
