import { FilterInterface } from "interweave";

export const linkFilter: FilterInterface = {
  node(name, node) {
    if (name === "a") {
      const isYoutubeVideo = node.getAttribute("href")?.includes("youtube");
      if (isYoutubeVideo) {
        return node;
      }
      node.setAttribute("href", "javascript:void(0)");
      node.className = "cursor-default";
    }
    return node;
  },
};
