import useArticleContent from "@/hooks/article/use-article-content";
import {
  useMedicalLibraryLibrarySearch,
  useMedicalLibraryPage,
  useMedicalLibraryRouter,
  useMedicalLibrarySearch,
  useMedicalLibrarySelectedHeader,
} from "../../store";
import GenericPage from "./generic-page";
import { FilterInterface, Interweave, TransformCallback } from "interweave";
import { Button } from "@/components/ui/button";
import ArticleIcon from "@/components/icons/hollow/article";
import { cn, range } from "@/lib/utils";
import { NbmeHint } from "@/components/NbmeHint";
import { NbmeHintTrigger } from "@/components/NbmeHintTrigger";
import Triggerable, { useTriggerableContext } from "@/components/triggerable";
import { createPortal } from "react-dom";
import { Skeleton } from "@/components/ui/skeleton";
import {
  NbmeContextMenu,
  useTriggerNbmeContextMenu,
} from "@/ncomponents/nbme/context-menu";
import { analyzeSelection, clamp, scaleUnitInRem } from "@/utils";
import gen from "@/lib/gen";
import combineInterweaveTransformers from "@/lib/utils/combine-interweave-transformers";
import { highlightTransformer } from "@/utils/interweave/transformers";
import useHighlightedHtml from "@/hooks/common/use-highlighted-html";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCirclePlay,
  faImage,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { FontSizes, PropsWithAs } from "@coursology/types";
import useIntersectionObserver from "@/hooks/common/use-intersection-observer";
import useSearchedHtml from "@/hooks/common/use-searched-html";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import {
  useNbmeFontSizeFactor,
  useNbmeFontWeight,
} from "@/utils/stores/nbmeLayoutStore";

const ArticlePage = () => {
  const [selectedArticle] = useMedicalLibraryPage();
  const { isLoading: isHighlightMarkersLoading } = useHighlightMarkers();
  const {
    data: article,
    isLoading: isArticleLoading,
    isPlaceholderData,
  } = useArticleContent(
    {
      articleId: selectedArticle ?? "",
    },
    {
      enabled: selectedArticle !== "bookmarks" && selectedArticle !== "search",
    },
  );
  const isLoading = isHighlightMarkersLoading || isArticleLoading;
  const hasRelatedArticles = (article?.relatedArticles.length ?? 0) !== 0;

  return (
    <GenericPage
      className={cn(isPlaceholderData && "opacity-50")}
      header={
        isLoading ? (
          <ArticleTitleSkeleton />
        ) : (
          <ArticleTitle
            title={article?.title ?? ""}
            subjects={article?.subjects ?? []}
          />
        )
      }
      footer={
        hasRelatedArticles ? (
          <RelatedArticles articles={article?.relatedArticles ?? []} />
        ) : undefined
      }
    >
      {isLoading && <ArticleSkeleton />}
      {!isLoading && article && <ArticleContent articleId={article.id} />}
    </GenericPage>
  );
};

export default ArticlePage;

const ArticleTitleSkeleton = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-y-4">
      <Skeleton className="w-72 h-8" />
      <div className="flex flex-row justify-start items-center gap-x-3">
        {range(3).map(() => {
          return <CategoryBadgeSkeleton />;
        })}
      </div>
    </div>
  );
};

const CategoryBadgeSkeleton = () => {
  return (
    <Skeleton className="flex flex-col justify-center items-center rounded-full bg-gray-100 h-7 w-12 text-xs border border-gray-300 text-gray-600 capitalize" />
  );
};

const ArticleTitle = ({
  title,
  subjects,
}: {
  title: string;
  subjects: { name: string }[];
}) => {
  return (
    <div className="flex flex-col justify-start items-start gap-y-4">
      <Interweave className="capitalize text-2xl font-medium" content={title} />
      <div className="flex flex-row justify-start items-center gap-x-3">
        {subjects.map((c) => {
          return <SubjectBadge categoryName={c.name} key={c.name} />;
        })}
      </div>
    </div>
  );
};

const SubjectBadge = ({ categoryName }: { categoryName: string }) => {
  return (
    <div className="flex flex-col justify-center items-center rounded-full bg-gray-100 dark:bg-neutral-100 dark:text-black warm:bg-brown-50 py-2 px-3 text-xs border border-gray-300 dark:border-neutral-300 warm:border-brown-200 text-gray-600 capitalize">
      {categoryName}
    </div>
  );
};

const RelatedArticle = ({
  articleId,
  articleName,
}: {
  articleId: string;
  articleName: string;
}) => {
  const router = useMedicalLibraryRouter();
  return (
    <Button
      variant={"ghost"}
      className="flex flex-row justify-start items-center gap-x-3 text-primary-500 hover:text-primary-600"
      onClick={() => router.push(articleId)}
    >
      <ArticleIcon />
      <span className="capitalize">{articleName}</span>
    </Button>
  );
};

const RelatedArticles = ({
  articles,
}: {
  articles: { id: string; name: string }[];
}) => {
  return (
    <div className="flex flex-col justify-start items-start gap-y-3">
      <h3 className="font-medium text-lg">RELATED ARTICLES</h3>
      <div className="flex flex-col justify-start items-start gap-y-2">
        {articles.map((article) => (
          <RelatedArticle articleId={article.id} articleName={article.name} />
        ))}
      </div>
    </div>
  );
};

const ArticleSkeleton = () => {
  return (
    <div className="flex flex-col justify-start items-start w-full gap-y-3">
      {range(5).map(() => {
        return <Skeleton className="w-full h-9" />;
      })}
    </div>
  );
};

const ArticleContent = ({ articleId }: { articleId: string }) => {
  const [search, setSearch] = useMedicalLibrarySearch();
  const { data: article } = useArticleContent({ articleId });
  const trigger = useTriggerNbmeContextMenu();
  const highlightedArticle = useHighlightedHtml({
    html: article?.content ?? "",
    highlights: article?.usage.highlights ?? [],
  });
  const { searchedHtml: searchedArticle, totalMatches } = useSearchedHtml({
    html: highlightedArticle,
    searchValue: search.query,
    targetMatchIndex: search.targetMatch,
    caseSensitive: search.isMatchCaseSensitive,
    isEnabled: search.isEnabled,
  });
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const [fontWeight] = useNbmeFontWeight();
  useEffect(() => {
    setSearch({ ...search, totalMatches });
  }, [totalMatches]);
  if (!article) return;
  return (
    <div
      onMouseUp={() => handleHighlight()}
      onTouchEnd={(e) => handleHighlight(e)}
      id="article-content"
      style={{
        fontSize: scaleUnitInRem(FontSizes.Base, fontSizeFactor)
          .resultInRemAsCss,
        fontWeight,
      }}
    >
      <Interweave
        content={searchedArticle}
        filters={[articleFilter]}
        transform={combineInterweaveTransformers(
          articleHintTransformer,
          articleIconTransformer,
          articleHeaderTransformer,
          articleSearchTransformer,
          highlightTransformer,
        )}
      />
      {createPortal(<NbmeContextMenu />, document.body)}
    </div>
  );

  function handleHighlight(touchEvent?: React.TouchEvent) {
    const windowSelection = window.getSelection();
    const analysis = analyzeSelection(windowSelection);
    if (!analysis) return;
    if (!analysis.selectionText) return;
    if (analysis.selectionText === " " || analysis.selectionText.length === 0) {
      return;
    }
    //If the selection is one character long we ignore it...
    //just to be on the safe side....
    if (analysis.selectionText.length === 1) return;

    const newHighlight = {
      id: gen.cuid(),
      text: analysis.selectionText!,
      ocurrence: analysis.ocurrence,
      questionId: null,
      questionExplanationId: null,
      articleId,
      start: analysis.startIndex,
      end: analysis.endIndex,
    };

    trigger(
      [
        {
          type: "create-highlight",
          newHighlight,
          onNewHighlight: () => {
            windowSelection?.removeAllRanges();
          },
        },
        { type: "copy-to-clipboard", textToCopy: analysis.selectionText },
      ],
      touchEvent
        ? {
            position: {
              x: touchEvent.changedTouches[0].clientX,
              y: touchEvent.changedTouches[0].clientY,
            },
          }
        : undefined,
    );
  }
};

const articleFilter: FilterInterface = {
  node(name, node) {
    if (name === "div") {
      node.setAttribute("style", "");
    }
    if (name === "p") {
      //No immediate parent
      if (node.parentElement?.tagName === "DIV") node.className = "mb-7";
      if (node.nextElementSibling?.tagName === "UL") node.className = "";
      node.classList.add("warm:text-brown-800");
    } else if (name === "ol") {
      node.className = "ms-10 mb-4 list-decimal";
    } else if (name === "ul") {
      node.className = "ms-10 mb-4 list-disc";
    }
    return node;
  },
};

const articleHintTransformer: TransformCallback = (node, children) => {
  const hintId = node.getAttribute("data-hint-id");
  const isHint = node.tagName === "A" && hintId;
  const hintText = node.textContent ?? "";
  if (!isHint) return;
  return (
    <Hint hintId={hintId} hintText={hintText}>
      {children}
    </Hint>
  );
};

const articleIconTransformer: TransformCallback = (node) => {
  const isIcon = node.classList.contains("article-icon");
  const className = "me-1";
  if (!isIcon) return;
  if (node.classList.contains("article-icon-figure")) {
    return (
      <span className={className}>
        <FontAwesomeIcon icon={faChartLine} />
      </span>
    );
  } else if (node.classList.contains("article-icon-table")) {
    return (
      <span className={className}>
        <FontAwesomeIcon icon={faTable} />
      </span>
    );
  } else if (node.classList.contains("article-icon-image")) {
    return (
      <span className={className}>
        <FontAwesomeIcon icon={faImage} />
      </span>
    );
  } else if (node.classList.contains("article-icon-video")) {
    return (
      <span className={className}>
        <FontAwesomeIcon icon={faCirclePlay} />
      </span>
    );
  }
};

const articleHeaderTransformer: TransformCallback = (node, children) => {
  const isHeader = node.tagName.startsWith("H");
  if (!isHeader) return;
  return (
    <ArticleHeader
      id={node.getAttribute("id") ?? ""}
      as={node.tagName.toLowerCase() as "h1" | "h2" | "h3" | "h4" | "h5" | "h6"}
    >
      {children}
    </ArticleHeader>
  );
};

const articleSearchTransformer: TransformCallback = (node, children) => {
  const isCurrentSearch = node.getAttribute("data-is-current-match");
  if (!isCurrentSearch) return;
  return <CurrentMatch>{children}</CurrentMatch>;
};

const CurrentMatch = ({ children }: PropsWithChildren) => {
  const ref = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  return (
    <span className="bg-amber-500 dark:text-black" ref={ref}>
      {children}
    </span>
  );
};

const ArticleHeader = ({
  id,
  as,
  children,
}: {
  id: string;
} & PropsWithAs &
  PropsWithChildren) => {
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const [fontWeight] = useNbmeFontWeight();
  const [, setSelectedHeader] = useMedicalLibrarySelectedHeader();
  const { ref } = useIntersectionObserver({
    threshold: 0,
    rootMargin: "0px 0px -85% 0px", // Ensures it triggers when it touches the top
    onChange: (isIntersecting) => {
      if (isIntersecting) {
        setSelectedHeader(id);
      }
    },
  });
  const Parent = as ?? "h1";
  const className =
    as === "h1"
      ? "text-primary-600 dark:text-white warm:text-brown-800 font-semibold text-xl uppercase my-3"
      : "text-primary-600 dark:text-neutral-50 warm:text-brown-700 font-medium text-lg capitalize my-1";
  return (
    <Parent
      id={id}
      className={className}
      style={{
        fontSize: scaleUnitInRem(
          as === "h1" ? FontSizes.ExtraLarge : FontSizes.Large,
          fontSizeFactor,
        ).resultInRemAsCss,
        fontWeight: clamp({
          num: fontWeight,
          min: as === "h1" ? 600 : 500,
          max: 900,
        }),
      }}
      ref={ref}
    >
      {children}
    </Parent>
  );
};

const Hint = ({
  hintId,
  hintText,
  children,
}: { hintId: string; hintText: string } & PropsWithChildren) => {
  return (
    <Triggerable.Root as={"span"}>
      <Triggerable.Toggle as={"span"}>
        <NbmeHintTrigger hint={{ id: hintId, text: hintText }}>
          {children}
        </NbmeHintTrigger>
      </Triggerable.Toggle>
      <Triggerable.Body className="fixed">
        <HintContent hintId={hintId} hintText={hintText} />
      </Triggerable.Body>
    </Triggerable.Root>
  );
};

const HintContent = ({
  hintId,
  hintText,
}: {
  hintId: string;
  hintText: string;
}) => {
  const { isOpen, setIsOpen } = useTriggerableContext();
  return (
    <>
      {createPortal(
        <NbmeHint
          hint={{
            videoId: null,
            imageId: null,
            id: hintId,
            text: hintText.trim(),
            html: "",
            medias: [""],
          }}
          visible={isOpen}
          onCloseClicked={() => setIsOpen(!isOpen)}
        />,

        document.body,
      )}
    </>
  );
};
