"use client"
import { useForm } from "@tanstack/react-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { trpc } from "@/utils/trpc"
import { useNavigate } from "@tanstack/react-router"


export default function CreatePromoForm() {
  const navigate = useNavigate();
  const { mutate: createPromoCode, isPending } = trpc.promoCode.createPromoCode.useMutation();
  const utils = trpc.useUtils();

  const form = useForm({
    defaultValues: {
      code: "",
      usersLimit: "",
      extraDays: "",
    },
    onSubmit: ({ value: values }) => {
      createPromoCode({
        code: values.code,
        extraDays: Number(values.extraDays),
        limit: values.usersLimit ? Number(values.usersLimit) : null,
      }, {
        onSettled: () => {
          utils.promoCode.getPromocodes.invalidate().then(() => {
            form.reset();
            navigate({ to: "/admin/promocodes" })
          });
        }
      })
    },
    validatorAdapter: zodValidator(),
  })

  return (
    <div className="dark min-h-screen">
      <Card className="mx-auto max-w-md dark:bg-neutral-900 dark:border-neutral-500">
        <CardHeader className="dark:text-neutral-100">
          <CardTitle>Create Promocode</CardTitle>
          <CardDescription className="dark:text-neutral-400">
            Add a new promocode for subscription extensions.
          </CardDescription>
        </CardHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
        >
          <CardContent className="space-y-4">
            <form.Field
              name="code"
              validators={{
                onChange: z.string().trim().regex(/^([a-zA-Z0-9]+)$/, "Promocode must not contain spaces or special characters").min(3, "Promocode must be at least 3 characters."),
              }}
            >
              {(field) => (
                <div className="space-y-2">
                  <label className="text-sm font-medium text-neutral-100">Promocode</label>
                  <Input
                    placeholder="Enter promocode"
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    className="dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100"
                  />
                  <p className="text-sm dark:text-neutral-400">The code users will enter to claim the promotion.</p>
                  {field.state.meta.errors ? (
                    <p className="text-sm text-red-400">{field.state.meta.errors.join(", ")}</p>
                  ) : null}
                </div>
              )}
            </form.Field>

            <form.Field
              name="usersLimit"
              validators={{
                onChange: z.string().optional(),
              }}
            >
              {(field) => (
                <div className="space-y-2">
                  <label className="text-sm font-medium text-neutral-100">Users Limit (Optional)</label>
                  <Input
                    type="number"
                    placeholder="Enter users limit"
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    className="dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100"
                  />
                  <p className="text-sm text-neutral-400">Maximum number of users who can use this code.</p>
                  {field.state.meta.errors ? (
                    <p className="text-sm text-red-400">{field.state.meta.errors.join(", ")}</p>
                  ) : null}
                </div>
              )}
            </form.Field>

            <form.Field
              name="extraDays"
              validators={{
                onChange: z.string().min(1, "Extra days is required."),
              }}
            >
              {(field) => (
                <div className="space-y-2">
                  <label className="text-sm font-medium dark:text-neutral-100">Extra Days</label>
                  <Input
                    type="number"
                    placeholder="Enter number of days"
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    className="dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100"
                  />
                  <p className="text-sm dark:text-neutral-400">
                    Number of days to add to the user&apos;s subscription on code use.
                  </p>
                  {field.state.meta.errors ? (
                    <p className="text-sm text-red-400">{field.state.meta.errors.join(", ")}</p>
                  ) : null}
                </div>
              )}
            </form.Field>
          </CardContent>
          <CardFooter>
            <Button disabled={isPending} type="submit" className="w-full dark:bg-neutral-100 dark:text-neutral-900 dark:hover:bg-neutral-200">
              Create Promocode
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  )
}

