import { twMerge } from "tailwind-merge";
import RadioGroupItem from "./RadioGroupItem";
import { Label } from "./Label";

interface RadioGroupItemWithLabelProps {
  id: string;
  label: string;
  value: string;
  className?: string;
  itemClassName?: string;
  labelClassName?: string;
  labelStyle?: React.CSSProperties;
  onSelect: (value: string) => void;
  isSelected: boolean;
  disabled?: boolean;
}

export const RadioGroupItemWithLabel = ({
  className,
  value,
  id,
  itemClassName,
  label,
  labelClassName,
  labelStyle,
  onSelect,
  isSelected,
  disabled,
}: RadioGroupItemWithLabelProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-row justify-start items-center gap-3",
        className,
      )}
    >
      <RadioGroupItem
        disabled={disabled}
        id={id}
        value={value}
        className={itemClassName}
        onSelect={onSelect}
        isSelected={isSelected}
      />
      <Label htmlFor={id} className={labelClassName} style={labelStyle}>
        {label}
      </Label>
    </div>
  );
};
