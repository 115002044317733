"use client";
import { faUser, faUserGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button } from "./Button";
import { useRequiresUser } from "@/utils/customHooks";
import { CoursologyLogo } from "./CoursologyLogo";
import { DarkModeSwitch } from "./DarkModeSwitch";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";
import { Link } from "@tanstack/react-router";

const HomeNavbar = () => {
  const user = useRequiresUser();
  const [isClient, setIsClient] = useState(false);
  const { signOut } = useAuthSlice();

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <nav className="w-full sticky top-0 bg-primary-500 warm:bg-brown-800 dark:bg-neutral-900 border-b border-b-primary-600 dark:border-b-neutral-800 flex flex-row justify-center md:justify-between items-center p-3 transition-colors z-50">
      <CoursologyLogo className="w-[6.25rem]" variant="mono" />
      <div className="flex flex-row justify-center items-center gap-3 ">
        {isClient && (
          <DarkModeSwitch className="warm:text-white ![&>button]:hover:text-white" />
        )}
        {isClient && user && (
          <>
            <Button
              className="bg-white text-primary-500 w-auto text-sm md:text-sm lg:text-sm lg:hover:bg-white lg:hover:text-primary-500 warm:text-white md:hover:bg-white md:hover:text-primary-500 hover:bg-white hover:text-primary-500"
              onClick={signOut}
            >
              Sign Out
            </Button>
            <div className="font-bold text-white">
              {user.name.split(" ").slice(0, 2).join(" ")}
            </div>
            <Link
              to="/account"
              className="bg-white rounded-full h-10 w-10 flex flex-row justify-center items-center "
            >
              <FontAwesomeIcon
                icon={faUserGear}
                className="text-primary-500 warm:text-brown-700 dark:text-neutral-700"
              />
            </Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default HomeNavbar;
