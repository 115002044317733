import { Setter } from "@coursology/types";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface NotesPageStore {
  search: string;
  setSearch: Setter<this["search"]>;
  sortBy: "topic" | "subject" | "system" | "questionId";
  setSortBy: Setter<this["sortBy"]>;
  currentPage: number;
  setCurrentPage: Setter<this["currentPage"]>;
  notesPerPage: 5 | 10 | 15 | 25 | 50 | 100;
  setNotesPerPage: Setter<this["notesPerPage"]>;
}

export const useNotesPageStore = create<NotesPageStore>()(
  immer((set) => ({
    search: "",
    setSearch: (search) =>
      set((state) => {
        state.search = search;
      }),
    sortBy: "questionId",
    setSortBy: (sortBy) =>
      set((state) => {
        state.sortBy = sortBy;
      }),
    currentPage: 1,
    setCurrentPage: (currentPage) =>
      set((state) => {
        state.currentPage = currentPage;
      }),
    notesPerPage: 10,
    setNotesPerPage: (notesPerPage) =>
      set((state) => {
        state.notesPerPage = notesPerPage;
      }),
  })),
);

export const useNotesPageCurrentPage = () => {
  const currentPage = useNotesPageStore((state) => state.currentPage);
  const setCurrentPage = useNotesPageStore((state) => state.setCurrentPage);
  return [currentPage, setCurrentPage] as const;
};

export const useNotesPageNotesPerPage = () => {
  const notesPerPage = useNotesPageStore((state) => state.notesPerPage);
  const setNotesPerPage = useNotesPageStore((state) => state.setNotesPerPage);
  return [notesPerPage, setNotesPerPage] as const;
};

export const useNotesPageSortBy = () => {
  const sortBy = useNotesPageStore((state) => state.sortBy);
  const setSortBy = useNotesPageStore((state) => state.setSortBy);
  return [sortBy, setSortBy] as const;
};

export const useNotesPageSearch = () => {
  const search = useNotesPageStore((state) => state.search);
  const setSearch = useNotesPageStore((state) => state.setSearch);
  return [search, setSearch] as const;
};
