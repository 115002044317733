import { trpc } from "@/utils/trpc";
import useMutatePreviousTestsQueryCache from "../utility/use-mutate-previous-tests-query-cache";
import useMutateOverallStatisticsPageQueryCache from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.performance/overall/-hooks/use-mutate-overallstatistics-page-query-cache";
import { current } from "immer";
import { GetPreviousTestsOutput } from "@/api/src/server/routers";
import { toast } from "sonner";

const useRecoverTest = () => {
  const mutatePreviousTests = useMutatePreviousTestsQueryCache()();
  const mutateOverallStatistics = useMutateOverallStatisticsPageQueryCache();
  const utils = trpc.useUtils();

  return trpc.test.recoverTest.useMutation({
    onMutate: ({ recoverQuestionStates, testId }) => {
      let targetTest: GetPreviousTestsOutput["tests"][number];
      const rollbackPreviousTests = mutatePreviousTests((state) => {
        const targetTestIndex = state.tests.findIndex((t) => t.id === testId);
        if (targetTestIndex !== -1) {
          targetTest = current(state.tests[targetTestIndex]);
          state.tests[targetTestIndex].deletedAt = null;
        }
      });
      const rollbackOverallStatistics = mutateOverallStatistics((state) => {
        state.tests.total += 1;
        if (targetTest && targetTest.submitted) {
          state.tests.completed += 1;
        }
        if (targetTest && targetTest.suspended) {
          state.tests.suspended += 1;
        }
        if (recoverQuestionStates) {
          const testResult = utils.statistics.getTestResult.getData({ testId });
          if (testResult) {
            state.questions.used += testResult.total;
            state.questions.unused -= testResult.total;
            state.questions.correct += testResult.correct;
            state.questions.correct += testResult.correct;
            state.questions.incorrect += testResult.incorrect;
            state.questions.omitted += testResult.omitted;
          }
        }
      });
      return {
        rollback: () => {
          rollbackPreviousTests((state) => {
            if (!targetTest) return;
            const targetTestIndex = state.tests.findIndex(
              (t) => targetTest.id === t.id,
            );
            if (targetTestIndex !== -1) {
              state.tests[targetTestIndex].deletedAt = targetTest.deletedAt;
            }
          });
          rollbackOverallStatistics();
        },
      };
    },
    onSuccess: () => {
      utils.statistics.getQuestionBankOverallStatistics.invalidate();
      utils.statistics.getQuestionBankPerformanceReports.invalidate();
    },
    onError: (err, _, ctx) => {
      toast.error(
        `Could not recover test..., an error ocurred: ${err.message}`,
      );
      ctx?.rollback?.();
    },
  });
};

export default useRecoverTest;
