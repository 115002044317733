import { PropsWithClassName } from "@coursology/types";
import { Body, Dialogs, Layout, Sidebar } from "./components";

interface NotebookProps extends PropsWithClassName {


  /**The className of the root layout of the notebook */
  className?: string;
};


export const Notebook = ({ className }: NotebookProps) => {
  return (
    <Layout className={className}>
      <Sidebar />
      <Body />
      <Dialogs />
    </Layout>
  );
};
