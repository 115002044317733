import { trpc } from "@/utils/trpc";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import { toast } from "sonner";
import useMutateArticleContentQueryCache from "../utility/use-mutate-article-content-query-cache";

const useCreateHighlight = () => {
  const mutateTestSlotCache = useMutateTestSlotQueryCache()();
  const mutateArticleContentCache = useMutateArticleContentQueryCache()();
  return trpc.highlight.createHighlight.useMutation({
    onMutate: ({ highlight }) => {
      const rollbackTestSlotCache = mutateTestSlotCache((state) => {
        if (highlight.questionId === state.question.id)
          state.question.highlights.push(highlight);
        if (highlight.questionExplanationId === state.question.explanation.id)
          state.question.explanation.highlights.push(highlight);
      });
      const rollbackArticleContentCache = mutateArticleContentCache((state) => {
        if (state.id === highlight.articleId) {
          state.usage.highlights.push(highlight);
        }
      });
      return {
        rollback: () => {
          rollbackTestSlotCache((state) => {
            state.question.highlights = state.question.highlights.filter(
              (h) => h.id !== highlight.id,
            );
            state.question.explanation.highlights =
              state.question.explanation.highlights.filter(
                (h) => h.id !== highlight.id,
              );
          });

          rollbackArticleContentCache();
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not create highlight, an error ocurred...,${error.message}`,
      );
      context?.rollback();
    },
  });
};
export default useCreateHighlight;
