import {
  BookmarkIcon as HollowBookmarkIcon,
  NotebookIcon,
  SearchIcon,
} from "@/components/icons/hollow";
import { BookmarkIcon } from "@/components/icons";
import { ArticleSearchIcon } from "@/components/icons/article-search";
import { Button } from "@/components/ui/button";
import { ComponentProps, PropsWithChildren, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { MessageSquareIcon, ZapIcon } from "lucide-react";
import { BlurInOut } from "@/ncomponents/utilities/animations/blur-in-out";
import {
  useMedicalLibraryLocation,
  useMedicalLibraryPage,
  useMedicalLibrarySearch,
  useMedicalLibrarySidebarIsOpen,
  useMedicalLibraryVariant,
} from "../store";
import useBookmarkArticle from "@/hooks/library/use-bookmark-article";
import useArticleContent from "@/hooks/article/use-article-content";
import { cn } from "@/lib/utils";
import { useNotebookUiVariant } from "@/hooks/notebook";
import { Checkbox } from "@/components/ui/checkbox";
import Text from "@/components/ui/text";
import { Label } from "@/components/ui/label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faFolderTree,
} from "@fortawesome/free-solid-svg-icons";
import { MobileDrawer, Notebook } from "@/components/common";
import { Setter } from "@coursology/types";
import NbmeSidePanel from "@/components/nbme-side-panel";
import { createPortal } from "react-dom";
import { NbmeFloatingWindow } from "@/components/NbmeFloatingWindow";
import { toast } from "sonner";
import LabeledCheckbox from "@/components/LabeledCheckbox";

const Footer = () => {
  const [isOpen, setIsOpen] = useMedicalLibrarySidebarIsOpen();
  const [location] = useMedicalLibraryLocation();
  const [variant] = useMedicalLibraryVariant();
  const [page] = useMedicalLibraryPage();
  const [search, setSearch] = useMedicalLibrarySearch();
  const isOnTestPage = location === "test";
  const isOnMobile = variant === "mobile";
  const isOnArticle = page !== "bookmarks" && page !== "search";

  return (
    <div className="w-full flex flex-col justify-start items-center">
      {isOnArticle && search.isEnabled && <SearchBar />}
      <div
        className={cn(
          "py-2 px-1 border-t border-t-gray-300 dark:border-t-neutral-700 warm:border-t-brown-300 w-full flex flex-row justify-center items-center",
          (isOnTestPage || isOnMobile) && "px-8",
        )}
      >
        <div
          className={cn(
            "w-1/2 flex flex-row justify-between items-center",
            (isOnTestPage || isOnMobile) && "w-full",
          )}
        >
          {isOnMobile && (
            <FooterButton popover="Articles" onClick={() => setIsOpen(!isOpen)}>
              <FontAwesomeIcon icon={faFolderTree} className="w-5 h-5" />
            </FooterButton>
          )}
          <NotebookButton />
          <FooterButton popover="Flashcards (Coming Soon)" disabled>
            <ZapIcon />
          </FooterButton>
          <FeedbackButton />
          {isOnArticle && <BookmarkButton />}
          {isOnArticle && (
            <FooterButton
              popover="Search Article"
              onClick={() =>
                setSearch({ ...search, isEnabled: !search.isEnabled })
              }
            >
              <ArticleSearchIcon />
            </FooterButton>
          )}
        </div>
      </div>
    </div>
  );
};

const SearchBar = () => {
  const [variant] = useMedicalLibraryVariant();
  const [location] = useMedicalLibraryLocation();
  const [search, setSearch] = useMedicalLibrarySearch();
  const isOnMobile = variant === "mobile";
  const isOnTestPage = location === "test";
  return (
    <div
      className={cn(
        "w-full flex flex-row justify-between items-center border-t border-t-gray-300 dark:border-t-neutral-700 warm:border-t-brown-300 bg-gray-100 dark:bg-neutral-900 warm:bg-brown-100/60 py-3 px-12",
        (isOnTestPage || isOnMobile) &&
          "flex-col-reverse gap-y-3 px-5 w-full justify-center",
      )}
    >
      <div
        className={cn(
          "flex flex-row justify-start items-center gap-x-4 flex-grow",
          (isOnTestPage || isOnMobile) && "justify-between w-full",
        )}
      >
        <SearchInput
          value={search.query}
          onChange={(e) => setSearch({ ...search, query: e.target.value })}
          onSubmit={() =>
            setSearch({ ...search, targetMatch: search.targetMatch + 1 })
          }
        />
        <MatchCaseCheckbox
          checked={search.isMatchCaseSensitive}
          onCheckedChange={(v) =>
            setSearch({ ...search, isMatchCaseSensitive: v as boolean })
          }
        />
      </div>
      <MatchSwitcher
        totalMatches={search.totalMatches}
        targetMatch={search.targetMatch}
        onTargetMatchChange={(targetMatch) =>
          setSearch({ ...search, targetMatch })
        }
      />
    </div>
  );
};
const SearchInput = ({
  onSubmit,
  ...props
}: { onSubmit } & ComponentProps<"input">) => {
  const [location] = useMedicalLibraryLocation();
  const [variant] = useMedicalLibraryVariant();
  const isOnMobile = variant === "mobile";
  const isOnTestPage = location === "test";
  return (
    <div
      className={cn(
        "border border-gray-300 warm:border-brown-300 dark:border-neutral-700 rounded-full px-4 py-1.5 flex flex-row justify-start items-start gap-x-4 bg-white dark:bg-neutral-950 max-w-[40%] flex-grow",
        (isOnTestPage || isOnMobile) && "max-w-full",
      )}
    >
      <Text>
        <SearchIcon />
      </Text>
      <form
        className="flex-grow"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <input
          {...props}
          placeholder="Search this article"
          className="outline-none bg-transparent text-gray-800 dark:text-neutral-300 warm:text-brown-800 min-w-0 placehoder:truncate w-full"
        />
      </form>
    </div>
  );
};

const MatchCaseCheckbox = ({ ...props }: ComponentProps<typeof Checkbox>) => {
  return (
    <div className="flex flex-row justify-start items-center gap-x-3">
      <Checkbox id="library-match-case" {...props} />
      <Label htmlFor="library-match-case">Match Case</Label>
    </div>
  );
};

const MatchSwitcher = ({
  totalMatches,
  targetMatch,
  onTargetMatchChange,
}: {
  totalMatches: number;
  targetMatch: number;
  onTargetMatchChange: (newTargetMatch: number) => void;
}) => {
  return (
    <div className="flex flex-row justify-start items-center">
      <button
        className="me-3 text-gray-800 dark:text-neutral-300 warm:text-brown-800"
        onClick={() => onTargetMatchChange(targetMatch - 1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <Text className="w-20 text-center">
        {totalMatches ? targetMatch + 1 : 0} of {totalMatches}
      </Text>
      <button
        className="ms-3 text-gray-800 dark:text-neutral-300 warm:text-brown-800"
        onClick={() => onTargetMatchChange(targetMatch + 1)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

const FooterButton = ({
  popover,
  children,
  className,
  ...props
}: ComponentProps<typeof Button> & { popover: string }) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger>
          <Button
            {...props}
            variant={"ghost"}
            className={cn(
              "text-gray-500 dark:text-neutral-300 warm:text-brown-600 hover:scale-110 active:scale-100 transition-transform disabled:text-gray-500 disabled:hover:scale-100 disabled:active:scale-100 disabled:dark:text-neutral-400 disabled:warm:text-brown-400 disabled:cursor-not-allowed",
              className,
            )}
          >
            {children}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{popover}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const BookmarkButton = () => {
  const [selectedArticle] = useMedicalLibraryPage();
  const { data: article } = useArticleContent(
    {
      articleId: selectedArticle ?? "",
    },
    {
      enabled: selectedArticle !== "bookmarks" && selectedArticle !== "search",
    },
  );
  const { mutate: bookmark } = useBookmarkArticle();
  const isBookmarked = !!article?.usage.bookmarkedAt;
  return (
    <FooterButton
      popover="Bookmark"
      onClick={() => bookmark({ articleId: selectedArticle ?? "" })}
    >
      {isBookmarked ? (
        <BookmarkIcon className="text-primary-500 warm:text-brown-700 dark:text-white w-6 h-6 scale-90" />
      ) : (
        <HollowBookmarkIcon />
      )}
    </FooterButton>
  );
};

const FeedbackButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <FooterButton popover="Feedback" onClick={() => setOpen(!open)}>
        <MessageSquareIcon />
      </FooterButton>
      {createPortal(
        <FeedbackWindow open={open} onOpenChange={setOpen} />,

        document.body,
      )}
    </>
  );
};

const FeedbackWindow = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: Setter<boolean>;
}) => {
  const [draft, setDraft] = useState<string>("");
  const [technical, setTechnical] = useState<boolean>(false);

  const giveFeedback = () => undefined;

  return (
    <NbmeFloatingWindow
      visible={open}
      title={"Submit Feedback"}
      maximizable={false}
      className="lg:min-w-[30rem] md:min-w-[25rem] min-w-[20rem]"
      resizable={false}
      draggableFromBody={false}
      viewportClassName="dark:bg-nbme-primary-dark-800"
      onCloseClicked={() => {
        onOpenChange(false);
        setDraft("");
      }}
    >
      <form
        onSubmit={() => {
          if (!draft) {
            onOpenChange(false);
            return;
          }
          giveFeedback();
          toast.success("Thank you for your feedback!");
          onOpenChange(false);
        }}
        className="flex flex-col justify-start items-center w-full p-3 gap-3"
      >
        <textarea
          value={draft}
          onChange={(e) => setDraft(e.target.value)}
          placeholder="Your feedback matters to us! Make sure to avoid simple feedback as 'good question', or 'bad question'."
          className="w-full border border-gray-300 warm:placeholder:text-brown-300 rounded-primary px-2 py-1 min-h-[10rem] placeholder:text-sm lg:placeholder:text-base bg-white warm:bg-white dark:bg-nbme-primary-dark-600 dark:text-white dark:placeholder:text-nbme-primary-dark-300 dark:border-nbme-primary-dark-600 dark:outline-nbme-primary-dark-600"
        ></textarea>
        <div className="flex flex-row justify-between items-center w-full gap-5">
          <LabeledCheckbox
            className="w-full"
            checkBoxClassName="dark:bg-nbme-primary-dark-600 dark:border-nbme-primary-dark-600"
            labelClassName="text-xs w-full warm:text-brown-700"
            id="technical-checkbox"
            label="Check here if you are submitting feedback regarding a technical issue."
            checked={technical}
            onClick={() => setTechnical(!technical)}
          />
          <Button
            type="submit"
            className="p-1 text-sm md:text-sm lg:text-sm bg-nbme-primary-600 hover:bg-nbme-primary-700 focus:bg-nbme-primary-700 w-[20%] dark:bg-nbme-primary-dark-700 dark:hover:bg-nbme-primary-dark-600 warm:bg-brown-800 hover:warm:bg-brown-900"
          >
            Submit
          </Button>
        </div>
      </form>
    </NbmeFloatingWindow>
  );
};

const NotebookButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <FooterButton popover="Notebook" onClick={() => setOpen(!open)}>
        <NotebookIcon />
      </FooterButton>
      <NotebookWindow isVisible={open} setIsVisible={setOpen} />
    </>
  );
};

const NotebookWindow = ({
  isVisible,
  setIsVisible,
}: {
  isVisible: boolean;
  setIsVisible: Setter<boolean>;
}) => {
  const [variant] = useNotebookUiVariant();
  const onDesktop = variant === "desktop";
  const Parent = onDesktop ? SidePanel : MobileDrawer;

  return (
    <Parent open={isVisible} onOpenChange={setIsVisible}>
      <Notebook className={cn(onDesktop && "min-h-screen")} />
    </Parent>
  );
};
const SidePanel = ({
  children,
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: Setter<boolean>;
} & PropsWithChildren) => {
  const [location] = useMedicalLibraryLocation();
  return (
    <NbmeSidePanel.Root
      draggable={false}
      emergeFrom={location === "test" ? "right" : "left"}
      open={open}
      onOpenChanged={onOpenChange}
      closeOnClickOutside={false}
      setCloseOnClickOutside={() => false}
    >
      {createPortal(
        <NbmeSidePanel.Body className="max-w-[50vw] z-[1002] h-screen w-[50vw] p-0 border-l-gray-300 overflow-hidden max-h-screen dark:border-l-neutral-600 dark:bg-neutral-900">
          <NbmeSidePanel.Header className="absolute top-3 bg-transparent warm:bg-transparent dark:bg-transparent z-[5] dark:bg-neutral-950 border-b-transparent warm:border-b-transparent dark:border-b-transparent dark:border-b-neutral-600">
            <NbmeSidePanel.CloseButton className="dark:text-neutral-100" />
          </NbmeSidePanel.Header>
          <div className="max-h-screen overflow-hidden">{children}</div>
        </NbmeSidePanel.Body>,
        document.body,
      )}
    </NbmeSidePanel.Root>
  );
};

export default Footer;
