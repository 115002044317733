import QuestionCountBadge from "./QuestionCountBadge";
import LabeledCheckbox from "./LabeledCheckbox";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import { cn } from "@/lib/utils";

interface SubjectCheckBoxProps {
  subjectId: string;
  subjectName: string;
  questionCount: number;
  className?: string;
  loading?: boolean;
}

const SubjectCheckBox = ({
  subjectId,
  subjectName,
  questionCount,
  className,
  loading,
}: SubjectCheckBoxProps) => {
  const disabled = questionCount === 0;
  const { toggleSelection, selectedSubjects } = useCreateTestPageStore(
    (state) => ({
      toggleSelection: state.toggleSelection,
      selectedSubjects: state.selectedSubjects,
    }),
  );

  const checked = !!selectedSubjects.find((s) => s === subjectId);

  return (
    <div
      className={cn(
        "flex justify-between items-center gap-2 w-full lg:max-w-xs transition-opacity",
        loading && "opacity-50 pointer-events-none",
      )}
    >
      <LabeledCheckbox
        className={className}
        id={`${subjectId}-subject-checkbox`}
        checked={checked && questionCount !== 0}
        onCheckedChange={(checked) => {
          toggleSelection("selectedSubjects", checked, subjectId);
        }}
        disabled={disabled}
        label={subjectName.at(0)!.toUpperCase() + subjectName.slice(1)}
      />
      <QuestionCountBadge count={questionCount} />
    </div>
  );
};

export default SubjectCheckBox;
