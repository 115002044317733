import { PropsWithClassName } from "@coursology/types";
import { motion, Variants } from "framer-motion";
import React, { PropsWithChildren } from "react";

export const BlurInOut = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren &
    PropsWithClassName & {
      noOpacityOnHidden?: boolean;
      state?: "visible" | "hidden";
      delay?: number;
      duration?: number;
      fadeUpOnHidden?: boolean;
    }
>(
  (
    {
      children,
      className,
      state,
      delay,
      duration = 0.5,
      noOpacityOnHidden = true,
      fadeUpOnHidden = true,
    },
    ref,
  ) => {
    const variants1: Variants = {
      hidden: {
        filter: "blur(10px)",
        opacity: noOpacityOnHidden ? 0 : 1,
        y: fadeUpOnHidden ? "-10px" : "0px",
        pointerEvents: "none",
      },
      visible: {
        filter: "blur(0px)",
        opacity: 1,
        y: "0px",
        pointerEvents: "auto",
      },
    };
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={state}
        exit={"hidden"}
        transition={{ duration, staggerChildren: 0.1, delay }}
        variants={variants1}
        className={className}
      >
        {children}
      </motion.div>
    );
  },
);
