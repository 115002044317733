import useChooseTestSlotQuestionChoice from "@/hooks/test-slot-hooks/use-choose-testslot-questionchoice";
import { ChooseTestSlotQuestionChoiceInput } from "@/api/routers/test-slot-router";
import { useCanSubmit } from "@/utils/stores/testStore";
import { useTestPageSelectedTestSlot } from "./use-test-page-selected-test-slot";
import { useCallback } from "react";

const useTestPageChooseTestSlotQuestionChoice = () => {
  const { data: selectedSlot } = useTestPageSelectedTestSlot();
  const chooseChoice = useChooseTestSlotQuestionChoice();
  const [_, setCanSubmit] = useCanSubmit();

  return useCallback(
    ({
      questionChoiceId,
    }: Omit<ChooseTestSlotQuestionChoiceInput, "testSlotId">) => {
      if (selectedSlot) {
        chooseChoice.mutate({ testSlotId: selectedSlot.id, questionChoiceId });
      }
    },
    [selectedSlot, chooseChoice, setCanSubmit],
  );
};

export default useTestPageChooseTestSlotQuestionChoice;
