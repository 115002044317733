import React, { useState } from "react";
import { NbmeNavbarBase } from "./NbmeNavbarBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { NbmeMarkQuestionButton } from "./NbmeMarkQuestionButton";
import { NbmeNextPreviousButton } from "./NbmeNextPreviousButton";
import { NbmeFullscreenButton } from "./NbmeFullscreenButton";
import { NbmeTutorialButton } from "./NbmeTutorialButton";
import { NbmeLabValuesButton } from "./NbmeLabValuesButton";
import { NbmeQuestionNoteButton } from "./NbmeQuestionNoteButton";
import { NbmeCalculatorShowButton } from "./NbmeCalculatorShowButton";
import { twMerge } from "tailwind-merge";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { BreakPoints } from "@/types";
import { NbmeSettingsButton } from "./NbmeSettingsButton";
import { NbmeHighlightMarkerButton } from "./NbmeHighlightMarkerButton";
import { Skeleton } from "./ui/skeleton";
import {
  useTestPageSwitchToNextSlot,
  useTestPageSwitchToPreviousSlot,
} from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-switch-selected-slot";
import { useTestPageSelectedTestSlot } from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";
import useTestPageTestSession from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-test-session";

interface NbmeTopNavbarProps {
  onSidebarToggle: () => void;
}

export const NbmeTopNavbar = ({ onSidebarToggle }: NbmeTopNavbarProps) => {
  const [buttonsVisible, setButtonsVisible] = useState<boolean>(false);
  const breakPoint = useBreakPoint();
  const { data: test } = useTestPageTestSession();
  const slots = test?.slots;
  const selectedSlot = useTestPageSelectedTestSlot();
  const question = selectedSlot.data?.question;
  const nextSlot = useTestPageSwitchToNextSlot();
  const previousSlot = useTestPageSwitchToPreviousSlot();

  return (
    <NbmeNavbarBase className="flex-[5] z-[900] min-h-[60px]">
      <div className="flex flex-row justify-center items-center gap-4 ">
        <button className="" type="button" onClick={onSidebarToggle}>
          <FontAwesomeIcon icon={faBars} className="w-8 h-8 text-white" />
        </button>
        <div className="md:flex flex-col gap-1 justify-center items-start text-white md:text-sm text-xs font-bold">
          {selectedSlot.isLoading && !selectedSlot.data ? (
            <Skeleton className="w-5 h-3" />
          ) : (
            <p>
              Item {(selectedSlot.data?.order ?? 0) + 1} of {slots?.length}
            </p>
          )}
          {!question ? (
            <Skeleton className="w-12 h-4" />
          ) : (
            <p className="text-xs">
              Question Id: <br className="block md:hidden" />
              {question.uWolrdId}
            </p>
          )}
        </div>
        <div className="ml-2 hidden md:block">
          <NbmeMarkQuestionButton />
        </div>
      </div>
      <div className="flex flex-row justify-center items-center gap-2 mx-auto absolute left-[50%] translate-x-[-50%] md:translate-x-0 md:relative md:left-auto">
        <NbmeNextPreviousButton
          direction="previous"
          onClick={() => previousSlot()}
        />
        <NbmeNextPreviousButton direction="next" onClick={() => nextSlot()} />
      </div>
      <div
        className={twMerge(
          "flex flex-col md:flex-row justify-start md:justify-center items-center gap-2 md:relative md:right-auto md:top-auto fixed right-0 top-3.5 rounded-b-primary pointer-events-none md:pointer-events-auto",
          buttonsVisible && "shadow-lg pointer-events-auto",
        )}
      >
        <button
          type="button"
          className="text-nbme-primary-500 dark:text-nbme-primary-dark-900  bg-white px-2 py-1 rounded-full md:hidden pointer-events-auto"
          onClick={() => setButtonsVisible((prev) => !prev)}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className={twMerge(
              "transition-transform",
              buttonsVisible && "rotate-180",
            )}
          />
        </button>
        <div
          className={twMerge(
            "flex flex-col md:flex-row justify-start md:justify-center items-center gap-2 bg-inherit bg-nbme-primary-700 warm:bg-brown-800 dark:bg-nbme-primary-dark-800 rounded-b-primary transition-colors",
            !buttonsVisible &&
              breakPoint < BreakPoints.Medium &&
              "invisible  pointer-events-none bg-transparent",
          )}
        >
          <NbmeMarkQuestionButton className="md:hidden w-full md:w-auto" />
          <NbmeFullscreenButton />
          <NbmeHighlightMarkerButton />
          <NbmeTutorialButton />
          <NbmeLabValuesButton />
          <NbmeQuestionNoteButton />
          <NbmeCalculatorShowButton />
          <NbmeSettingsButton />
        </div>
      </div>
    </NbmeNavbarBase>
  );
};
