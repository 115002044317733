import { useEffect, useState } from "react";
import Mark from "mark.js";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useDOMParser from "@/hooks/use-dom-parser";
import { useTestPageSelectedTestSlot } from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";

export const useHighlightMarker = (
  questionId: number,
  questionHtml: string,
) => {
  const domParser = useDOMParser();
  const [parsedQuestionDocument, setParsedQuestionDocument] =
    useState<HTMLElement>(
      domParser.parseFromString(questionHtml, "text/html").documentElement,
    );
  useEffect(() => {
    setParsedQuestionDocument(
      domParser.parseFromString(questionHtml, "text/html").documentElement,
    );
  }, [questionHtml]);

  const { data: slot } = useTestPageSelectedTestSlot();
  const highlights = slot?.question.highlights;
  const [highlightedHtml, setHighlightedHtml] = useState<string>(questionHtml);
  const { data: highlightMarkers } = useHighlightMarkers();

  useEffect(() => {
    const markInstance = new Mark(parsedQuestionDocument);
    parsedQuestionDocument.querySelectorAll("a").forEach((hint) => {
      hint.setAttribute("data-id", hint.getAttribute("href") ?? "");
    });

    markInstance.unmark({
      done: () => {
        //Fake mark so that mark.js can make it's magic
        //Don't touch this... ever... without it highlights
        //don't land on the right spot for some reason
        markInstance.markRanges([{ start: 0, length: 1 }], {
          each(elem) {
            elem.classList.add("bg-transparent", "dark:text-neutral-100");
          },
        });
        highlights?.forEach((highlight) => {
          markInstance.markRanges(
            [
              {
                start: highlight.start,
                length: highlight.end - highlight.start,
              },
            ],
            {
              className:
                "rounded-sm cursor-pointer font-semibold dark:text-neutral-800",

              each: (node) => {
                node.setAttribute("data-id", highlight.id);
                node.setAttribute("data-question-id", questionId.toString());
                node.setAttribute("data-marker-id", highlight.markerId);
                node.setAttribute("data-text", highlight.text);
                node.setAttribute(
                  "style",
                  `background-color: ${
                    highlightMarkers?.find((hm) => hm.id === highlight.markerId)
                      ?.color
                  }`,
                );
              },
            },
          );
        });
        setHighlightedHtml(
          parsedQuestionDocument.querySelector("body")!.innerHTML,
        );
      },
    });
  }, [highlights, parsedQuestionDocument]);

  return highlightedHtml;
};
