import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { faChartLine, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { twMerge } from "tailwind-merge";
import { Buttons } from "./components";
import { Link } from "@tanstack/react-router";
import { TestResultsAnalysisLink } from "./components/TestResultsAnalysisLink";
import ResumeTestLink from "./components/ResumeTestLink";

interface PreviousTestsActionRowProps extends PropsWithClassName {
  questionIds: number[];
  qBankEndpoint: string;
  testId: number;
  selectedSlot: number;
  testName: string;
  isTestSubmitted: boolean;
  deletedAt: Date | string | null;
}

export const PreviousTestsActionRow = ({
  testId,
  className,
  qBankEndpoint,
  selectedSlot,
  isTestSubmitted,
  testName,
  questionIds,
  deletedAt,
}: PreviousTestsActionRowProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-row justify-start items-center text-primary-500 warm:text-brown-800 dark:text-neutral-300 gap-4",
        className,
      )}
    >
      {!deletedAt && (
        <>
          <ResumeTestLink testId={testId} selectedSlot={selectedSlot} />
          {isTestSubmitted && (
            <>
              <TestResultsAnalysisLink
                qBankEndpoint={qBankEndpoint}
                testId={testId}
                to={"results"}
              />
              <TestResultsAnalysisLink
                qBankEndpoint={qBankEndpoint}
                testId={testId}
                to={"analysis"}
              />
            </>
          )}
        </>
      )}
      <Buttons
        testId={testId}
        testName={testName}
        qBankEndpoint={qBankEndpoint}
        questionIds={questionIds}
        deletedAt={deletedAt}
      />
    </div>
  );
};
