import YouTubeEmbed from "@/ncomponents/utilities/youtube-embed";
import { TransformCallback } from "interweave";

export const youtubeLinkTransformer: TransformCallback = (node) => {
  if (node.tagName === "A") {
    const href = node.getAttribute("href");
    const isYoutubeVideo = href?.includes("youtube");
    if (isYoutubeVideo) {
      return <YouTubeEmbed url={href!} />;
    }
  }
};
