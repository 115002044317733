import { trpc } from "@/utils/trpc";
import useMutateTestPageSelectedSlotQueryCache from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-mutate-test-page-selected-slot-query-cache";
import { toast } from "sonner";
import useMutateTestPageSessionCache from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-mutate-test-page-session-cache";
import { current } from "immer";
import useMutatePreviousTestsQueryCache from "../utility/use-mutate-previous-tests-query-cache";
import { percent } from "@/utils";

const useSubmitTestSlot = () => {
  const utils = trpc.useUtils();
  const testSlotCacheMutator = useMutateTestPageSelectedSlotQueryCache();
  const testSessionCacheMutator = useMutateTestPageSessionCache();
  const mutatePreviousTestsQueryCache = useMutatePreviousTestsQueryCache()();

  return trpc.testSlot.submitTestSlot.useMutation({
    onMutate: ({ testSlotId, chosenChoiceId }) => {
      let chosenChoice: { correct: boolean | null } | null | undefined =
        undefined;
      let allSlotsWereSubmitted = true;
      let testId: number | undefined = undefined;
      let correctSlots = 0;
      const rollbackTestSlot = testSlotCacheMutator((state) => {
        const chosenChoiceDraft = state.question.choices.find(
          (c) => c.id === chosenChoiceId,
        );

        chosenChoice = chosenChoiceDraft ? current(chosenChoiceDraft) : null;
        state.submitted = true;
      });

      const rollbackTestSession = testSessionCacheMutator((state) => {
        testId = state.id;
        state.slots.forEach((s) => {
          if (s.id === testSlotId) {
            s.isSubmitted = true;
          }
          if (!s.isSubmitted) {
            allSlotsWereSubmitted = false;
          }
          if (s.chosen?.correct) {
            correctSlots++;
          }
        });

        if (allSlotsWereSubmitted) {
          state.submitted = true;
          state.submittedAt = new Date();
        }
      });

      const rollbackPreviousTests = mutatePreviousTestsQueryCache((state) => {
        if (allSlotsWereSubmitted && testId !== undefined) {
          const targetTest = state.tests.find((t) => t.id === testId);
          if (!targetTest) return;
          targetTest.submitted = true;
          targetTest.score = percent(correctSlots, targetTest.questions.length);
        }
      });

      return {
        rollback: () => {
          rollbackTestSlot((previous) => {
            previous.submitted = false;
          });
          rollbackTestSession((state, oldTestSession) => {
            state.slots.forEach((s) => {
              if (s.id === testSlotId) {
                s.isSubmitted = false;
              }
            });

            if (allSlotsWereSubmitted) {
              state.submitted = false;
              state.submittedAt = null;
            }
          });
          rollbackPreviousTests((state) => {
            const targetTest = state.tests.find((t) => t.id === testId);
            if (!targetTest) return;
            targetTest.submitted = false;
            targetTest.score = null;
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not submit test slot.., an error ocurred ${error.message}`,
      );
      context?.rollback();
    },
    onSuccess: (data, variables, context) => {
      testSlotCacheMutator((state) => {
        state.timeElapsed = data.timeElapsed;
      });
      utils.statistics.getQuestionbankUsage.invalidate();
      utils.statistics.getQuestionBankOverallStatistics
        .invalidate()
        .then(() => {
          utils.statistics.getQuestionBankOverallStatistics.prefetch({
            questionBankId: data.questionBankId,
          });
        });
    },
  });
};
export default useSubmitTestSlot;
