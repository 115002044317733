import { QuestionBankPerformanceReportsInput } from "@/api/src/server/routers/statistics/types";
import { trpc } from "@/utils/trpc";
import { useEffect } from "react";

const useQuestionBankPerformanceReports = (
  input: QuestionBankPerformanceReportsInput,
) => {
  const utils = trpc.useUtils();
  useEffect(() => {
    if (!input.questionBankId) return;
    if (input.page > 1) {
      utils.statistics.getQuestionBankPerformanceReports.prefetch({
        ...input,
        page: input.page - 1,
      });
    }
    utils.statistics.getQuestionBankPerformanceReports.prefetch({
      ...input,
      page: input.page + 1,
    });
    utils.statistics.getQuestionBankPerformanceReports.prefetch({
      ...input,
      page: input.page + 2,
    });
  }, [input.page, input.questionBankId]);
  return trpc.statistics.getQuestionBankPerformanceReports.useQuery(input, {
    enabled: !!input.questionBankEndpoint || !!input.questionBankId,
    placeholderData: (previous) => previous,
  });
};

export default useQuestionBankPerformanceReports;
