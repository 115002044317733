"use client";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { useRequiresUser } from "@/utils/customHooks";
import { DateTime } from "luxon";
import { calcExpirationDate } from "@/utils/subscription";
import { CoursologyLogo } from "@/components/common/CoursologyLogo";
import { DashboardScrollArea } from "@/components/DashboardScrollArea";
import { Buttons } from "./components";
import { Skeleton } from "@/components/ui/skeleton";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";

export const DashboardSidebar = () => {
  const { data: questionBank, isLoading } = useDashboardQuestionBank();
  const { sidebarVisible, toggleSidebar } = useDashboardLayoutStore(
    (state) => ({
      sidebarVisible: state.sidebarVisible,
      toggleSidebar: state.toggleSidebar,
    }),
  );
  const user = useRequiresUser();
  if (!sidebarVisible) return <></>;
  return (
    <nav
      className={twMerge(
        "fixed flex flex-col transition-[transform_,border-color,_background-color] lg:transition-none justify-start items-start lg:sticky top-0 gap-3 bg-primary-500 warm:bg-brown-700 dark:bg-neutral-900 border-e border-e-transparent dark:border-e-neutral-700 min-h-screen max-h-screen w-max z-[1000]",
        !sidebarVisible && "translate-x-[-100%]",
      )}
    >
      <button
        type="button"
        className="absolute lg:hidden top-0 right-2 text-white dark:text-neutral-100 text-2xl"
        onClick={() => toggleSidebar()}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div
        className={twMerge(
          "flex flex-col justify-center items-center bg-primary-600 warm:bg-brown-800 dark:bg-neutral-800 gap-6 w-full py-5 opacity-100 transition-[opacity,_background-color]",
          !sidebarVisible && "opacity-0",
        )}
      >
        <CoursologyLogo variant="mono" className="w-[9.375rem]" />
        {isLoading ? (
          <Skeleton className="w-14 h-6 rounded-full" />
        ) : (
          <p className="font-semibold text-white">
            {questionBank?.name ?? "Loading"} QBank
          </p>
        )}
      </div>
      <ul
        className={twMerge(
          "flex flex-col self-start justify-start items-start bg-primary-500 warm:bg-brown-700 dark:bg-neutral-900 pt-5 px-3 text-white opacity-100 transition-opacity w-max overflow-hidden",
          !sidebarVisible && "opacity-0",
        )}
      >
        <DashboardScrollArea
          className="h-[50rem]"
          viewportClassName="px-5"
          thumbClassName="bg-primary-700 warm:bg-brown-800 transition-colors"
          scrollbarClassName="hover:bg-primary-600 warm:hover:bg-brown-950/20 bg-transparent"
        >
          <Buttons />
        </DashboardScrollArea>
      </ul>
      <div
        className={twMerge(
          "flex flex-col justify-center items-center bg-primary-600 warm:bg-brown-800 dark:bg-neutral-800 mt-auto w-full gap-1 py-3 transition-colors",
        )}
      >
        {user && user.currentSubscription && (
          <>
            <h1 className="text-center font-bold text-white">
              {user.name.split(" ").slice(0, 2).join(" ")}
            </h1>
            <div
              className={twMerge(
                "flex flex-col justify-center items-center mt-auto w-full gap-0.5",
              )}
            >
              {user.currentSubscription.name
                .toLowerCase()
                .includes("unlimited") ||
              user.currentSubscription.name.toLowerCase().includes("admin") ? (
                <h2 className="font-semibold text-base text-white">
                  Lifetime Subscription
                </h2>
              ) : (
                <>
                  <h2 className="font-medium text-base text-white">
                    Expiration Date
                  </h2>

                  <p className="text-primary-200 dark:text-neutral-300 text-xs">
                    {calcExpirationDate({
                      lastRenewal: DateTime.fromJSDate(
                        new Date(user.lastRenewal!),
                      ),
                      subscriptionType: user.currentSubscription.type,
                      duration: user.currentSubscription.duration,
                      extraMonths: user.extraMonths ?? 0,
                    })?.toFormat("dd MMMM, yyyy h:mm a")}
                  </p>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};
