"use client";

import * as React from "react";
import { Eye, EyeOff, Save, Pencil } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { trpc } from "@/utils/trpc";
import { toast } from "sonner";

interface PasswordInputProps {
  userId: string;
  initialPassword: string;
}

export default function PasswordInput({
  userId,
  initialPassword,
}: PasswordInputProps) {
  const { mutate: changePassword, isPending: isSaving } =
    trpc.user.changePassword.useMutation();
  const [password, setPassword] = React.useState(initialPassword);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isChanged, setIsChanged] = React.useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsChanged(newPassword !== initialPassword);
  };

  const handleSave = async () => {
    changePassword(
      { userId, newPassword: password },
      {
        onError: (error) => {
          toast.error(
            `Failed to save password, an error ocurred: ${error.message}`,
          );
        },
        onSettled: () => {
          setIsChanged(false);
          setIsEditing(false);
        },
      },
    );
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <div className="relative">
      <div className="flex gap-2">
        <div className="relative flex-1">
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            disabled={!isEditing}
            className="pr-[5.5rem] dark:border-neutral-50 dark:text-white dark:placeholder:text-neutral-400"
          />
          <div className="absolute right-0 top-0 flex h-full">
            <Button
              variant="ghost"
              size="icon"
              className="h-full px-2 hover:bg-transparent dark:text-white dark:hover:text-neutral-400 dark:bg-transparent dark:hover:bg-transparent"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <EyeOff className="h-4 w-4" />
              ) : (
                <Eye className="h-4 w-4" />
              )}
              <span className="sr-only">
                {showPassword ? "Hide password" : "Show password"}
              </span>
            </Button>
            {!isEditing && (
              <Button
                variant="ghost"
                size="icon"
                className="h-full px-2 hover:bg-transparent dark:text-white dark:hover:text-neutral-400 dark:bg-transparent dark:hover:bg-transparent"
                onClick={handleEditClick}
              >
                <Pencil className="h-4 w-4" />
                <span className="sr-only">Edit password</span>
              </Button>
            )}
          </div>
        </div>
        {isChanged && (
          <Button
            onClick={handleSave}
            disabled={isSaving}
            size="icon"
            className="dark:bg-neutral-800 dark:text-white dark:hover:bg-neutral-700"
          >
            <Save className="h-4 w-4" />
            <span className="sr-only">Save password</span>
          </Button>
        )}
      </div>
    </div>
  );
}
