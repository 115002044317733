import { DateTime } from "luxon";
import { trpc } from "@/utils/trpc";
import useMutateTestSessionQueryCache from "../utility/use-mutate-test-session-query-cache";
import { toast } from "sonner";
import useMutatePreviousTestsQueryCache from "../utility/use-mutate-previous-tests-query-cache";

const useSetTestMode = () => {
  const mutateTestSessions = useMutateTestSessionQueryCache()();
  const mutatePreviousTest = useMutatePreviousTestsQueryCache()();
  return trpc.test.setTestMode.useMutation({
    onMutate: ({ testId, mode }) => {
      const rollbackTestSession = mutateTestSessions((state) => {
        if (state.id === testId) {
          if (mode.timed === true) {
            state.mode = state.mode + ",timed";
            state.endsAt = DateTime.now()
              .plus({
                minutes: state.minutesPerQuestion * state.slots.length,
              })
              .toJSDate();
          } else if (mode.timed === false) {
            state.mode = state.mode.replaceAll("timed", "");
            state.endsAt = null;
          }
          if (mode.tutor === true) {
            state.mode = state.mode + ",tutor";
          } else if (mode.tutor === false) {
            state.mode = state.mode.replaceAll("tutor", "");
          }
        }
      });
      const rollbackPreviousTest = mutatePreviousTest((state) => {
        const targetTest = state.tests.find((t) => t.id === testId);
        if (!targetTest) return;
        if (mode.timed === true) {
          targetTest.mode = targetTest.mode + ",timed";
        } else if (mode.timed === false) {
          targetTest.mode = targetTest.mode.replaceAll("timed", "");
        }
        if (mode.tutor === true) {
          targetTest.mode = targetTest.mode + ",tutor";
        } else if (mode.tutor === false) {
          targetTest.mode = targetTest.mode.replaceAll("tutor", "");
        }
      });
      return {
        rollback: () => {
          rollbackTestSession((state, previousState) => {
            state.mode = previousState.mode;
            if (mode.timed === true) {
              state.endsAt = null;
            }
          });
          rollbackPreviousTest((state, previousState) => {
            const targetTest = state.tests.find((t) => t.id === testId);
            const oldTargetTest = previousState.tests.find(
              (t) => t.id === testId,
            );

            if (!targetTest || !oldTargetTest) return;

            targetTest.mode = oldTargetTest.mode;
          });
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Failed to set test mode..., an error occurred: ${error.message}`,
      );
      context?.rollback();
    },
  });
};
export default useSetTestMode;
