import { SearchInputWithActivity } from "@/components/SearchInputWithActivity";
import {
  useSearchQuestionsPageQuery,
  useSearchQuestionsPageStore,
} from "../-store";

export const SearchQuestionsPageQueryInput = () => {
  const isQuestionsLoading = useSearchQuestionsPageStore(
    (state) => state.isQuestionsLoading,
  );
  const { query, setQuery } = useSearchQuestionsPageQuery();

  return (
    <div className="flex flex-col justify-start items-start">
      <SearchInputWithActivity
        isLoading={isQuestionsLoading}
        value={query}
        onChange={() => undefined}
        onChangeEnd={(query) => setQuery(query.trim())}
        variant="dashboard"
        placeholder="Enter Question ID or keywords"
      />
      <p className="text-xs md:text-sm text-gray-500 dark:text-neutral-400 w-3/4">
        Note: You can search questions with their content, their IDs, the name
        of their system, subject, or topic.
      </p>
    </div>
  );
};
