import { Paginator } from "@/components/Paginator";
import useNotesPageNotes from "../-notes-page-hooks/use-notes-page-notes";
import { useNotesPageCurrentPage, useNotesPageNotesPerPage } from "../-store";

const NotesPagePaginator = () => {
  const { data } = useNotesPageNotes();
  const [currentPage, setCurrentPage] = useNotesPageCurrentPage();
  const [notesPerPage, setNotesPerPage] = useNotesPageNotesPerPage();

  return (
    <Paginator
      itemSingularName="note"
      currentPage={currentPage}
      onCurrentPageChange={setCurrentPage}
      onItemsPerPageChange={setNotesPerPage}
      itemsPerPage={notesPerPage}
      numberOfItems={data?.notesCount ?? 0}
    />
  );
};

export default NotesPagePaginator;
