import { Button } from "./Button";
import CircularProgressBar from "./ui/circular-progress-bar";
import DashboardStatsSection from "./DashboardStatsSection";
import { Input } from "./ui/input";
import useClampedState from "@/hooks/use-clamped-state";
import { clamp, percent } from "@/utils";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { LoadingSpinner } from "./LoadingSpinner";
import { isNaN } from "underscore";
import { cn } from "@/lib/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import useQuestionBankOverallStatistics from "@/hooks/statistics/use-questionbank-overall-statistics";
import { useParams } from "@tanstack/react-router";

const variants: Variants = {
  hidden: { filter: "blur(5px)", pointerEvents: "none" },
  visible: { filter: "blur(0)", pointerEvents: "auto" },
};

const noteVariants: Variants = {
  hidden: {
    filter: "blur(10px)",
    opacity: 0,
    transition: { duration: 0.4 },
    pointerEvents: "none",
  },
  visible: {
    filter: "blur(0)",
    opacity: 1,
    transition: { duration: 0.4, delay: 0.5 },
    pointerEvents: "auto",
  },
};

const circularProgressBarVariants: Variants = {
  hidden: {
    filter: "blur(10px)",
    y: "-10px",
    opacity: 0,
    transition: { duration: 0.4 },
    pointerEvents: "none",
  },
  visible: {
    filter: "blur(0)",
    y: "0",
    opacity: 1,
    transition: { duration: 0.4, delay: 1 },
    pointerEvents: "auto",
  },
};

const loadingSpinnerVariants: Variants = {
  hidden: {
    filter: "blur(10px)",
    y: "-10px",
    opacity: 0,
    transition: { duration: 0.4 },
    pointerEvents: "none",
  },
  visible: {
    filter: "blur(0)",
    y: "0",
    opacity: 1,
    transition: { duration: 0.4, delay: 0.5 },
    pointerEvents: "auto",
  },
};

const getMinimumPassingScore = (questionBankName: string) => {
  const STEP1_MINIMUM_PASSING_SCORE = 196;
  const STEP2_MINIMUM_PASSING_SCORE = 214;
  const lowerCasedQuestionBankName = questionBankName.toLowerCase();
  if (lowerCasedQuestionBankName.includes("step 1")) {
    return STEP1_MINIMUM_PASSING_SCORE;
  } else if (
    lowerCasedQuestionBankName.includes("step 2") ||
    lowerCasedQuestionBankName.includes("cms")
  ) {
    return STEP2_MINIMUM_PASSING_SCORE;
  } else return 0;
};

export const DashboardPredictedScoreSection = () => {
  const { qbankEndpoint } = useParams({ strict: false });
  const { data: questionBank } = useDashboardQuestionBank();
  const { data: overallStatistics, isLoading } =
    useQuestionBankOverallStatistics({
      questionBankEndpoint: qbankEndpoint ?? "",
    });
  const [canCalculateScore, setCanCalculateScore] = useState<boolean>(true);
  const [manuallyUsed, setManuallyUsed] = useState<boolean>(false);
  const formula = useMemo(() => {
    const questionBankName = questionBank?.name;
    if (!questionBankName) return "";
    const totalQuestionCount = overallStatistics?.questions.total;
    const formulaMapper: {
      [k: number]: { [k: string]: { [k: number]: string } };
    } = {
      1: {
        nbme: {
          25: "277.04 - 1.113 * x",
          26: "-1.1275 * x + 276.38",
          27: "-1.1211 * x + 276.53",
          28: "-1.1701 * x + 278.36",
          29: "-1.1522 * x + 276.92",
          30: "-1.1435 * x + 277.21",
          31: "-1.1435 * x + 277.21",
        },
        uwsa: {
          //UWSA calculators use the percentage of correct answers,
          //that's why we take the total questions and subtract x (wrong answers)
          //to get the correct answers and then get the percentage from the total
          1: `1.8458 * (((${totalQuestionCount} - x)/${totalQuestionCount}) * 100) + 107.6`,
          2: `1.8458 * (((${totalQuestionCount} - x)/${totalQuestionCount}) * 100) + 107.6`,
          3: `1.8458 * (((${totalQuestionCount} - x)/${totalQuestionCount}) * 100) + 107.6`,
        },
      },
      2: {
        nbme: {
          9: "-0.9 * x + 295",
          10: "-0.9 * x + 293",
          11: "-0.8 * x + 287",
          12: "-0.8 * x + 286",
          13: "-0.9 * x + 295",
          14: "-0.9 * x + 293",
          15: "-0.9 * x + 295",
        },
        uwsa: {
          1: "-1.0956 * x + 293.59",
          2: "-1.0273 * x + 292.02",
          3: "-1.0956 * x + 293.59",
        },
      },
    };
    const lowerCasedQuestionBankName = questionBankName.toLowerCase();
    const examType = /self assessment/.exec(lowerCasedQuestionBankName)?.[0]
      ? "uwsa"
      : /nbme/.exec(lowerCasedQuestionBankName)?.[0]
        ? "nbme"
        : "";
    if (!examType) return "";
    const examNumber =
      examType === "nbme"
        ? /nbme ([0-9]+)/.exec(lowerCasedQuestionBankName)?.[1]
        : /assessment ([0-9]+)/.exec(lowerCasedQuestionBankName)?.[1];
    if (!examNumber) return "";
    const examStep = /step ([0-9])/.exec(lowerCasedQuestionBankName)?.[1];
    if (!examStep) return "";
    const formula =
      formulaMapper[Number(examStep)]?.[examType]?.[Number(examNumber)];
    return formula;
  }, [questionBank?.name]);

  useEffect(() => {
    if (isLoading) return;
    if (!overallStatistics || overallStatistics.questions.total === 0) {
      return setCanCalculateScore(false);
    }
    if (overallStatistics?.questions.unused > 0) {
      return setCanCalculateScore(false);
    }

    setCanCalculateScore(true);
  }, [isLoading, overallStatistics]);

  const [incorrectAnswers, setIncorrectAnswers] = useClampedState<string>(
    "",
    (value) => {
      if (value === "") return value;
      let num = Number(value);
      if (isNaN(num)) {
        num = 0;
      }
      return String(
        clamp({
          num,
          min: 0,
          max: overallStatistics?.questions.total ?? Infinity,
        }),
      );
    },
  );

  const [predictedScore, setPredictedScore] = useClampedState<number>(
    0,
    (num) => {
      return clamp({ num, min: 0, max: 300 });
    },
  );

  const calculateScore = useCallback(
    (incorrectAnswers: number | string) =>
      setPredictedScore(
        Math.ceil(eval(formula.replace("x", String(incorrectAnswers)))),
      ),
    [setPredictedScore, formula],
  );

  useEffect(() => {
    if (manuallyUsed) return;
    if (canCalculateScore && formula) {
      const incorrectAnswers = String(
        (overallStatistics?.questions.incorrect ?? 0) +
          (overallStatistics?.questions.omitted ?? 0),
      );
      calculateScore(incorrectAnswers);
    }
  }, [
    canCalculateScore,
    calculateScore,
    formula,
    manuallyUsed,
    overallStatistics,
  ]);

  const minimumPassingScore = getMinimumPassingScore(questionBank?.name ?? "");
  const USMLE_MAXIMUM_PASSING_SCORE = 300;
  const maximumScore = USMLE_MAXIMUM_PASSING_SCORE;

  const minimumPassingScoreReached = predictedScore >= minimumPassingScore;
  if (!formula) return <></>;

  return (
    <DashboardStatsSection
      title={
        <h1 className={cn("font-medium text-base")}>Predicted 3-digit score</h1>
      }
      isLoading={isLoading}
    >
      <div className="relative flex flex-col justify-center items-center gap-4 w-full">
        <motion.div
          initial={"visible"}
          animate={isLoading ? "visible" : "hidden"}
          variants={loadingSpinnerVariants}
          className="absolute left-[50%] top-[50%] flex flex-col justify-center items-center"
        >
          <LoadingSpinner className="w-12 h-12 -translate-x-[50%] -translate-y-[50%]" />
        </motion.div>
        <motion.p
          className="absolute text-gray-900 dark:text-neutral-200 warm:text-brown-800 z-30 -translate-y-[50%]"
          variants={noteVariants}
          initial={"hidden"}
          animate={!canCalculateScore && !isLoading ? "visible" : "hidden"}
          exit={"hidden"}
        >
          Finish all blocks to see your score
        </motion.p>
        <div className="flex flex-row w-full items-center justify-center h-full">
          <motion.div
            className="relative my-12"
            variants={variants}
            initial={"hidden"}
            animate={canCalculateScore ? "visible" : "hidden"}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={predictedScore}
                initial={"hidden"}
                animate={isLoading ? "hidden" : "visible"}
                exit={"hidden"}
                variants={circularProgressBarVariants}
              >
                <CircularProgressBar.Root
                  isClickable={false}
                  progress={[
                    canCalculateScore
                      ? percent(predictedScore, maximumScore)
                      : 70,
                  ]}
                >
                  <CircularProgressBar.Renderer>
                    <CircularProgressBar.Background />
                    <CircularProgressBar.Progress
                      className={
                        minimumPassingScoreReached
                          ? "stroke-lime-500 transition-colors"
                          : canCalculateScore
                            ? "stroke-red-500 transition-colors"
                            : "stroke-lime-500 transition-colors"
                      }
                    />
                  </CircularProgressBar.Renderer>
                  <CircularProgressBar.Content className="flex flex-col justify-center items-center w-full">
                    <p className="font-bold text-2xl text-gray-700 dark:text-neutral-200 warm:text-brown-800 text-center w-full">
                      {predictedScore}
                    </p>
                    <p
                      className={cn(
                        "text-2xs font-light text-gray-600 dark:text-neutral-100 transition-colors text-center w-full",
                        !minimumPassingScoreReached &&
                          canCalculateScore &&
                          "text-red-600 dark:text-red-500 font-semibold",
                      )}
                    >
                      Need {minimumPassingScore} for Passing
                    </p>
                  </CircularProgressBar.Content>
                </CircularProgressBar.Root>
              </motion.div>
            </AnimatePresence>
            <AnimatePresence mode="wait">
              <motion.div
                key={predictedScore}
                initial={isLoading ? "hidden" : "visible"}
                animate={"hidden"}
                exit={"visible"}
                variants={loadingSpinnerVariants}
                className="absolute left-[50%] top-[50%] flex flex-col justify-center items-center"
              >
                <LoadingSpinner className="w-12 h-12 -translate-x-[50%] -translate-y-[50%]" />
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </div>
        <motion.div
          variants={circularProgressBarVariants}
          initial={"hidden"}
          animate={isLoading ? "hidden" : "visible"}
          className="flex flex-col w-full 2xl:flex-row justify-center items-center gap-3"
        >
          <p className="text-sm text-gray-700 dark:text-neutral-200 w-full">
            Or Enter the number of incorrect answers manually:
          </p>
          <form
            className="flex flex-row justify-start items-center gap-3"
            onSubmit={(e) => {
              e.preventDefault();
              calculateScore(incorrectAnswers);
              if (!canCalculateScore) setCanCalculateScore(true);
              if (!manuallyUsed) setManuallyUsed(true);
            }}
          >
            <Input
              className="w-16 dark:text-neutral-200 dark:border-neutral-200"
              type="number"
              value={incorrectAnswers}
              onChange={(e) => setIncorrectAnswers(e.target.value)}
              max={overallStatistics?.questions.total}
              min={0}
            />
            <Button
              type="submit"
              className="w-fit md:text-sm lg:text-sm text-sm"
            >
              Calculate
            </Button>
          </form>
        </motion.div>
      </div>
    </DashboardStatsSection>
  );
};
