import { HelpButton } from "./Help";
import { NotebookButton } from "./Notebook";
import { FlashcardsButton } from "./Flashcards";
import { NotesButton } from "./NotesButton";
import { QuestionsButton } from "./Questions";
import { PerformanceButton } from "./Performance";
import { TestsButton } from "./Tests";
import { CreateTestButton } from "./CreateTest";
import { WelcomeButton } from "./Welcome";
import { ResetQuestionBankButton } from "./ResetQuestionBankButton";
import MedicalLibraryButton from "./medical-library-button";

export const Buttons = () => {
  return (
    <>
      <WelcomeButton />
      <CreateTestButton />
      <TestsButton />
      <PerformanceButton />
      <MedicalLibraryButton />
      <QuestionsButton />
      <NotesButton />
      {/* <HighlightsButton /> */}
      <FlashcardsButton />
      <NotebookButton />
      <HelpButton />
      {/*<ResetAccountButton />*/}
      <ResetQuestionBankButton />
    </>
  );
};
