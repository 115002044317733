import { useEffect, useState } from "react";
import useDOMParser from "../use-dom-parser";
import Mark from "mark.js";
import { Highlight } from "@/models";
import useHighlightMarkers from "../highlightmarker-hooks/use-highlightmarkers";

const useHighlightedHtml = ({
  html,
  highlights,
}: {
  html: string;
  highlights: Pick<Highlight, "id" | "markerId" | "text" | "start" | "end">[];
}) => {
  const domParser = useDOMParser();
  const [parsedDocument, setParsedDocument] = useState<HTMLElement>(
    domParser.parseFromString(html, "text/html").documentElement,
  );
  useEffect(() => {
    setParsedDocument(
      domParser.parseFromString(html, "text/html").documentElement,
    );
  }, [html, domParser]);
  const { data: highlightMarkers } = useHighlightMarkers();
  const [highlightedHtml, setHighlightedHtml] = useState<string>(html);

  useEffect(() => {
    const markInstance = new Mark(parsedDocument);
    parsedDocument.querySelectorAll("a").forEach((hint) => {
      hint.setAttribute("data-id", hint.getAttribute("href") ?? "");
    });

    // This fixes highlight alignment on articles, leave as is...
    parsedDocument.querySelectorAll(".article-icon").forEach((icon) => {
      icon.innerHTML = "";
    });

    markInstance.unmark({
      exclude: ["[data-is-match=true]"],
      done: () => {
        //Fake mark so that mark.js can make it's magic
        //Don't touch this... ever... without it highlights
        //don't land on the right spot for some reason
        markInstance.markRanges([{ start: 0, length: 1 }], {
          each(elem) {
            elem.classList.add(
              "bg-transparent",
              "dark:text-neutral-100",
              "warm:text-inherit",
              "text-inherit",
            );
            elem.setAttribute("data-highlight", "true");
          },
        });
        highlights?.forEach((highlight) => {
          markInstance.markRanges(
            [
              {
                start: highlight.start,
                length: highlight.end - highlight.start,
              },
            ],
            {
              className:
                "rounded-sm cursor-pointer font-semibold dark:text-neutral-800",

              each: (node) => {
                node.setAttribute("data-highlight", "true");
                node.setAttribute("data-id", highlight.id);
                node.setAttribute("data-marker-id", highlight.markerId);
                node.setAttribute("data-text", highlight.text);
                node.setAttribute(
                  "style",
                  `background-color: ${
                    highlightMarkers?.find((hm) => hm.id === highlight.markerId)
                      ?.color
                  }`,
                );
              },
            },
          );
        });
        setHighlightedHtml(parsedDocument.querySelector("body")!.innerHTML);
      },
    });
  }, [parsedDocument, highlightMarkers, highlights]);
  return highlightedHtml;
};

export default useHighlightedHtml;
