import combineInterweaveTransformers from "@/lib/utils/combine-interweave-transformers";
import { Alignment } from "@/types/common";
import {
  linkFilter,
  listFilter,
  paragraphFilter,
  tableFilter,
} from "@/utils/interweave/filters";
import {
  highlightTransformer,
  imageTransformer,
} from "@/utils/interweave/transformers";
import { hintTransformer } from "@/utils/interweave/transformers/hintTransformer";
import { BreakPoints } from "@coursology/types";
import { Interweave } from "interweave";
import React, { useMemo } from "react";
import { Card } from "../ui/card";
import { BlurInOut } from "@/ncomponents/utilities/animations/blur-in-out";

export const NbmeExplanationAiSummary = React.forwardRef<
  HTMLDivElement,
  {
    questionSource: string;
    fontSizeFactor: number;
    fontWeight: number;
    alignment: Alignment;
    breakPoint: BreakPoints;
    children: string;
  }
>(
  (
    {
      questionSource,
      fontSizeFactor,
      alignment,
      breakPoint,
      children,
      fontWeight,
    },
    ref,
  ) => {
    const memoizedParagraphFilter = useMemo(
      () => paragraphFilter(fontSizeFactor, fontWeight, alignment),
      [fontSizeFactor, alignment, fontWeight],
    );
    const memoizedTableFilter = useMemo(
      () => tableFilter(breakPoint, fontSizeFactor, fontWeight),
      [breakPoint, fontSizeFactor, fontWeight],
    );
    const memoizedCombinedTransformer = useMemo(
      () =>
        combineInterweaveTransformers(
          highlightTransformer,
          imageTransformer(),
          hintTransformer({ questionSource }),
        ),
      [questionSource],
    );
    const memoizedBlockList = useMemo(() => ["br"], []);
    const memoizedInterweaveFilters = useMemo(
      () => [
        listFilter,
        linkFilter,
        memoizedParagraphFilter,
        memoizedTableFilter,
      ],
      [memoizedParagraphFilter, memoizedTableFilter],
    );
    return (
      <BlurInOut
        ref={ref}
        className="absolute top-0 left-0 w-full"
        state={children ? "visible" : "hidden"}
      >
        <Card className="w-full p-3 rounded-md border border-gray-300 dark:border-neutral-700 warm:border-brown-200 dark:bg-nbme-primary-dark-800 drop-shadow-none shadow-md shadow-gray-200 dark:shadow-nbme-primary-dark-900 warm:shadow-brown-200">
          <Interweave
            className=""
            blockList={memoizedBlockList}
            transform={memoizedCombinedTransformer}
            filters={memoizedInterweaveFilters}
            content={children}
          />
        </Card>
      </BlurInOut>
    );
  },
);
