import { queryClient } from "@/utils/trpc";
import { getQueryKey } from "@trpc/react-query";
import { Draft, produce } from "immer";
import { useCallback } from "react";

/**
 * @param procedure - The query to mutate the cache of
 * @param queryType - The type of query
 * @returns A function that, when invoked with the proper procedure input, returns a function that can
 * be used to mutate the query cache
 */
const useMutateQueryCache = <
  ProcedureInput extends any,
  ProcedureOutput extends any,
>(
  //TODO: Get rid of this 'any'
  procedure: any,
  queryType: "query" | "infinite",
) => {
  return useCallback(
    (input?: Partial<ProcedureInput>) =>
      (
        mutateFn: (
          state: Draft<ProcedureOutput>,
          input?: Partial<ProcedureInput>,
        ) => void,
      ) => {
        const queryKey = getQueryKey(procedure, input, queryType);

        let oldQueryData: ProcedureOutput | undefined;
        queryClient.cancelQueries({ queryKey });
        queryClient.setQueriesData<ProcedureOutput>(
          { queryKey: queryKey },
          (oldData) => {
            if (!oldData) return;
            oldQueryData = oldData;
            return produce(oldData, (draft) => {
              mutateFn(draft, input);
            });
          },
        );
        return (
          rollbackFn?: (
            state: Draft<ProcedureOutput>,
            oldState: ProcedureOutput,
          ) => void,
        ) => {
          queryClient.setQueriesData<ProcedureOutput>(
            { queryKey: queryKey },
            (previous) => {
              if (!previous) return;
              return rollbackFn
                ? produce(previous, (draft) => {
                    if (!oldQueryData) return;
                    rollbackFn(draft, oldQueryData);
                  })
                : oldQueryData;
            },
          );
        };
      },
    [procedure],
  );
};

export default useMutateQueryCache;
