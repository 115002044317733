import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import React, { ComponentProps, PropsWithChildren } from "react";
import useTriggerableContext from "./hooks/use-triggerable-context";

type TriggerableBodyProps = PropsWithChildren &
  PropsWithClassName &
  ComponentProps<"div">;
const TriggerableBody = React.forwardRef<
  HTMLDivElement | null,
  TriggerableBodyProps
>(({ children, className, ...props }, ref) => {
  const { isOpen } = useTriggerableContext();

  return (
    <>
      {isOpen && (
        <div {...props} ref={ref} className={cn(className)}>
          {children}
        </div>
      )}
    </>
  );
});

export default TriggerableBody;
