import { Page } from "../../types";
import { TreeItem, TreeItemProps } from "..";
import {
  useCollapsedItems,
  useNoteActions,
  useNotebookDraft,
  useNotebookUi,
} from "@/hooks/notebook";

type PageTreeProps = Page & {
  isEditMode?: boolean;
  onRecover: () => void;
  onRemove: () => void;
  onEditConfirm: (page: Pick<Page, "id" | "title">) => void;
  onEditCancel?: () => void;
};

export const PageTree = ({
  notes,
  title,
  isDeleted,
  id,
  onRemove,
  onRecover,
  onEditConfirm,
  isEditMode,
  onEditCancel,
}: PageTreeProps) => {
  const showDeletedNotes = useNotebookUi((state) => state.showDeletedNotes);
  const {
    add: addNote,
    remove: removeNote,
    rename: renameNote,
    recover: recoverNote,
  } = useNoteActions();
  const { collapsedItems } = useCollapsedItems();
  const [_, setNotebookDraft] = useNotebookDraft();
  const recursivelyMapChildren = (
    array: typeof notes,
  ): TreeItemProps["childItems"] => {
    if (array.length === 0) return [];
    return array!.map((n) => {
      return {
        ...n,
        children: n.children.filter((n) =>
          showDeletedNotes ? true : !n.isDeleted,
        ),
        onChildAdd: (newTitle) => {
          addNote({ title: newTitle, pageId: id, parentId: n.id });
        },
        onEditCancel: () => undefined,
        onEditConfirm: (newTitle) => renameNote({ id: n.id, newTitle }),
        onRemove: () => removeNote(n.id),
        onRecover: () => recoverNote(n.id),
        childItems: recursivelyMapChildren(
          n.children.filter((n) => (showDeletedNotes ? true : !n.isDeleted)),
        ),
        isCollapsed: collapsedItems.includes(n.id),
      };
    });
  };

  const childItems = recursivelyMapChildren(notes.filter((n) => !n.parentId))!;

  const handleNoteAdd = (newTitle: string) => {
    addNote({ pageId: id, title: newTitle });
  };

  return (
    <TreeItem
      level={0}
      id={id!}
      isDeleted={isDeleted}
      title={title!}
      childItems={childItems.filter((c) =>
        showDeletedNotes ? true : !c.isDeleted,
      )}
      isSelectable={false}
      collapseOnSelect={true}
      onChildAdd={handleNoteAdd}
      isCollapsed={collapsedItems.includes(id)}
      onRemove={() => onRemove()}
      onRecover={() => onRecover()}
      onEditCancel={() => onEditCancel?.()}
      onEditConfirm={(newItem) => onEditConfirm({ id, title: newItem })}
      isEditMode={isEditMode}
    />
  );
};
