import { QuestionBankOverallStatisticsInput } from "@/api/src/server/routers/statistics/types";
import { trpc } from "@/utils/trpc";

const useQuestionBankOverallStatistics = (
  input: QuestionBankOverallStatisticsInput,
) => {
  return trpc.statistics.getQuestionBankOverallStatistics.useQuery(input, {
    enabled: !!input.questionBankId || !!input.questionBankEndpoint,
  });
};
export default useQuestionBankOverallStatistics;
