import React, { Fragment, PropsWithChildren, useRef } from "react";
import { Rnd } from "react-rnd";
import { PropsWithClassName } from "@/types";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { cn } from "@/lib/utils";
import { Setter } from "@/types";

type DraggableWindowProps = {
  title: string;
  titleClassName?: string;
  visible: boolean;
  onVisibleChange: Setter<boolean>;
  withOverlay?: boolean;
  handleClassName?: string;
  withMoveIcon?: boolean;
  viewportClassName?: string;
} & PropsWithChildren &
  PropsWithClassName;
export const DraggableWindow = React.forwardRef<
  HTMLDivElement,
  DraggableWindowProps
>(
  (
    {
      children,
      className,
      titleClassName,
      title,
      visible = true,
      onVisibleChange,
      withOverlay = false,
      handleClassName,
      withMoveIcon = true,
      viewportClassName,
    },
    ref,
  ) => {
    if (!visible) return <></>;
    return (
      <>
        {withOverlay && (
          <div
            onClick={() => onVisibleChange(false)}
            className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-black opacity-50 z-[99999] backdrop-blur-md"
          ></div>
        )}
        <Rnd
          enableResizing
          bounds={"window"}
          dragHandleClassName="handle"
          default={{
            width: 800,
            height: 500,
            x: window.innerWidth / 2 - 400,
            y: window.innerHeight / 2 - 250,
          }}
          key={title + "window"}
          className="z-[100000] h-full"
        >
          <div
            key={title + "window"}
            className={cn(
              "relative rounded-primary flex flex-col justify-start items-center border border-gray-300 dark:border-nbme-primary-dark-600 bg-white dark:bg-nbme-primary-dark-700 shadow-lg",
              className,
            )}
          >
            <div
              className={twMerge(
                "absolute handle py-3 text-lg flex flex-row justify-center items-center w-full px-3 bg-nbme-primary-600 dark:bg-nbme-primary-dark-900 rounded-t-primary cursor-default lg:cursor-move text-white z-[100001] h-14",
                handleClassName,
              )}
            >
              <div className="relative flex flex-row justify-center items-center w-full">
                <p
                  className={cn(
                    "absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] font-bold text-sm md:text-base",
                    titleClassName,
                  )}
                >
                  {title}
                </p>
              </div>

              <button type="button" onClick={() => onVisibleChange(false)}>
                <FontAwesomeIcon icon={faCircleXmark} />
              </button>
              {withMoveIcon && (
                <FontAwesomeIcon
                  icon={faUpDownLeftRight}
                  className="hidden lg:block absolute left-3 top-3 w-4 h-4"
                />
              )}
            </div>
          </div>

          <div
            className={twMerge(
              "flex flex-col justify-start items-center w-full h-full rounded-b-primary overflow-y-scroll pt-14",
              viewportClassName,
            )}
          >
            {children}
          </div>
        </Rnd>
      </>
    );
  },
);
