"use client";
import * as RadixLabel from "@radix-ui/react-label";
import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface LabelProps extends PropsWithChildren {
  htmlFor: string;
  className?: string;
  error?: boolean;
  style?: React.CSSProperties;
}

export const Label = ({
  children,
  htmlFor,
  className,
  error,
  style,
}: LabelProps) => {
  return (
    <RadixLabel.Root
      htmlFor={htmlFor}
      className={twMerge(
        "text-sm lg:text-base transition-colors duration-100",
        className,
        error && "text-red-500",
      )}
      style={style}
    >
      {children}
    </RadixLabel.Root>
  );
};
