import { AnimatePresence, motion } from "framer-motion";
import useNbmeSidePanelContext from "./hooks/use-nbme-side-panel-context";
import { cn } from "@/lib/utils";
import { PropsWithChildren, useState } from "react";
import { PropsWithClassName } from "@/types";
import { DashboardScrollArea } from "../DashboardScrollArea";
import { smoothSpring } from "@/assets/framer/springs";
import useOnClickOutside from "@/hooks/use-on-click-outside";
import { createPortal } from "react-dom";
import ScrollArea from "../ui/scroll-area";

type NbmeSidePanelBodyProps = PropsWithChildren & PropsWithClassName;
const Body = ({ children, className }: NbmeSidePanelBodyProps) => {
  const {
    open,
    setOpen,
    bodyRef,
    draggable,
    setDraggable,
    closeOnClickOutside,
    emergeFrom,
  } = useNbmeSidePanelContext();
  useOnClickOutside(() => {
    if (!closeOnClickOutside) return;
    if (open) setOpen(false);
  }, bodyRef);
  return createPortal(
    <AnimatePresence mode="wait">
      {open && (
        <motion.div
          ref={bodyRef}
          tabIndex={0}
          className={cn(
            "fixed top-0 min-h-screen h-screen overflow-y-auto px-3 pb-4 bg-nbme-primary-50 warm:bg-brown-100 dark:bg-neutral-100 min-w-[20rem] z-[9999] flex flex-col justify-start items-center shadow-lg",
            "border-l border-l-gray-300 warm:border-l-brown-200 transition-colors",
            className,
            emergeFrom === "right" && "right-0",
            emergeFrom === "left" && "left-0",
          )}
          initial={{ x: emergeFrom === "right" ? "100%" : "-100%" }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          exit={{
            x: emergeFrom === "right" ? "100%" : "-100%",
            opacity: 0,
          }}
          transition={smoothSpring}
          drag={draggable ? "x" : false}
          dragSnapToOrigin
          dragElastic={{ left: 0 }}
          dragConstraints={{ left: 0 }}
          style={{ touchAction: "none" }}
          onDragEnd={(event, info) => {
            if (!bodyRef || !bodyRef.current) return;
            const bodyDimensions = bodyRef.current?.getBoundingClientRect();
            if (info.offset.x > bodyDimensions.width / 3) setOpen(false);
          }}
        >
          <ScrollArea.Root className="w-full h-full">
            <ScrollArea.Viewport className={cn("w-full h-full", className)}>
              {children}
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar orientation="vertical">
              <ScrollArea.Thumb />
            </ScrollArea.Scrollbar>
            <ScrollArea.Corner />
          </ScrollArea.Root>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body,
  );
};

export default Body;
