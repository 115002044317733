"use client";

import { useState } from "react";
import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";

interface UserStats {
  id: string;
  label: string;
  count: number;
}

export function DangerZoneSection() {
  const { currentUser: user, reset } = useAuthSlice();
  const cantResetAnyMore = !!user?.lastResetAt;
  const [password, setPassword] = useState("");

  const handleReset = async () => {
    reset(password);
  };

  return (
    <div className="space-y-4 rounded-lg border-2 border-dashed border-red-500/50 bg-red-500/5 p-3 sm:p-4 dark:bg-red-500/10">
      <div className="space-y-4">
        <div>
          <h2 className="text-base font-semibold text-red-500 sm:text-lg">
            Danger Zone
          </h2>
          <p className="text-xs text-red-500/80 sm:text-sm">
            Actions here can&apos;t be undone. Please proceed with caution.
          </p>
        </div>

        <div className="rounded-md border border-red-500/20 p-4">
          <div className="space-y-4">
            <div>
              <h3 className="text-sm font-medium text-red-500">
                Reset Account Data
              </h3>
              <p className="text-xs text-red-500/70">
                This action cannot be undone.
              </p>
            </div>

            {/*
            <div className="space-y-3">
              {userStats.map((stat) => (
                <div key={stat.id} className="flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id={stat.id}
                      checked={selectedItems.includes(stat.id)}
                      onCheckedChange={(checked) => {
                        setSelectedItems(
                          checked ? [...selectedItems, stat.id] : selectedItems.filter((i) => i !== stat.id),
                        )
                      }}
                    />
                    <Label
                      htmlFor={stat.id}
                      className="text-sm dark:text-neutral-300 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {stat.label}
                    </Label>
                  </div>
                  <span className="text-sm text-muted-foreground">{stat.count}</span>
                </div>
              ))}
            </div>


              */}
            <div className="flex flex-col justify-start items-start space-y-2">
              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    variant="destructive"
                    className="w-full sm:w-auto"
                    disabled={cantResetAnyMore}
                  >
                    Reset Account
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle className="text-lg sm:text-xl">
                      Are you absolutely sure?
                    </DialogTitle>
                    <DialogDescription className="space-y-2 text-sm sm:text-base">
                      <p className="text-red-400">
                        This action cannot be undone. This will permanently
                        reset all data in your account across all question
                        banks.
                      </p>

                      <p className="bg-red-500 text-white p-3 rounded-lg underline font-semibold">
                        Your account will return to its original state when you
                        first signed up
                      </p>
                      {/*<ul className="list-inside list-disc space-y-1 pl-4">
                        selectedItems.map((id) => {
                        const stat = userStats.find((s) => s.id === id);
                        return (
                          <li key={id}>
                            {stat?.label} ({stat?.count})
                          </li>
                        );
                      })
                      </ul>*/}
                    </DialogDescription>
                  </DialogHeader>
                  <div className="space-y-4 py-4">
                    <div className="space-y-2">
                      <Label
                        htmlFor="password"
                        className="text-sm sm:text-base"
                      >
                        Please enter your password to confirm this action:
                      </Label>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        className="text-sm sm:text-base"
                      />
                    </div>
                  </div>
                  <DialogFooter>
                    <DialogClose asChild>
                      <Button
                        variant="destructive"
                        onClick={handleReset}
                        className="w-full sm:w-auto"
                        disabled={!password}
                      >
                        Reset Account
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              {cantResetAnyMore && (
                <p className="text-xs text-red-400">
                  You've already reset your account once,{" "}
                  <a
                    className="underline text-red-500"
                    href="https://t.me/UW_Coursology"
                    target="_blank"
                  >
                    Contact us
                  </a>{" "}
                  if you want to reset again
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
