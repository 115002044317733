import useQuestionBankLibrary from "@/hooks/library/use-questionbank-library";
import GenericPage from "./generic-page";
import { useParams } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";
import SearchBar from "./components/search-bar";
import { buttonVariants } from "@/ui/button";
import ArticleIcon from "@/components/icons/hollow/article";
import { DateTime } from "luxon";
import {
  useMedicalLibraryLibrarySearch,
  useMedicalLibraryRouter,
  useMedicalLibraryVariant,
} from "../../store";
import { cn, range } from "@/lib/utils";
import { Skeleton } from "@/components/ui/skeleton";
import SearchResult from "./components/search-result";
import _ from "underscore";
import Text from "@/components/ui/text";

export default function BookmarksPage() {
  const { qbankEndpoint } = useParams({ strict: false });
  const { data: library, isLoading: isLibraryLoading } = useQuestionBankLibrary(
    {
      questionBankEndpoint: qbankEndpoint ?? "",
    },
  );
  const [librarySearch, setLibrarySearch] = useMedicalLibraryLibrarySearch();
  const { query } = librarySearch;

  const bookmarkedArticles = useMemo(() => {
    //@ts-expect-error date types collapse to strings
    const articles: {
      id: string;
      name: string;
      usage: { bookmarkedAt: string };
    }[] = [
      ...(library?.articles ?? []),
      ...(library?.categories.flatMap((c) => c.articles) ?? []),
    ].filter((a) => !!a.usage.bookmarkedAt);
    return _.uniq(
      _.sortBy(articles, (a) => a.usage.bookmarkedAt),
      true,
      (a) => a.id,
    );
  }, [library]);

  useEffect(() => {
    setLibrarySearch({ ...librarySearch, page: 1 });
  }, [query]);

  return (
    <GenericPage
      header={
        <h1 className="text-3xl font-semibold warm:text-brown-800">
          Bookmarks
        </h1>
      }
    >
      {isLibraryLoading && <ArticleListSkeleton />}
      {bookmarkedArticles.length === 0 && !isLibraryLoading && (
        <NoBookmarksYet />
      )}
      {bookmarkedArticles.length !== 0 && (
        <div className="flex flex-col justify-start items-start w-full gap-y-5">
          <SearchBar />
          {!query && <BookmarkedArticles articles={bookmarkedArticles} />}
        </div>
      )}
      <SearchResult inBookmarks={true} />
    </GenericPage>
  );
}

const ArticleListSkeleton = () => {
  return (
    <div className="w-full h-full flex flex-col justify-start items-start gap-y-3">
      {range(10).map(() => (
        <Skeleton className="w-full h-8 rounded-primary" />
      ))}
    </div>
  );
};

const NoBookmarksYet = () => {
  return (
    <div className="flex flex-col justify-start items-start w-full gap-2">
      <h2 className="text-gray-600 text-lg font-semibold">
        It looks like you don't have any bookmarks saved yet!
      </h2>
      <p className="text-gray-500">
        No problem - Creating bookmarks is quick and easy. Whether you're saving
        important resources, revisiting your favorite content, or keeping track
        of must-know materials, our bookmarking feature helps you stay organized
        and productive. Start building your collection by browsing content and
        clicking the star icon in your article tools bar. Your bookmarks will
        always be just a click away, ready for when you need them.
      </p>
    </div>
  );
};

const BookmarkedArticles = ({
  articles,
}: {
  articles: { id: string; name: string; usage: { bookmarkedAt: string } }[];
}) => {
  return (
    <div className="flex flex-col justify-start items-start gap-y-3 w-full">
      {articles.map((a, index) => (
        <Article {...a} key={index} />
      ))}
    </div>
  );
};

const Article = ({
  id,
  name,
  usage,
}: {
  id: string;
  name: string;
  usage: {
    bookmarkedAt: string;
  };
}) => {
  const router = useMedicalLibraryRouter();
  const dateAddedOn = DateTime.fromJSDate(
    new Date(usage.bookmarkedAt),
  ).toLocaleString(DateTime.DATE_MED);

  return (
    <div
      className={buttonVariants({
        variant: "ghost",
        className: cn(
          "w-full flex flex-row justify-between cursor-pointer flex-wrap h-full",
        ),
      })}
      onClick={() => router.push(id)}
    >
      <div className="flex flex-row justify-start items-center gap-x-2">
        <ArticleIcon />
        <span className="capitalize truncate w-full">{name}</span>
      </div>
      <Text>Added on {dateAddedOn}</Text>
    </div>
  );
};
