import RecoverIcon from "@/components/icons/recover-icon";
import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogPortal,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogTrigger,
  AlertDialogDescription,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
  TooltipPortal,
} from "@/components/ui/tooltip";
import useRecoverTest from "@/hooks/test-hooks/use-recover-test";
import {
  faArrowDown,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const RecoverTestButton = ({ testId }: { testId: number }) => {
  const { mutate: recoverTest } = useRecoverTest();
  const [recoverQuestionStates, setRecoverQuestionStates] = useState(false);
  return (
    <AlertDialog>
      <AlertDialogOverlay />
      <AlertDialogTrigger>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger>
              <Button variant={"ghost"} size={"icon"} className="w-4 h-4">
                <RecoverIcon />
              </Button>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent className="capitalize pointer-events-none">
                Recover This Test
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Recover this test?</AlertDialogTitle>
            <AlertDialogDescription>
              <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
              You are about to recover this test from your deleted tests list,
              are you sure you want to continue? You have the option to recover
              quesiton states as well below{" "}
              <FontAwesomeIcon icon={faArrowDown} />
              <div className="mt-3 flex flex-row justify-between items-center w-full">
                <p className="font-medium">
                  Recover submitted questions to their previous states?
                  (Correct, Incorrect, Omitted, etc...)?
                </p>
                <Switch
                  checked={recoverQuestionStates}
                  onCheckedChange={setRecoverQuestionStates}
                />
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => recoverTest({ testId, recoverQuestionStates })}
            >
              Recover
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};

export default RecoverTestButton;
