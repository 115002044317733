import NbmeSettingsWindowsSection from "@/components/common/nbme/NbmeSettingsWindowsSection";
import NbmeSidePanel from "@/components/nbme-side-panel";
import { NbmeSettingsBarSection } from "@/components/NbmeSettingsBarSection";
import { NbmeSettingsExhibitStyleControl } from "@/components/NbmeSettingsExhibitStyleControl";
import { NbmeSettingsFontSizeControl } from "@/components/NbmeSettingsFontSizeControl";
import { NbmeSettingsFontWeightSegment } from "@/components/NbmeSettingsFontWeightSegment";
import { NbmeSettingsHighlightsSection } from "@/components/NbmeSettingsHighlightsSection";
import { NbmeSettingsSegment } from "@/components/NbmeSettingsSegment";
import { NbmeSettingsThemeControl } from "@/components/NbmeSettingsThemeControl";
import { useMedicalLibraryIsSettingsVisible } from "../store";

const SettingsSidebar = () => {
  const [settingsVisible, setSettingsVisible] =
    useMedicalLibraryIsSettingsVisible();
  return (
    <NbmeSidePanel.Root
      open={settingsVisible}
      onOpenChanged={setSettingsVisible}
      closeOnClickOutside={false}
      setCloseOnClickOutside={() => false}
    >
      <NbmeSidePanel.Body className="max-w-xs pe-0">
        <NbmeSidePanel.Header className="z-[999]">
          <NbmeSidePanel.Title>Settings</NbmeSidePanel.Title>
          <NbmeSidePanel.CloseButton />
        </NbmeSidePanel.Header>
        <div className="flex flex-col justify-start items-center gap-2 w-full h-full py-3 pe-4">
          <AppearanceSection />
          <NbmeSettingsHighlightsSection />
          <NbmeSettingsWindowsSection />
        </div>
      </NbmeSidePanel.Body>
    </NbmeSidePanel.Root>
  );
};

export default SettingsSidebar;

const AppearanceSection = () => {
  return (
    <NbmeSettingsBarSection title={"Appearance"}>
      <NbmeSettingsSegment>
        <p className="text-xs">Font Size</p>
        <NbmeSettingsFontSizeControl />
      </NbmeSettingsSegment>
      <NbmeSettingsFontWeightSegment />
      <NbmeSettingsSegment>
        <p className="text-xs">Color Theme</p>
        <NbmeSettingsThemeControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Exhibit Style</p>
        <NbmeSettingsExhibitStyleControl />
      </NbmeSettingsSegment>
    </NbmeSettingsBarSection>
  );
};
