import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import React, { Children, createContext, ReactElement } from "react";
import useRequiredContext from "@/hooks/use-required-context";
import { smoothTransition } from "@/assets/framer";

export type CircularProgressBarContext = {
  size: number;
  isClickable?: boolean;
  /**Progress in percentages*/
  progress: number[];
};

export const CircularProgressBarContext =
  createContext<CircularProgressBarContext>({
    size: 200,
    isClickable: false,
    progress: [],
  });

interface CicularProgressBarRootProps
  extends React.ComponentPropsWithoutRef<"div">,
    Omit<CircularProgressBarContext, "size"> {
  size?: number;
}

export const CicularProgressBarRoot = React.forwardRef<
  React.ElementRef<"div">,
  CicularProgressBarRootProps
>(({ className, size, isClickable, progress, ...props }, ref) => (
  <CircularProgressBarContext.Provider
    value={{ size: size ?? 200, isClickable, progress }}
  >
    <div
      ref={ref}
      className={cn(
        "relative flex flex-col justify-center items-center p-8",
        className,
      )}
      {...props}
    />
  </CircularProgressBarContext.Provider>
));

interface CircularProgressBarRendererProps
  extends React.ComponentPropsWithoutRef<typeof motion.svg> {
  children:
    | (
        | ReactElement<typeof CircularProgressBarBackground>
        | ReactElement<typeof CircularProgressBarProgress>
      )
    | (
        | ReactElement<typeof CircularProgressBarBackground>
        | ReactElement<typeof CircularProgressBarProgress>
      )[];
}

export const CircularProgressBarRenderer = React.forwardRef<
  React.ElementRef<typeof motion.svg>,
  CircularProgressBarRendererProps
>(({ className, children, ...props }, ref) => {
  const { size, progress } = useRequiredContext(CircularProgressBarContext);
  let backgroundChildren = 0;
  let progressChildren = 0;

  React.Children.toArray(children).forEach((c) => {
    if (!React.isValidElement(c)) {
      throw Error(
        `Rendered does not accept regular children, please use Progress, or Background`,
      );
    }
    if (c.type === CircularProgressBarProgress) {
      progressChildren++;
    }
    if (c.type === CircularProgressBarBackground) {
      backgroundChildren++;
    }
  });
  if (backgroundChildren > 1) {
    throw Error(`More than 1 background present!`);
  }

  if (progress.length !== progressChildren) {
    throw Error(`More progress components than provided progress numbers`);
  }

  const fractions = progress.map((p) => p / 100);
  let total = 0;
  fractions.forEach((p) => (total += p));
  if (total > 1.5 || total < 0) {
    throw Error(`Progresses are invalid, total: ${total}`);
  }

  return (
    <motion.svg
      ref={ref}
      className={cn("absolute top-[0.5] left-[0.5]", className)}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      {...props}
    >
      {React.Children.map(children, (c, index) => {
        if (c.type === CircularProgressBarProgress) {
          const fraction = fractions[index - backgroundChildren] ?? 0;

          let previousFractions = 0;
          for (let i = index - backgroundChildren; i > 0; i--) {
            const previousFraction = fractions[i - 1];
            if (previousFraction === undefined) return;
            previousFractions += previousFraction;
          }
          if (fraction === 0) return;
          return React.cloneElement(c, {
            //@ts-expect-error React doesn't seem to infer that this is a motion component
            animate: { pathLength: fraction, pathOffset: previousFractions },
          });
        }
        return c;
      })}
    </motion.svg>
  );
});

export const CircularProgressBarBackground = React.forwardRef<
  React.ElementRef<typeof motion.circle>,
  React.ComponentPropsWithoutRef<typeof motion.circle>
>(({ className, ...props }, ref) => {
  const { size, isClickable } = useRequiredContext(CircularProgressBarContext);

  return (
    <motion.circle
      ref={ref}
      key={"background"}
      data-is-clickable={isClickable}
      className={cn(
        "stroke-[9] stroke-gray-100 dark:stroke-neutral-600 fill-none transition-colors duration-[500ms] data-[is-clickable=true]:cursor-pointer",
        className,
      )}
      cy={size / 2}
      cx={size / 2}
      r={size / 2 - 20}
      style={{
        rotate: -90,
        pathOffset: 0,
        pathLength: 1,
      }}
      {...props}
    />
  );
});

interface CircularProgressBarProgressProps
  extends React.ComponentPropsWithoutRef<typeof motion.circle> {}

export const CircularProgressBarProgress = React.forwardRef<
  React.ElementRef<typeof motion.circle>,
  CircularProgressBarProgressProps
>(({ className, ...props }, ref) => {
  const { size, isClickable } = useRequiredContext(CircularProgressBarContext);
  return (
    <motion.circle
      ref={ref}
      data-is-clickable={isClickable}
      className={cn(
        "stroke-[10] stroke-lime-500 fill-none transition-colors duration-[500ms] z-[3]",
        "data-[is-clickable=true]:cursor-pointer",
        className,
      )}
      cy={size / 2}
      cx={size / 2}
      r={size / 2 - 20}
      style={{ rotate: -90 }}
      initial={{ pathLength: 0, pathOffset: 0 }}
      transition={{ ...smoothTransition, duration: 1 }}
      {...props}
    />
  );
});

export const CircularProgressBarContent = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "relative flex flex-col justify-center items-center",
      className,
    )}
    {...props}
  />
));

/**
 * Basic Anatomy
 * ```tsx
 * <CircularProgressBar.Root>
 *   <CircularProgressBar.Renderer>
 *     <CircularProgressBar.Background/>
 *     <CircularProgressBar.Progress/>
 *     <CircularProgressBar.Progress/>
 *     <CircularProgressBar.Progress/>
 *   </CircularProgressBar.Renderer>
 *   <CircularProgressBar.Content>
 *     {middleContent}
 *   </CircularProgressBar.Content>
 * </CircularProgressBar.Root>
 *
 *
 *
 *
 *
 * ```*/
const CircularProgressBar = {
  Root: CicularProgressBarRoot,
  Renderer: CircularProgressBarRenderer,
  Background: CircularProgressBarBackground,
  Progress: CircularProgressBarProgress,
  Content: CircularProgressBarContent,
};

export default CircularProgressBar;
