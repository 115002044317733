interface SubscriptionSectionProps {
  plan: string
  status: string
  lastRenewed: string
  endsAt: string
}

export function SubscriptionSection({ plan, status, lastRenewed, endsAt }: SubscriptionSectionProps) {
  return (
    <div className="space-y-4 rounded-lg border border-border bg-card p-3 sm:p-4">
      <h2 className="text-lg font-bold sm:text-xl mb-6">Subscription</h2>
      <div className="grid gap-4 sm:grid-cols-2">
        <div className="space-y-1">
          <dt className="text-sm font-medium text-muted-foreground">Current Plan</dt>
          <dd className="text-sm sm:text-base">{plan}</dd>
        </div>
        <div className="space-y-1">
          <dt className="text-sm font-medium text-muted-foreground">Status</dt>
          <dd className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-600">
            {status}
          </dd>
        </div>
        <div className="space-y-1">
          <dt className="text-sm font-medium text-muted-foreground">Last Renewed</dt>
          {lastRenewed && <dd className="text-sm sm:text-base">{lastRenewed}</dd>}
        </div>
        <div className="space-y-1">
          <dt className="text-sm font-medium text-muted-foreground">Expiration Date</dt>
          <dd className="text-sm sm:text-base">{endsAt}</dd>
        </div>
      </div>
    </div>
  )
}

