import CircularProgressBar from "@/components/ui/circular-progress-bar";
import { motion } from "framer-motion";
import { getCenterText, getPercentages } from "./utils";
import { useState } from "react";
import { fadeAnimation } from "@/assets/framer";
import { LoadingSpinner } from "@/components/LoadingSpinner";

interface DashboardUsageStatsCircleProps {
  used: number;
  total: number;
  isLoading?: boolean;
}

export const DashboardUsageStatsCircle = ({
  used,
  total,
  isLoading,
}: DashboardUsageStatsCircleProps) => {
  const [activePercentage, setActivePercentage] = useState<number>(0);
  const percentages = getPercentages(used, total);
  const centerText = getCenterText(activePercentage);

  return (
    <CircularProgressBar.Root isClickable progress={percentages}>
      <CircularProgressBar.Renderer>
        <CircularProgressBar.Background />
        <CircularProgressBar.Progress
          className={`stroke-sky-500 hover:stroke-sky-500  ${
            activePercentage !== 0
              ? "stroke-sky-100 dark:stroke-sky-800 dark:hover:stroke-sky-500"
              : ""
          }`}
          onClick={() => setActivePercentage(0)}
        />
        <CircularProgressBar.Progress
          className={`stroke-gray-400 hover:stroke-gray-400 dark:stroke-neutral-500 ${
            activePercentage !== 1
              ? "stroke-gray-100 dark:stroke-neutral-700 dark:hover:stroke-neutral-500"
              : ""
          }`}
          onClick={() => setActivePercentage(1)}
        />
      </CircularProgressBar.Renderer>
      <CircularProgressBar.Content>
        <motion.div
          key={isLoading ? "loading" : centerText}
          {...fadeAnimation}
          transition={{ ease: "backOut", duration: 0.9 }}
          className="flex flex-col justify-center items-center select-none"
        >
          {isLoading ? (
            <LoadingSpinner className="w-12 h-12" />
          ) : (
            <>
              <p className="text-xl text-gray-700 dark:text-neutral-200 transition-colors">
                {percentages[activePercentage]!.toFixed(1)}%
              </p>
              <p className="text-gray-500 font-light dark:text-neutral-300 transition-colors">
                {centerText}
              </p>
            </>
          )}
        </motion.div>
      </CircularProgressBar.Content>
    </CircularProgressBar.Root>
  );
};
