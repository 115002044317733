import { GetTestSlotInput } from "@/api/src/server/routers/test-slot-router";
import { range } from "@/lib/utils";
import { trpc } from "@/utils/trpc";
import { useEffect } from "react";

const useTestSlot = (
  input: Partial<GetTestSlotInput>,
  opts?: {
    slots: {
      isMarked: boolean;
      order: number;
      isSubmitted: boolean;
      chosen: { correct: boolean | null } | null;
    }[];
  },
) => {
  const utils = trpc.useUtils();

  const query = trpc.testSlot.getTestSlot.useQuery(
    input as Required<GetTestSlotInput>,
    {
      enabled: !!input.testId && input.slot !== undefined,
      //We set staleTime because the getTestSlot query is somewhat expensive on the database
      staleTime: 1000 * 60 * 10, // 10 Minutes because time is specified in milliseconds
      placeholderData: (previous) => previous,
    },
  );

  useEffect(() => {
    //Load marked or submitted questions
    //because the user can go back to those
    opts?.slots
      .filter((s) => s.isMarked || s.isSubmitted || s.chosen)
      .forEach((ts) => {
        const oldData = utils.testSlot.getTestSlot.getData({
          testId: input.testId ?? 0,
          slot: ts.order,
        });
        if (!oldData) {
          utils.testSlot.getTestSlot.prefetch({
            testId: input.testId ?? 0,
            slot: ts.order,
          });
        }
      });

    //prefetch next and previous slots (if possible) and no previous data is present
    if (input.slot && input.slot > 0) {
      const oldData = utils.testSlot.getTestSlot.getData({
        testId: input.testId ?? 0,
        slot: (input.slot ?? 0) - 1,
      });
      if (!oldData) {
        utils.testSlot.getTestSlot.prefetch({
          testId: input.testId ?? 0,
          slot: (input.slot ?? 0) - 1,
        });
      }
    }
    if (query.data && input.slot && input.slot < query.data.slots - 1) {
      const oldData = utils.testSlot.getTestSlot.getData({
        testId: input.testId ?? 0,
        slot: (input.slot ?? 0) + 1,
      });
      if (!oldData) {
        utils.testSlot.getTestSlot.prefetch({
          testId: input.testId ?? 0,
          slot: (input.slot ?? 0) + 1,
        });
      }
    }
  }, [input.testId, input.slot, query.data?.slots, opts, opts?.slots]);

  return query;
};
export default useTestSlot;
