import SignupCard from "@/components/SignupCard";
import { useTitle } from "@/hooks/common/use-title";
import { useRequiresNoUser } from "@/utils/customHooks";
import { createFileRoute } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";
import { z } from "zod";

const signupSearchSchema = z.object({
  promocode: z.string().optional(),
});

export const Route = createFileRoute("/auth/signup/")({
  component: SignupPage,
  validateSearch: zodValidator(signupSearchSchema),
});

function SignupPage() {
  useTitle("Sign Up");
  useRequiresNoUser();
  return <SignupCard />;
}
