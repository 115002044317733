import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faCircleStop,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { useCanSubmit } from "@/utils/stores/testStore";
import useTestPageTestSession from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-test-session";
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogPortal,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogTrigger,
  AlertDialogDescription,
} from "./ui/alert-dialog";
import { useNavigate } from "@tanstack/react-router";
import { buttonVariants } from "./ui/button";
import useCreateTest from "@/hooks/test-hooks/use-create-test";
import { toast } from "sonner";
import useSubmitTest from "@/hooks/test-hooks/use-submit-test";
import { trpc } from "@/utils/trpc";

export const NbmeEndTestButton = () => {
  const { data: test } = useTestPageTestSession();
  const submitted = test?.submitted;
  const [canSubmit] = useCanSubmit();
  const navigate = useNavigate();
  const { mutate: submitTest } = useSubmitTest();
  const { mutate: createTest } = useCreateTest();
  const utils = trpc.useUtils();
  const omittedQuestions = (test?.slots.filter((s) => !s.chosen) ?? []).length;
  const hasNext = !!test?.blueprint.next;

  return (
    <AlertDialog>
      <AlertDialogOverlay className="bg-black/50" />
      <AlertDialogTrigger>
        <NbmeIconNavbarButton
          disabled={!canSubmit}
          className="w-full md:w-auto"
          icon={
            <div className="flex flex-row justify-center items-center bg-white rounded-full">
              <FontAwesomeIcon
                icon={faCircleStop}
                className="w-6 h-6 text-red-500"
              />
            </div>
          }
          label={submitted ? "End Review" : "End Block"}
        />
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {submitted ? "End Review?" : "End Block?"}
            </AlertDialogTitle>
            <AlertDialogDescription className="flex flex-col justify-start items-start gap-y-2">
              {submitted ? (
                'You can return to it any time in the "Previous Tests" page in your dashboard.'
              ) : (
                <>
                  {!!omittedQuestions && (
                    <p className="font-medium text-red-600">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-1"
                      />
                      You still have {omittedQuestions} question
                      {omittedQuestions > 1 ? "s" : ""} with no choice selected,
                      these questions will be marked as omitted if you submit
                      this test.
                    </p>
                  )}
                  <p>
                    You will not be able to change your answers after ending the
                    test.
                  </p>
                  {hasNext && (
                    <p className="text-sm">
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        className="me-1"
                      />
                      Since you're practicing in ordered test mode, you have the
                      option to navigate directly to the next block in this
                      question bank without viewing the result of the current
                      block (You will still end the current block).
                    </p>
                  )}
                </>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className={
                !submitted && hasNext
                  ? buttonVariants({ variant: "outline" })
                  : undefined
              }
              onClick={() => {
                if (!test) return;
                return submitted
                  ? navigate({
                      from: "/qbanks/$qbankEndpoint/tests/$testId",
                      to: "/qbanks/$qbankEndpoint/dashboard/previous-tests",
                    })
                  : submitTest({ testId: test.id });
              }}
            >
              End {!submitted && hasNext && <>Only</>}
            </AlertDialogAction>
            {!submitted && hasNext && (
              <AlertDialogAction
                onClick={() => {
                  if (!test) return;
                  createTest(
                    {
                      test: {
                        questionBankId: test.questionBankId,
                        mode: test.mode
                          .split(",")
                          .filter((m) => !!m.trim()) as ("tutor" | "timed")[],
                        minutesPerQuestion: test.minutesPerQuestion,
                        parentId: null,
                      },
                      options: {
                        type: "ordered",
                        orderedTestBlueprintOrder:
                          test.blueprint.next?.order ?? 0,
                      },
                    },
                    {
                      onSuccess: (data) => {
                        //Reseting the test slot query to remove previousData
                        utils.testSlot.getTestSlot.reset().then(() => {
                          navigate({
                            from: "/qbanks/$qbankEndpoint/tests/$testId",
                            to: "/qbanks/$qbankEndpoint/tests/$testId",
                            params: { testId: String(data.id) },
                          });
                        });
                        submitTest({ testId: test.id });
                      },
                      onError: (err) => {
                        toast.error(
                          `Could not create next test, an error ocurred: ${err.message}`,
                        );
                      },
                    },
                  );
                }}
              >
                Next Block
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};
