import useClearQuestionHighlights from "@/hooks/highlight-hooks/use-clear-question-highlights";
import { useCallback } from "react";
import { useTestPageSelectedTestSlot } from "./use-test-page-selected-test-slot";

const useTestPageClearQuestionHighlights = () => {
  const { mutate: clearHighlights } = useClearQuestionHighlights();
  const { data: slot } = useTestPageSelectedTestSlot();
  const selectedQuestion = slot?.question;
  return useCallback(() => {
    if (!selectedQuestion) return;
    clearHighlights({ questionId: selectedQuestion.id });
  }, [clearHighlights, selectedQuestion]);
};
export default useTestPageClearQuestionHighlights;
