import QuestionCountBadge from "./QuestionCountBadge";
import LabeledCheckbox from "./LabeledCheckbox";
import Accordion from "./Accordion";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import AccordionItem from "./AccordionItem";
import TopicCheckbox from "./TopicCheckbox";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import useCreateTestPageQuestionBankUsage from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-createtest-page-questionbank-usage";
import { cn } from "@/lib/utils";

interface SystemCheckBoxProps {
  questionCount: number;
  systemId: string;
  systemName: string;
  checked: boolean;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const SystemCheckbox = ({
  checked,
  systemId,
  systemName,
  questionCount,
  onClick,
  className,
  isLoading,
}: SystemCheckBoxProps) => {
  const { data: usage } = useCreateTestPageQuestionBankUsage();
  const { toggleSelection } = useCreateTestPageStore();
  const topicIds = Object.keys(usage?.systems[systemId]?.topics ?? {});

  const disabled = questionCount === 0;
  const [toggledSection, setToggledSection] = useState<string[]>([]);
  const toggled = toggledSection.includes(systemId);

  const collapsible = topicIds.length > 1;

  return (
    <Accordion
      value={toggledSection}
      onValueChange={(value) => setToggledSection(value)}
      className={cn(
        "w-full max-w-md transition-opacity",
        isLoading && "opacity-50 pointer-events-none",
      )}
    >
      <div
        className={twMerge(
          "flex flex-col justify-start items-start",
          toggled && "gap-2 mb-5",
        )}
      >
        <div className="flex justify-between items-center w-full gap-3">
          <LabeledCheckbox
            className={className}
            id={`${systemId}-subject-checkbox`}
            checked={checked && questionCount !== 0}
            labelClassName="md:text-sm lg:text-sm"
            checkBoxClassName="min-w-max"
            onClick={onClick}
            disabled={disabled}
            label={systemName.at(0)!.toUpperCase() + systemName.slice(1)}
          />
          <div className="flex flex-row justify-start items-center gap-2">
            <QuestionCountBadge count={questionCount} />
            <button
              type="button"
              className={twMerge(
                "text-gray-500 warm:text-brown-500 invisible pointer-events-none",
                disabled && "text-gray-300 warm:text-brown-300",
                collapsible && "visible pointer-events-auto",
              )}
              onClick={() =>
                toggled ? setToggledSection([]) : setToggledSection([systemId])
              }
            >
              <FontAwesomeIcon icon={toggled ? faMinus : faPlus} />
            </button>
          </div>
        </div>
        <AccordionItem value={systemId} className="">
          <div
            className={cn(
              "lg:ml-6 ml-3 flex flex-col justify-start items-center lg:items-start gap-1 w-full transition-opacity",
              isLoading && "opacity-50 pointer-events-none",
            )}
          >
            {topicIds.length > 1 &&
              topicIds
                .sort((a, b) => a.localeCompare(b))
                .map((topicId) => {
                  const topic = usage?.systems[systemId]?.topics[topicId];

                  return (
                    <TopicCheckbox
                      key={topicId}
                      topicId={topicId}
                      topicName={topic?.name ?? ""}
                      questionCount={topic?.count ?? 0}
                      systemId={systemId}
                      systemName={systemName}
                      isLoading={!!isLoading}
                      className="w-full"
                      labelClassName="lg:text-sm md:text-sm"
                      onCheckedChange={(checked) => {
                        toggleSelection("selectedTopics", checked, topicId);
                      }}
                    />
                  );
                })}
          </div>
        </AccordionItem>
      </div>
    </Accordion>
  );
};

export default SystemCheckbox;
