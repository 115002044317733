import LibraryIcon from "@/components/icons/library-icon";
import { Button } from "..";

const MedicalLibraryButton = () => {
  return (
    <Button href="medical-library">
      <LibraryIcon className="w-5 h-5" />
      <p>Medical Library</p>
    </Button>
  );
};

export default MedicalLibraryButton;
