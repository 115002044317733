import Triggerable from "@/components/triggerable";
import { WithTooltip } from "@/components/pages/dashboard";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import useChangeHighlightHighlightMarker from "@/hooks/highlight-hooks/use-change-highlight-highlightmarker";
import { useNbmeContextMenuState } from ".";
import useCreateHighlight from "@/hooks/highlight-hooks/use-create-highlight";
import { CreateHighlightInput } from "@/api/src/server/routers/highlight-router";

const NbmeContextMenuChangeHighlightMarker = ({
  currentMarkerId,
  onNewMarkerSelected,
  highlightId,
  newHighlight,
  onNewHighlight,
}:
  | {
      currentMarkerId: string | null;
      highlightId: string;
      onNewMarkerSelected?: (newMarkerId: string) => void;
      newHighlight?: never;
      onNewHighlight?: never;
    }
  | {
      currentMarkerId?: never;
      highlightId?: never;
      onNewMarkerSelected?: never;
      newHighlight: Omit<CreateHighlightInput["highlight"], "markerId">;
      onNewHighlight?: (highlight: CreateHighlightInput["highlight"]) => void;
    }) => {
  const setIsVisible = useNbmeContextMenuState((state) => state.setVisible);
  const { mutate: changeHighlightMarker } = useChangeHighlightHighlightMarker();
  const { mutate: createHighlight } = useCreateHighlight();
  const { data: highlightMarkers } = useHighlightMarkers();

  return (
    <Triggerable.Root className="relative inline-flex flex-row justify-center items-center w-full left-0">
      <WithTooltip tooltip={highlightId ? "Change Color" : "Highlight"}>
        <Triggerable.Toggle className=" rounded-primary hover:scale-110 transition-transform p-1 dark:bg-neutral-600 bg-nbme-primary-700/70 warm:bg-brown-700  m-auto inline-flex flex-col justify-center items-center active:scale-95">
          <div
            className="w-5 h-5 rounded-full"
            style={{
              background:
                highlightMarkers?.find((hm) => hm.id === currentMarkerId)
                  ?.color ?? "white",
            }}
          ></div>
        </Triggerable.Toggle>
      </WithTooltip>
      <Triggerable.Body className="flex flex-col justify-start items-center absolute md:-bottom-1.5 md:top-auto -top-1.5 md:translate-y-full -translate-y-full gap-2 py-2 left-0 px-1 rounded-primary dark:bg-neutral-700 bg-nbme-primary-600 warm:bg-brown-700 max-h-30 overflow-y-auto">
        {highlightMarkers &&
          highlightMarkers
            .filter((hm) => hm.id !== currentMarkerId)
            .map((hm) => (
              <WithTooltip key={hm.id} tooltip={hm.name} side="left">
                <button
                  key={hm.id}
                  className="w-5 h-5 rounded-full hover:scale-110 hover:shadow-md transition-[transform,_box-shadow]"
                  style={{ background: hm.color }}
                  onClick={() => {
                    setIsVisible(false);
                    if (highlightId) {
                      changeHighlightMarker({
                        highlightId,
                        highlightMarkerId: hm.id,
                      });
                    } else {
                      createHighlight({
                        highlight: { ...newHighlight!, markerId: hm.id },
                      });
                      onNewHighlight?.({ ...newHighlight!, markerId: hm.id });
                    }
                    onNewMarkerSelected?.(hm.id);
                  }}
                />
              </WithTooltip>
            ))}
      </Triggerable.Body>
    </Triggerable.Root>
  );
};

export default NbmeContextMenuChangeHighlightMarker;
