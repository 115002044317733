import { twMerge } from "tailwind-merge";

interface RadioGroupProps {
  className?: string;
  value: string;
  onSelect: (value: string) => void;
  isSelected: boolean;
  id: string;
  disabled?: boolean;
}

const RadioGroupItem = ({
  value,
  className,
  id,
  isSelected,
  disabled,
  onSelect,
}: RadioGroupProps) => (
  <button
    tabIndex={-1}
    value={value}
    disabled={disabled}
    className={twMerge(
      "group dark:bg-white border border-gray-400 w-6 h-6 rounded-full disabled:bg-gray-200 disabled:dark:bg-nbme-primary-dark-400 disabled:bg-brown-900/10 hover:dark:bg-neutral-200 transition-colors focus:dark:bg-neutral-200",
      className,
    )}
    id={id}
    onClick={() => {
      if (isSelected) return;
      onSelect(value);
    }}
  >
    {isSelected && (
      <div className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-3 after:h-3 after:rounded-[50%] after:bg-nbme-secondary-500 after:dark:bg-nbme-primary-dark-900 after:warm:bg-brown-800 data-[state=checked]:fade-in group-disabled:after:bg-nbme-secondary-400 group-disabled:after:dark:bg-nbme-primary-dark-600" />
    )}
  </button>
);

export default RadioGroupItem;
