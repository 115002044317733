import { ResizablePanel } from "@/components/ui/resizable";
import { MobileDrawer } from "@/components/common/MobileDrawer";
import {
  useMedicalLibraryLocation,
  useMedicalLibrarySidebarIsOpen,
  useMedicalLibraryVariant,
} from "../store";
import Header from "./header";
import Body from "./body";

const Sidebar = () => {
  const [location] = useMedicalLibraryLocation();
  const [variant] = useMedicalLibraryVariant();
  const [isOpen, setIsOpen] = useMedicalLibrarySidebarIsOpen();
  const onDesktop = variant === "desktop";

  const Container = onDesktop ? ResizablePanel : MobileDrawer;
  const parentProps = onDesktop
    ? { order: 1, defaultSize: location === "test" ? 30 : 15 }
    : { open: isOpen, onOpenChange: setIsOpen };
  return (
    <Container className="h-full" {...parentProps}>
      <div className="w-full h-full flex flex-col justify-start items-start">
        <Header />
        <Body />
      </div>
    </Container>
  );
};

export default Sidebar;
