import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { PropsWithClassName } from "@/types";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps, PropsWithChildren, ReactNode } from "react";

type WithTooltipProps = {
  tooltip: ReactNode;
} & PropsWithChildren &
  PropsWithClassName &
  VariantProps<typeof tooltipVariants> &
  ComponentProps<typeof TooltipContent>;

const tooltipVariants = cva("", {
  variants: {
    intent: {
      nbme: "bg-nbme-primary-600 border border-nbme-primary-500 shadow-md",
    },
  },
});

export const WithTooltip = ({
  children,
  tooltip,
  className,
  intent,
  ...props
}: WithTooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={3}>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            className={cn(
              "capitalize z-[100000] pointer-events-none",
              tooltipVariants({ intent, className }),
            )}
            {...props}
          >
            {tooltip}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};
