import { trpc } from "@/utils/trpc";
import { useParams } from "@tanstack/react-router";

const useTestPageTestSession = () => {
  const { testId } = useParams({
    from: "/qbanks/$qbankEndpoint/tests/$testId",
  });
  return trpc.test.getTestSession.useQuery(
    { testId: testId ? Number(testId) : 0 },
    { staleTime: Infinity, enabled: !!testId },
  );
};

export default useTestPageTestSession;
