import FloatingWindowBody from "./floating-window-body";
import FloatingWindowClose from "./floating-window-close";
import FloatingWindowPortal from "./floating-window-portal";
import FloatingWindowRoot from "./floating-window-root";
import FloatingWindowTitlebar from "./floating-window-titlebar";
import FloatingWindowToggle from "./floating-window-toggle";
import FloatingWindowViewer from "./floating-window-viewer";

export { default as FloatingWindowViewer } from "./floating-window-viewer";
export { default as FloatingWindowRoot } from "./floating-window-root";
export { default as FloatingWindowBody } from "./floating-window-body";
export { default as FloatingWindowToggle } from "./floating-window-toggle";
export { default as FloatingWindowTitlebar } from "./floating-window-titlebar";
export { default as FloatingWindowClose } from "./floating-window-close";
export { default as FloatingWindowPortal } from "./floating-window-portal";

const FloatingWindow = {
  Root: FloatingWindowRoot,
  Toggle: FloatingWindowToggle,
  Close: FloatingWindowClose,
  Body: FloatingWindowBody,
  Viewer: FloatingWindowViewer,
  Titlebar: FloatingWindowTitlebar,
  Portal: FloatingWindowPortal,
};

export default FloatingWindow;
