import { twMerge } from "tailwind-merge";
import { LoadingSpinner } from "./LoadingSpinner";
import { DashboardTableHeaderRow } from "./DashboardTableHeaderRow";
import { BreakPoints, TableRow } from "@/types";
import { DashboardTableRow, DashboardTableRowProps } from "./DashboardTableRow";
import { useState } from "react";
import { useBreakPoint } from "@/utils/hooks";
import { DashboardMobileTable } from "./DashboardMobileTable";
import { PropsWithClassName } from "@/types";
import { paginateArray } from "@/utils/common/paginateArray";

export interface DashboardTableProps<T extends { id: string | number }>
  extends PropsWithClassName {
  columns: TableRow<T>;
  isLoading?: boolean;
  items: T[];
  currentPage?: number;
  itemsPerPage?: number;
  collapsedItems?: (item: T) => (any & { id: number | string })[];
  columnContentMapper?: DashboardTableRowProps<T>["columnContentMapper"];
  collapsedColumnContentMapper?: DashboardTableRowProps<T>["columnContentMapper"];
  allowMobileTable?: boolean;
}

export const DashboardTable = <T extends { id: string | number }>(
  props: DashboardTableProps<T>,
) => {
  const {
    isLoading,
    columns,
    items,
    columnContentMapper,
    itemsPerPage,
    currentPage,
    collapsedItems,
    allowMobileTable = true,
    className,
    collapsedColumnContentMapper,
  } = props;

  const breakpoint = useBreakPoint();
  const isScreenSmall = breakpoint < BreakPoints.Medium;
  const [isAllCollapsed, setIsAllCollapsed] = useState<boolean>(false);

  if (isScreenSmall && allowMobileTable) {
    return <DashboardMobileTable {...props} />;
  }
  return (
    <table
      className={twMerge(
        "w-full",
        isLoading && items.length === 0 && "min-h-[80vh]",
        isLoading && items.length > 0 && "opacity-30 pointer-events-none",
        className,
      )}
    >
      <thead>
        <DashboardTableHeaderRow
          isAllCollapsed={isAllCollapsed}
          onIsAllCollapsedChange={setIsAllCollapsed}
          row={columns}
        />
      </thead>
      <tbody>
        {isLoading && items.length === 0 && (
          <tr>
            <td className="w-full py-5" colSpan={10}>
              <div className="flex flex-row justify-center items-center w-full">
                <LoadingSpinner className="w-10 h-10 md:w-12 md:h-12 lg:w-16 lg:h-16" />
              </div>
            </td>
          </tr>
        )}
        {items &&
          (currentPage && itemsPerPage
            ? paginateArray({
                array: items,
                pageSize: itemsPerPage,
                pageNumber: currentPage,
              })
            : items
          ).map((item) => (
            <DashboardTableRow
              isAllCollapsed={isAllCollapsed}
              collapsedItems={collapsedItems}
              key={item.id}
              row={columns}
              item={item}
              columnContentMapper={columnContentMapper}
              collapsedColumnContentMapper={collapsedColumnContentMapper}
            />
          ))}
      </tbody>
    </table>
  );
};
