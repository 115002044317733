import { RefObject, useEffect, useRef } from "react";

export const useOnClickOutside = <T = HTMLElement>(
  callback: Function,
  externalRef?: RefObject<T>,
) => {
  const internalRef = useRef<T>(null);
  const ref = externalRef ?? internalRef;
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, ref]);
  return ref;
};
export default useOnClickOutside;
