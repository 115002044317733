import { cn } from "@/lib/utils";
import { SearchIcon } from "@/components/icons/hollow";
import { Button } from "@/components/ui/button";
import XIcon from "@/components/icons/x-icon";
import { BlurInOut } from "@/ncomponents/utilities/animations/blur-in-out";
import { useState } from "react";
import { useMedicalLibraryLibrarySearch } from "@/ncomponents/medical-library/store";
import { useActivity } from "@/utils/hooks";
import { library } from "@fortawesome/fontawesome-svg-core";
const SearchBar = () => {
  const [librarySearch, setLibrarySearch] = useMedicalLibraryLibrarySearch();
  const [draft, setDraft] = useState(librarySearch.query);
  const [isFocused, setIsFocused] = useState(false);
  const { triggerActivity } = useActivity({
    activityDurationInMilliSeconds: 500,
    onActivityStopped: () =>
      setLibrarySearch({ ...librarySearch, query: draft }),
  });

  return (
    <div
      className={cn(
        "w-full px-4 py-2.5 bg-gray-200/60 warm:bg-brown-100/60 dark:bg-neutral-900 flex flex-row gap-x-3 justify-start items-center border border-transparent rounded-primary transition-colors text-gray-600 dark:text-neutral-700 warm:text-brown-500",
        isFocused &&
          "border-primary-300 dark:border-neutral-500 warm:border-brown-300",
      )}
    >
      <SearchIcon
        className={cn(
          "transition-colors",
          isFocused &&
            "text-primary-400 warm:text-brown-600 dark:text-neutral-500",
        )}
      />
      <input
        value={draft}
        onChange={(e) => {
          triggerActivity();
          if (!e.target.value) {
            setLibrarySearch({ ...librarySearch, query: "" });
          }
          setDraft(e.target.value);
        }}
        placeholder="Search"
        className="outline-none bg-transparent flex-grow text-gray-800 dark:text-neutral-300 dark:placeholder:text-neutral-600 warm:text-brown-800 warm:placeholder:text-brown-300"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <BlurInOut state={draft ? "visible" : "hidden"}>
        <Button
          size={"icon"}
          variant={"ghost"}
          className="w-6 h-6"
          onClick={() => {
            setDraft("");
            setLibrarySearch({ ...librarySearch, query: "" });
          }}
        >
          <XIcon />
        </Button>
      </BlurInOut>
    </div>
  );
};
export default SearchBar;
