import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { IconButton } from ".";
import {
  Dialog,
  DialogClose,
  DialogTitle,
  DialogFooter,
  DialogHeader,
  DialogPortal,
  DialogContent,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { buttonVariants } from "@/ui/button";
import { Button } from "@/components/ui/button";

interface RecoverButtonProps extends PropsWithClassName {
  title: string;
  onClick?: () => void;
}

export const RecoverButton = ({
  className,
  title,
  onClick,
}: RecoverButtonProps) => {
  return (
    <IconButton className={className}>
      <Dialog>
        <DialogTrigger>
          <FontAwesomeIcon icon={faRotateRight} />
        </DialogTrigger>
        <DialogPortal>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Recover {title}?</DialogTitle>
              <DialogDescription>
                Would you like to recover this note/page along with it's
                children?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogClose className={buttonVariants({ variant: "ghost" })}>
                Cancel
              </DialogClose>
              <DialogClose asChild>
                <Button onClick={onClick} variant={"default"}>
                  Recover
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </DialogPortal>
      </Dialog>
    </IconButton>
  );
};
