import React from "react";

type YouTubeEmbedProps = {
  url: string;
};

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ url }) => {
  // Function to extract video ID from a standard YouTube URL
  const getYouTubeID = (url: string): string | null => {
    const match = url.match(
      /(?:youtube\.com\/.*(?:\?|&)v=|youtu\.be\/)([a-zA-Z0-9_-]+)/,
    );
    return match ? match[1] : null;
  };

  const videoId = getYouTubeID(url);

  if (!videoId) {
    return <p>Invalid YouTube URL</p>;
  }

  //TODO: Align this in the center on mobile and sync alignment with settings
  return (
    <div className="relative max-w-lg aspect-video">
      <iframe
        className="absolute inset-0 w-full h-full"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
