import { fadeAnimation } from "@/assets/framer";
import CircularProgressBar from "@/components/ui/circular-progress-bar";
import { percent } from "@/utils/common/percent";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { getCenterText, getStrokeClassNames } from "./utils";
import { LoadingSpinner } from "@/components/LoadingSpinner";

interface DashboardScoreStatsCircleProps {
  total: number;
  correct: number;
  incorrect: number;
  omitted: number;
  isLoading?: boolean;
}

export const DashboardScoreStatsCircle = ({
  total,
  correct,
  incorrect,
  omitted,
  isLoading,
}: DashboardScoreStatsCircleProps) => {
  const [activePercentage, setActivePercentage] = useState<number>(0);
  const percentages = [
    percent(correct, total),
    percent(incorrect, total),
    percent(omitted, total),
  ];

  return (
    <CircularProgressBar.Root isClickable progress={percentages}>
      <CircularProgressBar.Renderer>
        <CircularProgressBar.Background />
        <CircularProgressBar.Progress
          className={`stroke-lime-500 hover:stroke-lime-500 dark:stroke-lime-600 ${
            activePercentage !== 0
              ? "stroke-lime-100 dark:stroke-lime-800 dark:hover:stroke-lime-500"
              : ""
          }`}
          onClick={() => setActivePercentage(0)}
        />
        <CircularProgressBar.Progress
          className={`stroke-red-500 hover:stroke-red-500 ${
            activePercentage !== 1
              ? "stroke-red-100 dark:stroke-red-800 dark:hover:stroke-red-500"
              : ""
          }`}
          onClick={() => setActivePercentage(1)}
        />
        <CircularProgressBar.Progress
          className={`stroke-sky-500 hover:stroke-sky-500 ${
            activePercentage !== 2
              ? "stroke-sky-100 dark:stroke-sky-800 dark:hover:stroke-sky-500"
              : ""
          }`}
          onClick={() => setActivePercentage(2)}
        />
      </CircularProgressBar.Renderer>
      <CircularProgressBar.Content>
        <motion.div
          key={isLoading ? "loading" : getCenterText(activePercentage)}
          {...fadeAnimation}
          transition={{ ease: "backOut", duration: 0.9 }}
          className="flex flex-col justify-center items-center select-none"
        >
          {isLoading ? (
            <LoadingSpinner className="w-12 h-12" />
          ) : (
            <>
              <p className="text-xl text-gray-700 dark:text-neutral-200">
                {percentages[activePercentage]!.toFixed(1)}%
              </p>
              <p className="text-gray-500 font-light dark:text-neutral-300">
                {getCenterText(activePercentage)}
              </p>
            </>
          )}
        </motion.div>
      </CircularProgressBar.Content>
    </CircularProgressBar.Root>
  );
};
