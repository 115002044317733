import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import LabeledCheckbox from "./LabeledCheckbox";
import { useMemo } from "react";
import { useParams } from "@tanstack/react-router";
import useCreateTestPageQuestionBankUsage from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-createtest-page-questionbank-usage";
import DashboardAccordionSection from "./DashboardAccordionSection";
import CreateTestSubjects from "./CreateTestSubjects";

const CreateTestSubjectsSection = () => {
  const { qbankEndpoint } = useParams({
    from: "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test/standard",
  });
  const {
    selectedSubjects,
    setSelectedSubjects,
    toggleSection,
    toggledSections,
  } = useCreateTestPageStore();

  const { data: usage } = useCreateTestPageQuestionBankUsage();
  const allSubjects = Object.keys(usage?.subjects ?? {});

  const isTitleChecked = !!useMemo(
    () =>
      usage &&
      selectedSubjects.length !== 0 &&
      selectedSubjects.length === allSubjects.length,
    [selectedSubjects, usage],
  );

  return (
    <DashboardAccordionSection
      title={
        <div className="flex flex-row justify-center items-center gap-2">
          <LabeledCheckbox
            labelClassName="font-medium text-black"
            id="all-subjects-checkbox"
            checked={isTitleChecked}
            onCheckedChange={(checked) =>
              checked
                ? setSelectedSubjects(allSubjects)
                : setSelectedSubjects([])
            }
            label="Subjects"
          />
        </div>
      }
      value="subjects"
      className="border-b"
      onToggle={() => toggleSection("subjects")}
      toggled={toggledSections.includes("subjects")}
    >
      <div className="lg:ml-8 flex flex-row justify-center lg:justify-start items-center gap-10 mt-3 w-full pb-5 pt-2">
        <CreateTestSubjects />
      </div>
    </DashboardAccordionSection>
  );
};

export default CreateTestSubjectsSection;
