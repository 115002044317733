import { trpc } from "@/utils/trpc";
import { toast } from "sonner";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";
import { Highlight } from "@/models";
import useMutateArticleContentQueryCache from "../utility/use-mutate-article-content-query-cache";

const useDeleteHighlight = () => {
  const mutateTestSlotCache = useMutateTestSlotQueryCache()();
  const mutateArticleContentCache = useMutateArticleContentQueryCache()();
  return trpc.highlight.deleteHighlight.useMutation({
    onMutate: ({ highlightId }) => {
      let oldHighlight:
        | Pick<
            Highlight,
            | "id"
            | "start"
            | "end"
            | "markerId"
            | "questionId"
            | "questionExplanationId"
            | "text"
          >
        | undefined;
      const rollbackTestSlot = mutateTestSlotCache((state) => {
        let targetHighlight = state.question.highlights.find(
          (h) => h.id === highlightId,
        );
        oldHighlight = targetHighlight
          ? {
              ...targetHighlight,
              questionId: state.question.id,
              questionExplanationId: null,
            }
          : undefined;
        state.question.highlights = state.question.highlights.filter(
          (h) => h.id !== highlightId,
        );

        //Target highlight was not on question, look for it on explanation
        if (!targetHighlight) {
          targetHighlight = state.question.explanation.highlights.find(
            (h) => h.id === highlightId,
          );
          oldHighlight = targetHighlight
            ? {
                ...targetHighlight,
                questionId: null,
                questionExplanationId: state.question.explanation.id,
              }
            : undefined;
        }
        state.question.explanation.highlights =
          state.question.explanation.highlights.filter(
            (h) => h.id !== highlightId,
          );
      });

      const rollbackArticleContent = mutateArticleContentCache((state) => {
        state.usage.highlights = state.usage.highlights.filter(
          (h) => h.id !== highlightId,
        );
      });

      return {
        rollback: () => {
          rollbackTestSlot((state) => {
            if (!oldHighlight) return;
            if (oldHighlight.questionId === state.question.id) {
              state.question.highlights.push(oldHighlight);
            }
            if (
              oldHighlight.questionExplanationId ===
              state.question.explanation.id
            ) {
              state.question.explanation.highlights.push(oldHighlight);
            }
          });
          rollbackArticleContent();
        },
      };
    },
    onError: (error, variables, context) => {
      toast.error(
        `Could not delete highlight, an error ocurred...,${error.message}`,
      );
      context?.rollback();
    },
  });
};

export default useDeleteHighlight;
