import { trpc } from "@/utils/trpc";
import useMutateQueryCache from "./use-mutate-query-cache";
import {
  GetArticleContentInput,
  GetArticleContentOutput,
} from "@/api/src/server/routers/article";

const useMutateArticleContentQueryCache = () => {
  return useMutateQueryCache<GetArticleContentInput, GetArticleContentOutput>(
    trpc.article.getArticleContent,
    "query",
  );
};

export default useMutateArticleContentQueryCache;
