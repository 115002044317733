import { trpc } from "@/utils/trpc";
import { faChartLine, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@tanstack/react-router";

export const TestResultsAnalysisLink = ({
  to,
  testId,
  qBankEndpoint,
}: {
  to: "analysis" | "results";
  qBankEndpoint: string;
  testId: number;
}) => {
  trpc.statistics.getTestResult.usePrefetchQuery({ testId });
  return (
    <Link
      to={
        to === "results"
          ? `/qbanks/$qbankEndpoint/dashboard/performance/test/$testId/results`
          : `/qbanks/$qbankEndpoint/dashboard/performance/test/$testId/analysis`
      }
      params={{ qbankEndpoint: qBankEndpoint, testId: String(testId) }}
    >
      <FontAwesomeIcon icon={to === "results" ? faListCheck : faChartLine} />
    </Link>
  );
};
