import useMutateTestPageSessionCache from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-mutate-test-page-session-cache";
import useMutateTestPageSelectedSlotQueryCache from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-mutate-test-page-selected-slot-query-cache";
import { trpc } from "@/utils/trpc";
import { toast } from "sonner";
import useMutateTestSessionQueryCache from "../utility/use-mutate-test-session-query-cache";
import useMutateTestSlotQueryCache from "../utility/use-mutate-test-slot-query-cache";

const useMarkQuestion = () => {
  const testSessionCacheMutator = useMutateTestSessionQueryCache()(undefined);
  const testSlotCacheMutator = useMutateTestSlotQueryCache()(undefined);

  return trpc.question.markQuestion.useMutation({
    onMutate: ({ questionId, marked }) => {
      const rollBackTestSession = testSessionCacheMutator((state) => {
        state.slots.forEach((s) => {
          if (s.questionId === questionId) {
            s.isMarked = marked;
          }
        });
      });

      const rollBackTestSlot = testSlotCacheMutator((state) => {
        if (state.question.id === questionId) state.question.isMarked = marked;
      });

      //TODO: Update create test page cache when we finish it
      return {
        rollback: () => {
          rollBackTestSession((state, oldTestSession) => {
            state.slots.forEach((s) => {
              if (s.questionId === questionId) {
                s.isMarked = !!oldTestSession.slots.find(
                  (s) => s.questionId === questionId,
                )?.isMarked;
              }
            });
          });
          rollBackTestSlot((state, oldTestSlot) => {
            if (state.question.id === questionId)
              state.question.isMarked = !!oldTestSlot.question.isMarked;
          });
        },
      };
    },
    onError: (error, variables, context) => {
      context?.rollback();
      toast.error(
        `Could not mark question, an error occurred: ${error.message}`,
      );
    },
  });
};
export default useMarkQuestion;
