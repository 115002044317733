import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import LabeledCheckbox from "./LabeledCheckbox";
import CreateTestSystems from "./CreateTestSystems";
import { useMemo } from "react";
import useCreateTestPageQuestionBankUsage from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-createtest-page-questionbank-usage";

const CreateTestSystemsSection = () => {
  const { data: usage } = useCreateTestPageQuestionBankUsage();

  const { toggleSection, selectedTopics, toggledSections, setSelectedTopics } =
    useCreateTestPageStore();

  const topics = Object.keys(usage?.topics ?? {});

  const isTitleChecked = !!useMemo(
    () =>
      usage &&
      selectedTopics.length !== 0 &&
      selectedTopics.length === topics.length,
    [selectedTopics, usage, topics.length],
  );

  return (
    <DashboardAccordionSection
      title={
        <div className="flex flex-row justify-center items-center gap-2">
          <LabeledCheckbox
            labelClassName="font-medium text-black"
            id="all-systems-checkbox"
            checked={isTitleChecked}
            onCheckedChange={(checked) =>
              checked ? setSelectedTopics(topics) : setSelectedTopics([])
            }
            label="Systems"
          />
        </div>
      }
      value="systems"
      className="border-b"
      onToggle={() => toggleSection("systems")}
      toggled={toggledSections.includes("systems")}
    >
      <div className="lg:ml-8 flex flex-row justify-center lg:justify-start items-center gap-10 mt-3 w-full pb-5 pt-2">
        <CreateTestSystems />
      </div>
    </DashboardAccordionSection>
  );
};
export default CreateTestSystemsSection;
