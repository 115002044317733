import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { Button } from "./Button";
import { LoadingSpinner } from "./LoadingSpinner";

interface ButtonWithLoadingSpinnerProps
  extends PropsWithChildren,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  loading: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const ButtonWithLoadingSpinner = ({
  loading,
  children,
  error,
  disabled,
  ...props
}: ButtonWithLoadingSpinnerProps) => {
  return (
    <div className="relative w-full">
      <div
        className={twMerge(
          "w-full transition-opacity duration-200 opacity-100",
          loading && "opacity-0",
        )}
      >
        <Button error={error} disabled={disabled} type="submit" {...props}>
          {children}
        </Button>
      </div>
      {loading && (
        <div className="absolute bottom-0 flex justify-center items-center w-full h-full">
          <LoadingSpinner className="absolute w-12 h-12 lg:w-16 lg:h-16" />
        </div>
      )}
    </div>
  );
};
