import { ResizablePanelGroup } from "@/components/ui/resizable";
import { cn } from "@/lib/utils";
import { BreakPoints, PropsWithClassName } from "@coursology/types";
import Sidebar from "./sidebar";
import Body from "./body";
import { useMedicalLibraryLocation, useMedicalLibraryVariant } from "./store";
import { useEffect } from "react";
import { useBreakPoint } from "@/utils/hooks";

interface MedicalLibraryProps extends PropsWithClassName {
  /**The className of the root of the medical library*/
  className?: string;
  location?: "dashboard" | "test";
}

const MedicalLibrary = ({ className, location }: MedicalLibraryProps) => {
  const [, setLocation] = useMedicalLibraryLocation();
  const [, setVariant] = useMedicalLibraryVariant();
  const breakpoint = useBreakPoint();
  useEffect(() => {
    if (breakpoint > BreakPoints.Medium) {
      setVariant("desktop");
    } else {
      setVariant("mobile");
    }
  }, [breakpoint, setVariant]);

  useEffect(() => {
    if (location) setLocation(location);
  }, [location, setLocation]);

  return (
    <ResizablePanelGroup
      direction="horizontal"
      className={cn(
        "relative w-full h-full min-h-full max-h-full bg-white warm:bg-egg-sour-50 dark:bg-neutral-950 transition-colors",
        className,
      )}
    >
      <Sidebar />
      <Body />
    </ResizablePanelGroup>
  );
};

export default MedicalLibrary;
