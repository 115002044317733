import FloatingWindow from "@/components/floating-window";
import { useOnDesktop } from "@/hooks/common/useOnDesktop";
import { cn } from "@/lib/utils";
import { Dimension } from "@/types";
import {
  useNbmeAlignment,
  useNbmeImageAlignment,
  useNbmeSplitView,
  useNbmeWindowsSettings,
} from "@/utils/stores/nbmeLayoutStore";
import {
  convertBlobToPng,
  copyBlobToClipboard,
  copyImageToClipboard,
} from "copy-image-clipboard";
import { Node } from "interweave";
import { toast } from "sonner";
import { blobToDataUri } from "@/utils/common";
import { useTestPageSelectedTestSlot } from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";
import useTestPageTestSession from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-test-session";
import { createPortal } from "react-dom";

const DraggableImageViewer = ({
  src,
  alt,
  dimensions,
}: {
  src: string;
  alt: string;
  dimensions?: Partial<Dimension>;
}) => {
  const onDesktop = useOnDesktop();
  const [splitView] = useNbmeSplitView();
  const [alignment] = useNbmeAlignment();
  const [imageAlignment] = useNbmeImageAlignment();
  const [settings] = useNbmeWindowsSettings();

  const { data: test } = useTestPageTestSession();
  const { data: selected } = useTestPageSelectedTestSlot();
  const mode = test?.mode;
  const submitted = test?.submitted;
  const question = selected?.question;
  const shouldShowExplanation =
    selected?.submitted &&
    (mode?.includes("tutor") || (!mode?.includes("tutor") && submitted));

  const shouldShowSplitView =
    onDesktop && splitView && shouldShowExplanation && !question?.abstract;

  return (
    <FloatingWindow.Root
      onDownload={() => handleDownload(src)}
      onCopy={() => handleCopy(src)}
      isMaximizable={true}
      allowOffScreen={settings.allowOffScreen}
    >
      <div
        className={cn(
          "w-full",
          !onDesktop && "flex flex-row justify-center items-center",
          imageAlignment === "center" &&
            "lg:flex lg:justify-center lg:items-center",
          imageAlignment === "right" && "lg:flex lg:justfiy-end lg:items-end",
        )}
      >
        <FloatingWindow.Toggle
          className={cn(
            "lg:max-w-lg md:max-w-md w-full my-5  hover:scale-[1.01] transition-[transform,_box-shadow] ease-in-out duration-500 hover:shadow-lg",
            alignment === "left" && imageAlignment === "right" && "ms-auto",
            shouldShowSplitView && "lg:max-w-xl md:max-w-lg w-full",
          )}
        >
          <img
            src={src}
            alt={alt}
            className={"w-full rounded-lg"}
            style={{
              minWidth: dimensions?.width ?? undefined,
              minHeight: dimensions?.height ?? undefined,
            }}
          ></img>
        </FloatingWindow.Toggle>
      </div>

      {createPortal(<FloatingWindow.Body title={"Image"} className="z-[1000]">
        <FloatingWindow.Viewer>
          <img src={src} alt={alt} />
        </FloatingWindow.Viewer>
      </FloatingWindow.Body>, document.body)}
    </FloatingWindow.Root>
  );
};

export const imageTransformer =
  (dimensions?: { width?: number; height?: number }) =>
  (node: HTMLElement, children: Node[]): React.ReactNode => {
    const isImage = node.tagName === "IMG";

    if (!isImage) return;
    const src = node.getAttribute("src") ?? "";
    const alt = node.getAttribute("alt") ?? "";

    return (
      <DraggableImageViewer
        src={src}
        alt={alt}
        key={src}
        dimensions={dimensions}
      />
    );
  };

function handleDownload(dataUri?: string, name?: string, useProxy = true) {
  if (!dataUri) return;
  const imagePromise = fetch(
    (useProxy ? import.meta.env.VITE_CORS_PROXY_ENDPOINT : "") + dataUri,
  ).then((res) => res.blob());
  toast.promise(imagePromise, {
    loading: "Downloading file...",
    success: async (data) => {
      const url = await blobToDataUri(data);
      const a = document.createElement("a");
      a.href = url;
      a.download = name ?? "";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return `Successfully downloaded file!`;
    },
    error: "An error ocurred while downloading the file...",
  });
}

async function handleCopy(url: string) {
  toast.promise(
    copyImageToClipboard(import.meta.env.VITE_CORS_PROXY_ENDPOINT + url),
    {
      loading: "Downloading image to copy...",
      success: "Successfully copied image to clipboard.",
    },
  );
}
