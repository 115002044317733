import { ResizablePanelGroup } from "@/components/ui/resizable";
import { cn } from "@/lib/utils";
import { PropsWithClassName } from "@coursology/types";
import { PropsWithChildren } from "react";

type LayoutProps = PropsWithChildren & PropsWithClassName;
export const Layout = ({ children, className }: LayoutProps) => {
  return (
    <ResizablePanelGroup
      direction="horizontal"
      className={cn("relative w-full h-full min-h-full bg-white warm:bg-brown-50 dark:bg-neutral-950 transition-colors", className)}
    >
      {children}
    </ResizablePanelGroup>
  );
};
