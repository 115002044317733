import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { CSSProperties, PropsWithChildren, useEffect } from "react";
import { twMerge } from "tailwind-merge";

interface RadioGroupProps extends PropsWithChildren {
  className?: string;
  orientation?: "vertical" | "horizontal";
  ariaLabel: string;
  style?: CSSProperties;
}

const RadioGroup = ({
  className,
  children,
  orientation,
  ariaLabel,
  style,
}: RadioGroupProps) => {
  return (
  <div
    style={style}
    tabIndex={-1}
    className={twMerge(
      "flex justify-start items-start gap-2.5",
      orientation === "vertical" && "flex-col",
      orientation === "horizontal" && "flex-row",
      className,
    )}
    aria-label={ariaLabel}
  >
    {children}
  </div>
)};

export default RadioGroup;
