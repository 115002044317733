import {
  GetPreviousTestsInput,
  GetPreviousTestsOutput,
} from "@/api/src/server/routers";
import useMutateQueryCache from "./use-mutate-query-cache";
import { trpc } from "@/utils/trpc";

const useMutatePreviousTestsQueryCache = () => {
  return useMutateQueryCache<GetPreviousTestsInput, GetPreviousTestsOutput>(
    trpc.test.getPreviousTests,
    "query",
  );
};

export default useMutatePreviousTestsQueryCache;
