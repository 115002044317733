export { default as NbmeSidePanelRoot } from "./root";
export { default as NbmeSidePanelHeader } from "./header";
export { default as NbmeSidePanelTitle } from "./title";
export { default as NbmeSidePanelCloseButton } from "./close-button";
export { default as NbmeSidePanelBody } from "./body";
export { default as NbmeSidePanelPortal } from "./portal";

import Root from "./root";
import Title from "./title";
import Header from "./header";
import CloseButton from "./close-button";
import Body from "./body";
import Trigger from "./trigger";
import Portal from "./portal";

const NbmeSidePanel = {
  Root,
  Title,
  Header,
  Trigger,
  CloseButton,
  Body,
  Portal,
};

export default NbmeSidePanel;
