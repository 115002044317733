import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useAppStore } from "@/store";

const useCurrentUser = () => {
  const currentUser = useAppStore((state) => state.currentUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (!currentUser) {
      navigate({ to: "/auth/signin" });
    }
  }, [currentUser]);
  return currentUser!;
};
export default useCurrentUser;
