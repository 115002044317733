import { trpc } from "@/utils/trpc";
import { toast } from "sonner";
import useMutatePreviousTestsQueryCache from "../utility/use-mutate-previous-tests-query-cache";

const useRenameTest = () => {
  const mutatePreviousTests = useMutatePreviousTestsQueryCache()();
  return trpc.test.renameTest.useMutation({
    onMutate: ({ name, testId }) => {
      let oldName = "";
      const rollbackTests = mutatePreviousTests((state) => {
        const targetTest = state.tests.find((t) => t.id === testId);
        if (targetTest) {
          oldName = targetTest.name;
          targetTest.name = name;
        }
      });

      //Function to reverse state on error
      return () => {
        rollbackTests((state) => {
          const targetTest = state.tests.find((t) => t.id === testId);
          if (targetTest && oldName) {
            targetTest.name = oldName;
          }
        });
      };
    },
    onError: (err, variables, reverse) => {
      reverse?.();
      toast.error(
        `Could not rename test to: ${variables.name} , an error ocurred: ${err.message}`,
      );
    },
  });
};
export default useRenameTest;
