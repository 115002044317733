import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBars,
  faCheck,
  faFlag,
  faPencil,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { cn } from "@/lib/utils";
import {
  useNbmeAllocateSpaceForSidebar,
  useNbmeLayoutStore,
  useNbmeUnsubmittedSlotMark,
} from "@/utils/stores/nbmeLayoutStore";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation, smoothTransition } from "@/assets/framer";
import { DashboardScrollArea } from "../DashboardScrollArea";
import { useEffect, useMemo, useRef } from "react";
import useNbmeSidebarActivity from "./hooks/use-nbme-sidebar-activity";
import useNbmeSidebarGestures from "./hooks/use-nbme-sidebar-gestures";
import { smoothSpring } from "@/assets/framer/springs";
import useTestPageSwitchSelectedSlot from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-switch-selected-slot";
import useTestPageTestSession from "@/routes/qbanks/$qbankEndpoint/tests/$testId/-test-page-hooks/use-test-page-test-session";

export const NbmeSidebar = ({
  visible,
  onToggle,
}: {
  visible: boolean;
  onToggle: () => void;
}) => {
  const { data: test, isLoading: isTestLoading } = useTestPageTestSession();
  const slots = test?.slots;
  const { setSidebarWidth } = useNbmeLayoutStore((state) => ({
    setSidebarWidth: state.setSidebarWidth,
    sidebarWidth: state.sidebarWidth,
  }));
  const ref = useRef<HTMLElement>(null);

  useNbmeSidebarActivity();
  const selectSlot = useTestPageSwitchSelectedSlot();
  const bindNbmeSidebarGestures = useNbmeSidebarGestures();
  const [allocateSpaceForSidebar] = useNbmeAllocateSpaceForSidebar();

  //We use a useEffect here with loading as a
  //dependency because when the page first loads
  //the sidebar doesn't have any data yet so its
  //width is very small then it gets big when the
  //test loads, so we make sure to refresh
  //the width once the loading state finishes.
  useEffect(() => {
    if (isTestLoading) return;
    if (!ref || !ref.current) return;
    setSidebarWidth(ref.current.getBoundingClientRect().width);
  }, [test, isTestLoading, setSidebarWidth]);

  return (
    <motion.nav
      ref={ref}
      className={twMerge(
        "fixed top-0 left-0 h-screen min-h-screen bg-white warm:bg-egg-sour-100 dark:bg-nbme-primary-dark-700 dark:text-white flex flex-col justify-start items-center border border-r-gray-300 dark:border-nbme-primary-dark-600 z-[1000] md:z-[1000] lg:z-[1000] overflow-hidden max-h-screen",
      )}
      transition={{ ...smoothSpring }}
      initial={{ x: 0 }}
      animate={
        visible
          ? { x: 0, opacity: 1 }
          : { x: "-100%", opacity: allocateSpaceForSidebar ? 1 : 0 }
      }
      whileHover={{ opacity: 1 }}
    >
      <div {...bindNbmeSidebarGestures()} className="h-full">
        <DashboardScrollArea className="h-full">
          <button
            type="button"
            className="text-end text-nbme-primary-500 warm:text-brown-800 dark:text-neutral-50 text-lg mr-3 sticky top-0 z-[800]"
            onClick={onToggle}
          >
            <FontAwesomeIcon
              className="bg-white warm:bg-brown-50 dark:bg-neutral-700 dark:hover:bg-neutral-600 active:scale-95 transition-[background-color,_transform] p-2 rounded-primary"
              icon={faArrowLeft}
            />
          </button>
          {slots &&
            [...slots]
              .sort((a, b) => a.order - b.order)
              .map((slot) => {
                const isOmitted = slot.chosen === null && slot.isSubmitted;
                const isCorrect = slot.chosen && slot.chosen.correct;
                const slotIcon = isOmitted
                  ? faCircle
                  : isCorrect
                    ? faCheck
                    : faXmark;
                const n = slot.order;
                const isSelected = test?.selectedSlot === n;
                const isEven = n % 2 === 0;
                const hasParent = slot.hasParent;
                const hasChild = slot.hasChild;
                const mode = test?.mode;
                const submitted = test?.submitted;

                return (
                  <button
                    className={twMerge(
                      "relative flex flex-row justify-center items-center text-center pl-10 pr-14 text-sm w-full",
                      isEven &&
                        "bg-gray-200 warm:bg-brown-950/10 dark:bg-nbme-primary-dark-800",
                      isSelected &&
                        "bg-nbme-primary-500 dark:bg-nbme-primary-dark-400 warm:bg-brown-600 text-white",
                    )}
                    key={n}
                    onClick={() => selectSlot(n)}
                  >
                    <div className="absolute left-0 ml-4 flex flex-row justify-center items-center">
                      {slot.isSubmitted &&
                        (mode?.includes("tutor") ||
                          (!mode?.includes("tutor") && submitted)) && (
                          <FontAwesomeIcon
                            icon={slotIcon}
                            className={twMerge(
                              "w-4 h-4 opacity-0",
                              !isCorrect && "text-red-500",
                              isCorrect && "text-lime-500",
                              isOmitted && "text-blue-500",
                              isOmitted && isSelected && "text-white",
                              slot?.isSubmitted && "opacity-100",
                            )}
                          />
                        )}
                    </div>
                    <p
                      className={cn(
                        "w-5 py-0.5 dark:border-white border-gray-600",
                        hasChild &&
                          !hasParent &&
                          "border-t border-s border-e rounded-t-sm",
                        hasParent &&
                          !hasChild &&
                          "border-s border-e border-b rounded-b-sm",
                        hasParent && hasChild && "border-s border-e",
                      )}
                    >
                      {n + 1}
                    </p>
                    <div className="absolute right-0 mr-3 flex flex-row justify-center items-center gap-2">
                      {
                        <FontAwesomeIcon
                          icon={faFlag}
                          className={twMerge(
                            "w-3 h-3 text-red-500 invisible",
                            slot.isMarked && "visible",
                          )}
                        />
                      }
                      {
                        <FontAwesomeIcon
                          icon={faPencil}
                          className={twMerge(
                            "w-3 h-3 text-amber-500 invisible",
                            slot.hasNote && "visible",
                          )}
                        />
                      }
                    </div>
                    {!slot.chosen && !slot.isSubmitted && (
                      <UnansweredMark isHighContrast={isSelected} />
                    )}
                  </button>
                );
              })}
        </DashboardScrollArea>
      </div>
    </motion.nav>
  );
};

function UnansweredMark({ isHighContrast }: { isHighContrast: boolean }) {
  const [unsubmittedSlotMark] = useNbmeUnsubmittedSlotMark();
  return (
    <AnimatePresence mode="wait">
      {unsubmittedSlotMark && (
        <motion.div
          key={"mark"}
          {...fadeAnimation}
          className={cn(
            "absolute ml-9 w-2 h-2 bg-sky-500 rounded-full",
            isHighContrast && "bg-white",
          )}
        ></motion.div>
      )}
    </AnimatePresence>
  );
}
