"use client";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import QuestionModeCheckbox from "./QuestionModeCheckbox";
import QuestionCountBadge from "./QuestionCountBadge";
import { twMerge } from "tailwind-merge";
import { useNavigate, useParams, useRouterState } from "@tanstack/react-router";
import useCreateTestPageQuestionBankUsage from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-createtest-page-questionbank-usage";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";

const CreateTestQuestionModeSection = () => {
  const { qbankEndpoint } = useParams({
    from: "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test",
  });
  const { data: questionBank } = useQuestionBank({
    questionBankEndpoint: qbankEndpoint,
  });
  const {
    data: usage,
    isLoading: isUsageLoading,
    isPlaceholderData,
  } = useCreateTestPageQuestionBankUsage();
  const pathname = useRouterState().location.pathname;
  const navigate = useNavigate();

  const {
    toggledSections,
    toggleSection,
    toggleField,
    setQmode,
    unused,
    incorrect,
    marked,
    omitted,
    correct,
  } = useCreateTestPageStore();

  const qmode = pathname.split("/").at(-1);
  const allChecked = unused && correct && incorrect && marked && omitted;

  return (
    <DashboardAccordionSection
      title={
        <div className="flex lg:flex-row flex-col justify-center items-center gap-1 text-center lg:text-start">
          <h2 className="font-medium text-gray-900 warm:text-brown-900 dark:text-neutral-50">
            Question mode
          </h2>
          <div className="flex flex-row justify-center items-center gap-2">
            <p className="text-gray-400 warm:text-brown-400 dark:text-neutral-200 text-sm italic">
              Total Available
            </p>
            <QuestionCountBadge count={usage?.total ?? 0} />
          </div>
        </div>
      }
      value="qmode"
      className="border-b"
      onToggle={() => toggleSection("qmode")}
      toggled={toggledSections.includes("qmode")}
    >
      <div
        className={twMerge(
          "flex flex-row justify-center items-center bg-gray-200 warm:bg-brown-100 dark:bg-neutral-700 w-min rounded-full border border-gray-200 warm:border-brown-100 dark:border-neutral-600",
          qmode === "standard" && "mb-4",
        )}
      >
        <button
          className={twMerge(
            "px-7 py-1 rounded-full bg-transparent warm:bg-transparent text-gray-400 warm:text-brown-800/50 dark:text-neutral-100 text-sm transition-colors",
            qmode === "standard" &&
              "bg-white warm:bg-white dark:bg-neutral-100 text-gray-700 warm:text-brown-800 dark:text-neutral-900",
          )}
          onClick={() => setQmode("standard")}
        >
          Standard
        </button>
        <button
          className={twMerge(
            "px-7 py-1 rounded-full bg-transparent text-gray-400 warm:text-brown-800/50 dark:text-neutral-100 text-sm transition-colors",
            qmode === "custom" &&
              "bg-white warm:bg-white dark:bg-neutral-100 text-gray-700 warm:text-brown-800 dark:text-neutral-900",
          )}
          onClick={() => setQmode("custom")}
        >
          Custom
        </button>
        {questionBank?.supportsOrderedTests && (
          <button
            className={twMerge(
              "px-7 py-1 rounded-full bg-transparent text-gray-400 dark:text-neutral-100 text-sm transition-colors",
              qmode === "ordered" &&
                "bg-white dark:bg-neutral-100 text-gray-700 dark:text-neutral-900",
            )}
            onClick={() => setQmode("ordered")}
          >
            Ordered
          </button>
        )}
      </div>

      {qmode === "standard" && (
        <div className="flex lg:flex-row lg:flex-wrap flex-col justify-start items-center lg:gap-10 gap-3 w-full lg:w-auto">
          <QuestionModeCheckbox
            loading={isUsageLoading || isPlaceholderData}
            //Ignore this mode it has no use
            //@ts-ignore
            mode="correctt"
            questionCount={usage?.total ?? 0}
            label="All"
            checked={allChecked}
            onCheckedChange={(newChecked) => {
              ["correct", "incorrect", "omitted", "marked", "unused"].forEach(
                (k) => {
                  toggleField(
                    k as keyof ReturnType<typeof useCreateTestPageStore>,
                    newChecked,
                  );
                },
              );
            }}
          />
          <QuestionModeCheckbox
            loading={isUsageLoading || isPlaceholderData}
            mode="unused"
            questionCount={usage?.unused ?? 0}
          />
          <QuestionModeCheckbox
            mode="incorrect"
            loading={isUsageLoading || isPlaceholderData}
            questionCount={usage?.incorrect ?? 0}
          />
          <QuestionModeCheckbox
            loading={isUsageLoading || isPlaceholderData}
            mode="marked"
            questionCount={usage?.marked ?? 0}
          />
          <QuestionModeCheckbox
            loading={isUsageLoading || isPlaceholderData}
            mode="omitted"
            questionCount={usage?.omitted ?? 0}
          />
          <QuestionModeCheckbox
            loading={isUsageLoading || isPlaceholderData}
            mode="correct"
            questionCount={usage?.correct ?? 0}
          />
        </div>
      )}
    </DashboardAccordionSection>
  );
};

export default CreateTestQuestionModeSection;
