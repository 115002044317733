import { MatcherInterface } from "interweave";
import escapeRegex from "@/utils/common/escape-regex";

const librarySearchMatcher = (search: string): MatcherInterface => ({
  createElement(children, props) {
    return (
      <span className="bg-sky-200 warm:bg-cyan-100 dark:text-black" {...props}>
        {children}
      </span>
    );
  },
  propName: "search",
  inverseName: "noSearch",
  match(value) {
    const result = value.match(escapeRegex(search));
    if (!result) return null;
    return {
      index: result.index!,
      match: result[0],
      length: result[0].length,
      valid: true,
    };
  },

  asTag() {
    return "span";
  },
});

export default librarySearchMatcher;
